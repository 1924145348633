<template>
  <div class="sbs-requests-list" ref="container">
    <div :class="{ visible: showNewRequests }" class="new-request btn btn1" @click="refresh">
      Новая заявка <span class="fi fi-refresh icon"></span>
    </div>

    <div class="tabs">
      <sbs-tabs :items="tabs" v-model:active="currentTab" />
    </div>

    <template v-if="items != null">
      <!-- Если список пуст-->
      <template v-if="items.length == 0">
        <!-- Если не применён фильтр -->
        <div v-if="!filtered" class="empty-list">
          <div class="title">Заявок нет...</div>
          <div class="text" v-if="currentTab == 'unread'">
            Здесь будут отображаться заявки, в которых есть непрочитанные уведомления
          </div>
          <div class="text" v-else>
            Здесь будут отображаться заявки, которые Вы выполняете или поручаете
            сотрудникам.
          </div>
        </div>
        <!-- Иначе применён фильтр -->
        <div v-else class="empty-list">
          <div class="title">Заявки не найдены</div>
          <div class="text">
            По указанному фильтру заявки отсутствуют. Попробуйте поменять
            параметры поиска.
          </div>
        </div>
      </template>

      <!-- Заявки -->
      <div v-else class="items">
        <sbs-request-item v-for="item in items" :key="item.id" :item="item" />
      </div>
    </template>
    <div ref="pager"></div>

    <!-- Загрузка -->
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>

  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsTabs from "@/components/tabs.vue";
import sbsRequestItem from "@/components/requests/item.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "sbs-requests-list",
  components: {
    sbsLoaderInline,
    sbsTabs,
    sbsRequestItem
  },
  data() {
    return {
      loading: true,
      items: null,
      pager: {
        size: 12,
        page: 1,
        cnt: 1,
      },
      currentTab: "",
      showNewRequests: false,
      checkNewRequestsTimer: 0,
      displayNfc: false,
      itemToTake: false,
      itemToStart: false,
    };
  },
  props: {
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "getUserProfile",
      clientSettings: "getClientSettings",
      requestUnread: "getRequestUnread",
      requestUnreadCount: "getRequestUnreadCount",
      tab: "getRequestsTab",
      filterKey: "getRequestsTabFilterKey"
    }),

    isCommonTab() {
      //для системных вкладок фильтр должен быть общий
      return ["active", "responsible", "owner", "unread", "all"].includes(this.tab);
    },


    filter() {
      return this.$store.getters.getFilter("REQUEST-" + this.filterKey);
    },

    sort() {
      let sort = this.$store.getters.getSort("REQUEST-" + this.filterKey);
      if (!sort || Object.keys(sort).length == 0) {
        sort = {
          ID: "desc",
        }
      }
      return sort;
    },

    /**
     * Данные, передаваемые между экранами
     */
    savedFilters() {
      return this.$store.getters.getUserFiltersByType("REQUEST")
    },

    tabs() {
      let tabs = [
        {
          code: "active",
          name: "Активные",
        },
        {
          code: "responsible",
          name: "Мне",
        },
        {
          code: "owner",
          name: "От меня",
        },
        {
          code: "unread",
          name: "Чаты",
          count: this.requestUnreadCount,
        },
        {
          code: "all",
          name: "Все",
        }
      ];

      //сохранённые фильтры
      this.savedFilters.forEach(item => {
        tabs.push({
          code: "saved-filter-" + item.id,
          name: item.name
        });
      });

      return tabs
    },

    //ИД статуса "Завершено"
    requestStatusFinished() {
      return this.clientSettings
        ? this.clientSettings.request_status_finished
        : false;
    },

    //Применённый фильтьр
    filterApplied() {
      let f = {};
      //фильтр по оборудованию
      if (this.filter.equipment && this.filter.equipment.length > 0) {
        f["equipment_id"] = this.filter.equipment.map((v) => v.id);
      }
      //фильтр по приоритету
      if (this.filter.priority && this.filter.priority.length > 0) {
        f["priority_id"] = this.filter.priority.map((v) => v.id);
      }
      //фильтр по типу
      if (this.filter.type && this.filter.type.length > 0) {
        f["type_id"] = this.filter.type.map((v) => v.id);
      }
      //фильтр по статусу
      if (this.filter.status && this.filter.status.length > 0) {
        f["status_id"] = this.filter.status.map((v) => v.id);
      }
      //фильтр по ответственному подразделению
      if (
        this.filter.responsibleDepartment &&
        this.filter.responsibleDepartment.length > 0
      ) {
        f["responsible_department_id"] = this.filter.responsibleDepartment.map(
          (v) => v.id
        );
      }
      //фильтр по ответственному сотруднику
      if (
        this.filter.responsibleUser &&
        this.filter.responsibleUser.length > 0
      ) {
        f["responsible_user_id"] = this.filter.responsibleUser.map((v) => v.id);
      }

      //фильтр по автору
      if (this.filter.createdBy && this.filter.createdBy.length > 0) {
        f["created_by"] = this.filter.createdBy.map((v) => v.id);
      }

      //фильтр по подразделению автора
      if (
        this.filter.createdDepartment &&
        this.filter.createdDepartment.length > 0
      ) {
        f["created_department_id"] = this.filter.createdDepartment.map(
          (v) => v.id
        );
      }

      //фильтр по датам
      let di = 100;
      ["created", "deadline"].forEach(code => {

        if (!this.filter[code] || this.filter[code].length == 0) {
          return;
        }

        let fd = {
          LOGIC: "OR",
        };

        let field = code == 'created' ? 'date_create' : 'deadline';

        this.filter[code].forEach((t, i) => {

          let fds = {};
          if (t.from) {
            fds[">=" + field] = t.from;
          }
          if (t.to) {
            fds["<" + field] = moment(t.to, "DD.MM.YYYY")
              .add(1, "d")
              .format("DD.MM.YYYY");
          }

          fd[i] = fds;

        });

        f[di] = fd;
        di++;
      })

      //фильтр по оценке
      if (this.filter.rating && this.filter.rating.length > 0) {
        f["rating"] = this.filter.rating.map((v) => v.id);
      }

      //фильтр - просрочено
      if (this.filter.overdue && this.filter.overdue.length > 0) {
        this.filter.overdue.forEach(item => {
          if (item.id == "Y") {
            f["<deadline"] = moment().format("DD.MM.YYYY HH:mm:ss");
          } else if (item.id == 'N') {
            f[">deadline"] = moment().format("DD.MM.YYYY HH:mm:ss");
          }
          f["!status_id"] = this.requestStatusFinished
        });
      }

      return f;
    },

    filtered() {
      return Object.keys(this.filterApplied).length > 0;
    },

    //фильтр с наложением фильтра по вкладке
    filterCombined() {
      let filter = Object.assign({}, this.filterApplied);

      //активные
      if (this.currentTab == "active") {
        filter["0"] = {
          "!status_id": this.requestStatusFinished,
        };
      }
      // мне (либо мои либо назначенные на моя подразделение без исполнителя)
      else if (this.currentTab == "responsible") {
        filter["0"] = {
          LOGIC: "OR",
          responsible_user_id: this.user ? this.user.id : false,
          0: {
            responsible_user_id: false,
            responsible_department_id: this.user ? this.user.department_id : 0,
          },
        };
      }
      // мне (либо мои либо назначенные на моя подразделение без исполнителя)
      else if (this.currentTab == "owner") {
        filter["0"] = {
          LOGIC: "OR",
          created_by: this.user ? this.user.id : false,
          created_department_id: this.user ? this.user.department_id : false,
        };
      }
      // не прочитанные
      else if (this.currentTab == "unread") {
        let ids = this.requestUnread.map(r => r.id);

        filter["0"] = {
          id: ids.length > 0 ? ids : -1
        };
      }

      return filter;
    },
  },
  watch: {
    reload: function () {
      this.refresh();
    },
    filter: {
      handler() {
        if (this.$store.getters.isAuthorized) {
          this.refresh();
        }
      },

      deep: true,
    },
    tab: function (newVal) {
      this.currentTab = newVal;
    },
    currentTab: function () {
      this.$store.commit("setRequestsTab", this.currentTab);
      this.refresh();
    },
    user: function () {
      this.refresh();
    },
  },

  methods: {
    /**
     * Загружает данные
     */
    async loadData() {
      //покажем экран загрузки
      this.loading = true;
      //скроем метку "есть новые заявки"
      this.showNewRequests = false;

      //ждём получение настроек клиента (чтобы знать ИД статусов)
      await this.waitClientSettings();

      //запрашиваем данные
      rest
        .call("request.list", {
          method: "post",
          data: {
            order: this.sort,
            paging: this.pager,
            filter: this.filterCombined,
            loadPermissions: "Y",
          },
        })
        .then((data) => {
          if (data.success) {
            if (this.pager.page == 1) {
              this.items = data.items;
            } else {
              this.items = this.items.concat(data.items);
            }
            this.pager = data.pager;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Обработка события сохранения заявки
     */
    onRequestSave(id) {
      let item = this.items.find((i) => i.id == id);

      //если заявка в списке отображения уже есть
      if (item) {
        this.syncRequest(item);
      } else {
        this.refresh();
      }
    },

    /**
     * Синхронизация
     */
    syncRequest(item) {
      //запрашиваем данные
      rest
        .call("request.get", {
          method: "post",
          data: {
            id: item.id,
          },
        })
        .then((data) => {
          let index = this.items.indexOf(item);
          //если завка найдена
          if (data.success) {
            data.request.expanded = !!item.expanded;
            data.request.permissions = data.permissions;
            this.items[index] = data.request;
          }
          //иначе заявка не доступна и нужно её удалить из списка
          else {
            this.items.splice(index, 1);
          }
        });
    },

    /**
     * Перезагружает список
     */
    refresh() {
      this.items = null;
      this.pager.page = 1;
      this.loadData();
    },

    onScroll(e) {
      let scroll = e.target.scrollTop;
      let height = e.target.offsetHeight;
      let offsetTop = this.$refs.pager.offsetTop;
      //если экран прокрутился до пейджера (высоту экрана за вычетом 70px под меню которые заререзрвированы)
      if (scroll + height - 70 > offsetTop) {
        //если экран не в стадии загрузки и есть следующие страницы
        if (!this.loading && this.pager.page < this.pager.cnt) {
          this.pager.page++;
          this.loadData();
        }
      }
    },

    /**
     * Обработка порлучения сообщения через вебсокет
     */
    onMessageReceive(data) {
      //если событие создания новой заявки
      if (data.event == "new_request") {
        //если такой заявки нет в списке
        if (!this.items.find((i) => i.id == data.requestId)) {
          this.showNewRequests = true;
        }
      }

    },

    /**
     * Ожидает получение клиентских настроек
     */
    waitClientSettings() {

      //создаём прамис, чтобы вызывающий компонент ждал
      return new Promise(resolve => {

        //если настройки уже прогрузились
        if (this.clientSettings) {
          resolve(true);
        } else {

          //следим за изменением настроек
          const watcher = this.$store.watch((state, getters) => { return getters.getClientSettings }, (newVal) => {
            //если не пустые 
            if (newVal) {
              //прекращаем отслеживание
              watcher();
              resolve(true);
            }
          });
        }

      });
    },
  },

  created() {
    this.loading = true;
    this.currentTab = this.tab;
    if (this.requestStatusFinished > 0) {
      //загружаем данные
      this.loadData();
    }
  },

  mounted() {
    //подписваемся на события
    bus.on("SBS_REQUEST_FORM_E_SAVE", this.onRequestSave);
    bus.on("SBS_REQUEST_E_UPDATED", this.onRequestSave);
    bus.on("SBS_WS_MESSAGE_E_RECEIVE", this.onMessageReceive);

    //подписываемся на скролинг экрана
    this.$refs.container
      .closest(".view-content-scroll")
      .addEventListener("scroll", this.onScroll);
  },

  beforeUnmount() {
    this.$refs.container
      .closest(".view-content-scroll")
      .removeEventListener("scroll", this.onScroll);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_REQUEST_FORM_E_SAVE", this.onRequestSave);
    bus.off("SBS_REQUEST_E_UPDATED", this.onRequestSave);
    bus.off("SBS_WS_MESSAGE_E_RECEIVE", this.onMessageReceive);
  },
};
</script>

<style lang="scss">
.sbs-requests-list {
  padding-bottom: 80px;
  position: relative;

  //есть новая заявка
  .new-request {
    position: absolute;

    top: -30px;
    right: 0px;
    display: inline-block;
    text-transform: none;
    font-size: 14px;
    padding: 5px 10px;
    visibility: hidden;
    transform: scale(0, 0);
    transition: transform 0.5s cubic-bezier(0.25, 1.07, 0.54, 0.92);

    &.visible {
      visibility: visible;
      transform: scale(1, 1);
    }
  }

  .tabs {
    margin-top: -30px;
  }

  //Список пуст
  .empty-list {
    text-align: center;

    .title {
      color: var(--color-text-title);
      margin-bottom: 15px;
      font-size: 18px;
    }

    .text {
      font-size: 16px;
    }
  }

}
</style>