<template>
  <div class="sbs-request-master-multi">
    <div v-if="step == 1">
      <h2>Шаг №{{ step }} - выбор оборудования</h2>
      Оборудование можно выбрать, если отсканировать его NFC-метку, распознать
      QR-код, или сделать выбор вручную. <br /><br />

      <div class="btn btn-block btn1" @click="scanNfc">
        Сканировать NFC-метку
      </div>
      <div class="btn btn-block btn1" @click="scanQR">Сканировать QR-код</div>
      <div class="btn btn-block btn1" @click="selectEquipment">
        Выбрать из списка
      </div>

      <teleport to="#app">
        <sbs-dialog-select v-model:visible="showEquipmentList" title="Оборудование" method="equipment.list"
          v-model="equipment" />
      </teleport>
    </div>

    <div v-if="step == 2">
      <h2>Шаг №{{ step }} - Подразделение - исполнитель</h2>
      <br />

      <sbs-control-select v-model="fields.responsible_department_id"
        v-model:modelName="info.responsible_department_name" label="Подразделение - исполнитель" :required="true"
        method="department.list" :params="{
      filter: [
        {
          LOGIC: 'OR',
          active: 'Y',
          id: this.fields.responsible_department_id,
        },
      ],
      forResponsible: 'Y',
    }" />

      <sbs-control-select v-model="fields.type_id" v-model:modelName="info.type_name" label="Тип заявки"
        :required="true" method="dictionary.request.type.list" :params="{
      filter: [
        {
          LOGIC: 'OR',
          ACTIVE: 'Y',
          ID: this.fields.type_id,
        },
      ],
      forRequestCreate: 'Y',
    }" />

      <div class="btn btn1 btn-block" v-ripple.300 @click="next">
        Далее
      </div>


    </div>

    <div v-if="step == 3">
      <h2>Шаг №{{ step }} - Описание</h2>

      <div class="block">
        <div class="prop">
          <div class="label">Оборудование</div>
          <div class="value">{{ info.equipment_name }}</div>
        </div>
        <div class="prop">
          <div class="label">Подразделение - исполнитель</div>
          <div class="value">{{ info.responsible_department_name }}</div>
        </div>
      </div>

      <sbs-control-input v-model="fields.name" type="text" label="Название" :required="true">
        <template v-if="isNativePlatform" v-slot:icon>
          <div class="icon" @click="speechName">
            <span class="fi fi-mic"></span>
          </div>
        </template>
      </sbs-control-input>

      <sbs-control-select v-model="fields.priority_id" v-model:modelName="info.priority_name" label="Приоритет"
        :required="true" method="dictionary.request.priorities.list" />

      <sbs-control-checkbox v-model="fields.report_required" value="Y" label="Необходими отчёт при завершении" />

      <div>Для правильной диагностики проблемы Вы можете прикрепить фото.</div>
      <br />

      <sbs-control-photos v-model="photos" />

      <div class="btn btn1 btn-block" v-ripple.300 @click="next">Далее</div>
    </div>

    <div v-if="step == 4 && this.entityFields.length > 0">
      <h2>Шаг №{{ step }} - Дополнительные поля</h2>

      <sbs-fields-edit v-model="entityValues" entity="REQUEST" :settingsKey="typeId" :showTitle="false" />

      <div v-if="templateId != 0" class="btn btn1 btn-block" v-ripple.300 @click="next">
        Далее
      </div>
    </div>

    <div v-if="step == 4 && this.entityFields.length == 0 || step == 5">
      <h2>Шаг №{{ step }} - Подтверждение</h2>
      <div class="block">
        <div class="name">{{ fields.name }}</div>
        <div class="prop">
          <div class="label">Оборудование</div>
          <div class="value">{{ info.equipment_name }}</div>
        </div>
        <div class="prop">
          <div class="label">Подразделение - исполнитель</div>
          <div class="value">{{ info.responsible_department_name }}</div>
        </div>
        <div class="prop">
          <div class="label">Приоритет</div>
          <div class="value">{{ info.priority_name }}</div>
        </div>
        <div class="prop">
          <div class="label">Тип</div>
          <div class="value">{{ info.type_name }}</div>
        </div>

        <div class="prop" v-if="photos.length > 0">
          <div class="label">Фото</div>
          <div class="photos-mini row">
            <div class="col-6" v-for="photo in photos" :key="photo.id">
              <div class="photo" :style="{
      backgroundImage:
        'url(data:image/jpeg;base64,' + photo.data + ')',
    }"></div>
            </div>
          </div>
        </div>

        <sbs-fields-view :fields="entityFields" :values="entityValues" />
      </div>

      <div class="btn btn1 btn-block btn-save" :class="saving ? 'wait' : ''" v-ripple.300 @click="save">
        Сохранить и добавить ещё
        <div v-if="saving" class="btn-loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import sbsDialogSelect from "@/components/dialogs/select.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlSelect from "@/components/controls/select.vue";
import sbsControlPhotos from "@/components/controls/photos.vue";
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import sbsFieldsEdit from "@/components/fields.edit.vue";
import sbsFieldsView from '@/components/fields.view.vue';

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";
import { mapGetters } from "vuex";
import validator from "@/plugins/validator";

export default {
  name: "sbs-request-master-multi",
  components: {
    sbsDialogSelect,
    sbsControlInput,
    sbsControlSelect,
    sbsControlPhotos,
    sbsControlCheckbox,
    sbsFieldsEdit,
    sbsFieldsView
  },
  emits: ["update:step", "update:skipBack"],
  data() {
    return {
      showEquipmentList: false,
      showDepartmentList: false,
      fields: {
        report_required: "Y",
      },
      info: {},
      equipment: {},
      department: {},
      defaults: {},
      photos: [],
      saving: false,

      entityFields: [],
      entityValues: {}
    };
  },
  props: {
    step: {
      type: Number,
      default() {
        return 1;
      },
    },
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters({
      isNativePlatform: "isNativePlatform",
    }),

    /**
     * Тукущий тип заявки
     */
    typeId() {
      return this.fields ? this.fields.type_id : false
    }
  },
  watch: {
    step: function (newVal) {
      //шаг 1
      if (newVal == 1) {
        this.clear();
        this.loadDefaults();
      }
    },
    equipment: function () {
      if (this.equipment.id) {
        this.fields.equipment_id = this.equipment.id;
        this.info.equipment_name = this.equipment.name;
        this.next();
      }
    },

    department: {
      handler() {
        if (this.department.id) {
          this.fields.responsible_department_id = this.department.id;
          this.info.responsible_department_name = this.department.name;
          this.next();
        }
      },
      deep: true,
    },

    reload: function () {
      this.clear();
    },

    typeId() {
      this.loadEntityFields();
    }
  },

  methods: {

    /**
     * Очищает форму
     */
    clear() {

      this.fields = {
        name: "",
        equipment_id: "",
        responsible_department_id: "",
        type_id: this.fields.type_id ? this.fields.type_id : "",
        priority_id: this.fields.priority_id ? this.fields.priority_id : "",
        report_required: this.fields.report_required == "Y" ? "Y" : "N",
      };
      this.info = {
        equipment_name: "",
        responsible_department_name: "",
        type_name: this.info.type_name ? this.info.type_name : "",
        priority_name: this.info.priority_name ? this.info.priority_name : "",
      };

      this.photos = [];
      this.equipment = {};
      this.department = {};
    },

    /**
     * Сканпрование NFC-метки
     */
    scanNfc() {
      let vm = this;
      bus.emit("SBS_NFC_SCANNER_E_OPEN", {
        resolve: function (item) {
          vm.fields.equipment_id = item.id;
          vm.info.equipment_name = item.name;
          vm.next();
        },
      });
    },

    /**
     * Сканпрование QR
     */
    scanQR() {
      let vm = this;
      bus.emit("SBS_QR_SCANNER_E_OPEN", {
        resolve: function (item) {
          vm.fields.equipment_id = item.id;
          vm.info.equipment_name = item.name;
          vm.next();
        },
      });
    },

    /**
     * Клик по "Выбрать из списка"
     */
    selectEquipment() {
      this.showEquipmentList = true;
    },

    /**
     * Следующий шаг
     */
    next() {
      //проверка введённых данных
      if (!validator.check({ container: this.$el })) {
        return;
      }

      this.$emit("update:step", this.step + 1);
    },

    /**
     * Клик по "выбрать из списка" ля подразделения
     */
    selectDepartment() {
      this.showDepartmentList = true;
    },

    /**
     * Распознование речи для навания
     */
    speechName() {
      bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
        resolve: (text) => {
          this.fields.name = text;
        },
      });
    },

    /**
     * Загружает список доп полей
     */
    loadEntityFields() {
      rest.call("field.entity.fields", {
        method: "post",
        data: {
          entity: "REQUEST",
          key: this.typeId,
        },
      })
        .then((data) => {
          if (data.success) {
            this.entityFields = data.fields;
          }
        });
    },

    /**
     * Сохранение
     */
    save() {
      if (this.saving) {
        return;
      }
      this.saving = true;

      let photoData = this.photos.map((p) => p.data);

      //выполняем сохранение
      rest
        .call("request.add", {
          method: "post",
          data: {
            fields: this.fields,
            photos: photoData,
            entityFields: this.entityValues
          },
          timeout: 0,
        })
        .then((data) => {
          if (data.success) {
            this.onSave(data.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },

    /**
     * Обработка успешного сохранения
     */
    onSave(id) {
      //показываем сообщение об Успехе
      bus.emit("SBS_MESSAGE_E_SHOW", {
        message: "Заявка создана успешно.",
        type: "success",
      });

      //генерим событие успешного сохранения заявки
      bus.emit("SBS_REQUEST_FORM_E_SAVE", id);

      //сбрасываем название и фото
      this.photos = [];
      this.fields.name = "";
      //переходоим к шагу №3
      this.$emit("update:step", 3);
    },

    /**
     * Загружает значения по умолчанию
     */
    loadDefaults() {

      let arRequests = {
        departments: {
          ENTITY: "department",
          METHOD: "list",
          PARAMS: {
            filter: {
              active: 'Y'
            },
            forResponsible: 'Y'
          }
        },
        types: {
          ENTITY: "dictionary",
          METHOD: "request.type.list",
          PARAMS: {
            filter: {
              active: 'Y'
            },
            forRequestCreate: 'Y'
          }
        },
        defaultPriority: {
          ENTITY: "dictionary",
          METHOD: "request.priorities.list",
          PARAMS: {
            filter: {
              "!PROPERTY_IS_DEFAULT": false,
            },
          },
        },
      };

      //запрашиваем данные
      rest
        .batch(arRequests)
        .then((arResults) => {

          this.defaults = {};
          for (var key in arResults) {
            let data = arResults[key];
            if (key == "departments") {
              //если доступно только одно подразделени-исполнитель
              if (data.items.length == 1) {
                let department = data.items.find(i => i);
                this.defaults.department = department;
              }
            } else if (key == 'types') {
              //если доступен только один тип заявок
              if (data.items.length == 1) {
                let type = data.items.find(i => i);
                this.defaults.type = type;
              }
            } else if (key == 'defaultPriority') {
              //приоритет по умолчанию
              let priority = data.items.find(i => i);
              this.defaults.priority = priority;
            }
          }

          this.setDefaults();

        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        });
    },

    /**
     * Устанавливает значения по умолчанию
     */
    setDefaults() {

      //подразделение-исполнитель
      if (this.defaults.department) {
        this.fields.responsible_department_id = this.defaults.department.id;
        this.info.responsible_department_name = this.defaults.department.name;
      }

      //тип
      if (this.defaults.type) {
        this.fields.type_id = this.defaults.type.id;
        this.info.type_name = this.defaults.type.name;
      }

      //приоритет
      if (this.defaults.priority) {
        this.fields.priority_id = this.defaults.priority.id;
        this.info.priority_name = this.defaults.priority.name;
      }

    }
  },

  created() {
    //загружаем данные
    this.loadDefaults();
  },
};
</script>

<style lang="scss">
.sbs-request-master-multi {
  .btn {
    margin-bottom: 15px;
  }

  .btn-select-department {
    margin-bottom: 40px;
  }

  .block {
    padding: 10px;
    border-radius: 8px;
    background-color: var(--color-block-background);
    margin-bottom: 35px;

    .name {
      font-size: 16px;
      color: var(--color-block-text);
      line-height: 1.14;

      margin-bottom: 10px;
    }

    .prop {
      margin-bottom: 15px;
    }

    .prop:last-of-type {
      margin-bottom: 0px;
    }

    .label {
      color: var(--color-block-label);
      font-size: 14px;
    }

    .value {
      color: var(--color-block-value);
      font-size: 16px;
    }
  }

  .photos-mini {
    margin-top: 15px;

    .photo {
      position: relative;

      margin-bottom: 15px;
      height: 0px;
      padding-top: 177%;
      background-color: #000;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
}
</style>