import bus from '@/plugins/bus'
import errorListiner from '@/plugins/error.listiner'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { FileOpener } from '@capacitor-community/file-opener'
import { Capacitor } from '@capacitor/core';

export default {


    /**
     * Инициализация
     */
    init() {

        //подписываемся на событие вызова загрузки файла 
        bus.on('SBS_FILE_E_DOWNLOAD', (params) => {

            if (Capacitor.getPlatform() == "web") {
                this.downloadWeb(params);
            } else {
                this.save(params);
            }



        });
    },

    /**
     * Загрузка файла для web
     */
    downloadWeb(params) {
        let link = document.createElement("a");
        link.download = params.name;
        link.href = params.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    },

    /**
     * Загрузка файла
     */
    save(params) {
        const req = new XMLHttpRequest();
        req.open("GET", params.url, true);
        req.responseType = "blob";

        req.onload = () => {
            const blob = req.response;
            this.saveInDevice(blob, params.name);
        };

        req.onerror = function (e) {
            bus.emit("SBS_MESSAGE_E_SHOW", { message: "Не удалось загрузить файл." });
            errorListiner.onError(e);
        };

        req.send();
    },

    blobToBase64(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        })
    },

    /**
     * Сохранение бинарных данных на устройстве
     */
    async saveInDevice(blob, name) {

        let str = await this.blobToBase64(blob);

        let result = await Filesystem.writeFile({
            path: name,
            data: str,
            directory: Directory.Data
        });

        FileOpener.open({
            filePath: result.uri
        })

    }

}