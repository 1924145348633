<template>
  <div class="sbs-request-view">
    <sbs-tabs :items="tabs" v-model:active="tab" />

    <!-- О заявке -->
    <div v-if="tab == 'about'" class="block about">
      <div class="id">#{{ request.number }}</div>
      <div class="status" :style="{ backgroundColor: request.status_color }">
        {{ request.status_name }}
      </div>

      <div class="name">{{ request.name }}</div>

      <div class="prop report_required" v-if="request.report_required == 'Y'">
        <div class="label">Необходим отчёт при завершении</div>
      </div>

      <div class="prop" v-if="request.description && request.description.trim()">
        <div class="label">Описание</div>
        <div class="description" v-html="request.description"></div>
      </div>

      <div class="prop">
        <div class="label">Создана</div>
        <div class="value">
          {{ formatDate(request.date_create) }}
        </div>
      </div>

      <div class="prop">
        <div class="label">Изменена</div>
        <div class="value">
          {{ formatDate(request.date_update) }}
        </div>
      </div>
      <div class="prop">
        <div class="label">Крайний срок</div>
        <div class="value">

          <template v-if="permissions.includes('deadline')">
            <span class="edit" @click="openDeadlinePopup">
              {{ request.deadline ? formatDate(request.deadline) : "нет" }}
            </span>
          </template>
          <template v-else>
            {{ request.deadline ? formatDate(request.deadline) : "нет" }}
          </template>

        </div>
      </div>

      <div class="prop">
        <div class="label">Приоритет</div>
        <div class="value">
          {{ request.priority_name }}
          <span class="priority fi fi-fire" :style="{ color: request.priority_color }"></span>
        </div>
      </div>

      <div class="prop">
        <div class="label">Тип</div>
        <div class="value">{{ request.type_name }}</div>
      </div>

      <div class="prop prop-link" v-if="request.created_by > 0"
        @click="goTo('user-view', { id: request.created_by, service: service.toLowerCase() })">
        <div class="label">Автор</div>
        <div class="value">
          {{ request.created_by_user_name }}
          {{ request.created_by_user_last_name }}
        </div>
        <span class="arrow fi fi-arrow-right"></span>
      </div>

      <div class="prop prop-link" v-if="request.created_by_department_name"
        @click="goTo('department-view', { id: request.created_by_department_id, service: service.toLowerCase() })">
        <div class="label">Подразделение</div>
        <div class="value">
          {{ request.created_by_department_name }}
        </div>
        <span class="arrow fi fi-arrow-right"></span>
      </div>

      <div class="prop prop-link" v-if="request.equipment_id > 0"
        @click="goTo('equipment-view', { id: request.equipment_id })">
        <div class="label">Оборудование</div>
        <div class="value">{{ request.equipment_name }}</div>
        <span class="arrow fi fi-arrow-right"></span>
      </div>

      <div class="prop prop-link"
        @click="goTo('department-view', { id: request.responsible_department_id, service: service.toLowerCase() })">
        <div class="label">Подразделение - исполнитель</div>
        <div class="value">{{ request.responsible_department_name }}</div>
        <span class="arrow fi fi-arrow-right"></span>
      </div>

      <div class="prop prop-link" v-if="request.responsible_user_id > 0"
        @click="goTo('user-view', { id: request.responsible_user_id, service: service.toLowerCase() })">
        <div class="label">Исполнитель</div>
        <div class="value">
          {{ request.responsible_user_name }}
          {{ request.responsible_user_last_name }}
        </div>
        <span class="arrow fi fi-arrow-right"></span>
      </div>

      <div class="prop" v-if="request.start_date_plan">
        <div class="label">Плановая дата начала:</div>
        <div class="value">
          {{ formatDate(request.start_date_plan) }}
        </div>
      </div>

      <div class="prop" v-if="request.end_date_plan">
        <div class="label">Плановая дата завершения</div>
        <div class="value">
          {{ formatDate(request.end_date_plan) }}
        </div>
      </div>

      <div class="prop" v-if="request.duration_plan">
        <div class="label">Плановое время выполнения</div>
        <div class="value">
          {{ request.duration_plan }}
        </div>
      </div>

      <div class="prop" v-if="lastViewer">
        <div class="label">Просмотрели</div>
        <div class="value">
          {{ lastViewer.user_name }}
          {{ lastViewer.user_last_name }}
          <span v-if="request.viewers_count > 1">
            и
            <span class="show-viewers" @click="showViewers()">ещё {{ request.viewers_count - 1 }}</span>
          </span>
        </div>
      </div>

      <sbs-fields-view :fields="fields" />

      <div class="prop" v-if="request.rating > 0">
        <div class="label">Оценка</div>
        <div class="value">
          <sbs-control-stars :modelValue="request.rating" :readonly="true" :noMargin="true" />
        </div>
      </div>
      <div class="prop" v-if="request.rating_comment">
        <div class="label">Комментарий</div>
        <div class="value">
          {{ request.rating_comment }}
        </div>
      </div>

      <!-- Список просмотревших -->
      <sbs-request-viewers :id="request.id" v-if="request.viewers_count && request.viewers_count > 1"
        v-model:visible="viewersOpen" />
    </div>
    <!-- Обсуждение -->
    <sbs-request-view-chat v-show="tab == 'discussion'" :id="request.id" ref="chat" />

    <div v-if="tab == 'checklist'">
      <sbs-request-view-checklist :items="checklist" :permissions="permissions" :id="request.id" />
    </div>

    <!-- История -->
    <div v-if="tab == 'history'" class="history">
      <div class="row head align-items-center">
        <div class="col col-3">Дата</div>
        <div class="col col-3">Автор</div>
        <div class="col">Что изменено</div>
      </div>

      <div v-for="item in history" :key="item.id" class="row item">
        <div class="col col-3">{{ formatDate(item.date) }}</div>
        <div class="col col-3">
          {{ item.user_name }}
          {{ item.user_last_name }}
        </div>
        <div class="col">
          <div>{{ item.action_name }}</div>
          <div class="values" v-if="item.from.value || item.to.value">
            {{ item.from.value }}
            &rarr;
            {{ item.to.value }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <sbs-request-deadline v-model:visible="displayDeadlinePopup" :id="request.id" />
</template>

<script>
import sbsTabs from "@/components/tabs.vue";
import sbsRequestViewChat from "@/components/requests/view.chat.vue";
import sbsRequestViewChecklist from "@/components/requests/view.checklist.vue";
import sbsRequestViewers from "@/components/requests/viewers.vue";
import sbsFieldsView from '@/components/fields.view.vue';
import sbsControlStars from "@/components/controls/stars.vue";
import sbsRequestDeadline from "@/components/requests/deadline.vue";

import moment from "moment";

import { mapGetters } from "vuex";

export default {
  name: "sbs-request-view",
  components: {
    sbsTabs,
    sbsRequestViewChat,
    sbsRequestViewChecklist,
    sbsRequestViewers,
    sbsFieldsView,
    sbsControlStars,
    sbsRequestDeadline
  },
  data() {
    return {
      viewersOpen: false,

      tab: this.activeTab,

      displayDeadlinePopup: false
    };
  },

  props: {
    request: {
      type: Object,
      default() {
        return {};
      },
    },
    checklist: {
      type: Array,
      default() {
        return [];
      },
    },
    permissions: {
      type: Array,
      default() {
        return [];
      },
    },
    history: {
      type: Array,
      default() {
        return [];
      },
    },
    needNfcStart: {
      type: Boolean,
      default() {
        return false;
      },
    },
    lastViewer: {
      type: [Object, Boolean],
      default() {
        return false;
      },
    },
    fields: {
      type: Array,
      default() {
        return []
      }
    },

    activeTab: {
      type: String,
      default() {
        return "about";
      },
    },
  },

  computed: {

    ...mapGetters({
      requestUnread: "getRequestUnread",
      service: "getService"
    }),

    unread() {
      let r = this.requestUnread.find(r => r.id == this.request.id);
      if (r) {
        return r.unread_messages_cnt
      }
      return 0;
    },
    tabs() {
      let tabs = [
        {
          code: "about",
          name: "О заявке",
        },
        {
          code: "discussion",
          name: "Обсуждение",
          count: this.unread,
        },
      ];

      if (this.checklist.length > 0) {
        tabs.push({
          code: "checklist",
          name: "Чек-лист",
        });
      }

      tabs.push({
        code: "history",
        name: "История",
      });
      return tabs;
    },


  },

  watch: {
    activeTab: function (newVal) {
      if (newVal) {
        this.tab = newVal;
      }
    },

  },

  methods: {
    /**
     * Форматирует дату
     */
    formatDate(date) {
      return moment(date, "DD.MM.YYYY hh:mm:ss").format("DD.MM.YYYY HH:mm");
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    onBeforeRouteLeave() {
      if (this.$refs.chat) {
        //вызываем аналог события у дочернего компонента чата (чтобы запомнить прокрутку в чате)
        this.$refs.chat.onBeforeRouteLeave();
      }
    },

    /**
     * Вызывается перед переходом в этот экран
     */
    onBeforeRouteEnter() {
      if (this.$refs.chat) {
        //вызываем аналог события у дочернего компонента чата (чтобы восставноить прокрутку чата)
        this.$refs.chat.onBeforeRouteEnter();
      }
    },

    /**
     * Показывает список просмотревших
     */
    showViewers() {
      this.viewersOpen = true;
    },

    /**
     * Переход к просмотру 
     */
    goTo(name, params) {
      this.$router.push({
        name: name,
        params: params
      });
    },

    /**
     * Открыть окно редактирования крайнего срока
     */
    openDeadlinePopup() {
      this.displayDeadlinePopup = true;
    }
  },
};
</script>

<style lang="scss">
.sbs-request-view {
  .block {
    padding: 10px;
    border-radius: 8px;
    background-color: var(--color-block-background);
  }

  .about {
    position: relative;

    .id {
      font-size: 18px;
      color: var(--color-block-title);
    }

    .status {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 6px 5px;
      min-width: 90px;
      text-align: center;
      color: #1e1f22;
      border-radius: 4px;
      line-height: 1;
    }

    .name {
      font-size: 16px;
      color: var(--color-block-text);
      line-height: 1.14;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .prop {
      position: relative;
      margin-bottom: 10px;

      &.prop-link {
        padding-right: 30px;
      }

      .arrow {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        color: var(--color-text-label);
      }

      .label {
        color: var(--color-block-label);
        font-size: 1em;
      }

      .value {
        color: var(--color-block-value);
        font-size: 16px;

        .edit {
          cursor: pointer;
          display: inline-block;
          border-bottom: 1px dashed currentColor;
        }
      }

      &.report_required {
        position: relative;
        top: -10px;
        margin-bottom: 0px;

        .label {
          color: var(--color-color1);
        }
      }

    }



    .show-viewers {
      border-bottom: 1px dashed currentColor;
    }
  }

  .history {
    padding: 0px 15px;

    .head {
      border: 1px solid var(--color-color1);
      border-radius: 8px;

      .col {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .item {
      padding: 10px 0px;
      color: var(--color-block-label);
      border-bottom: 1px solid var(--color-block-label);
      line-height: 1;

      .values {
        margin-top: 4px;
      }
    }
  }
}
</style>