import axios from 'axios';
import store from '@/store';
import router from '@/router';
import bus from '@/plugins/bus';
import {AuthTokenExpiredError} from '@/plugins/errors.js'

const rest =  {

    timeout: 10000,
    refreshToken: false,

    /**
     * Вызов метода REST API
     * @param {string} method метод, например "auth.login"
     * @param {Object} config конфиг axios (без указания url)
     * @param {Boolean} silent тихий режим (при ошибке сети ничего не показывает экран ошибки сети)
     * @param {string} apiUrl URL API, если не задан, то берётся текущий
     * @returns 
     */
    call(method, config, silent = false, apiUrl = false) {

        let _config = config;
        _config.timeout = "timeout" in config ? config.timeout : this.timeout;

        if ((apiUrl === false || apiUrl == store.getters.getApiUrl) && method != "auth.token") {
            this.addToken(_config);
        }
        this.addWebsocketId(_config);

        apiUrl = apiUrl ? apiUrl : store.getters.getApiUrl;

        return axios(apiUrl + method, _config)
            .then((response) => {
                return this.processResponse(response, false);
            }).catch((error) => {
                return this.processError(error, method, config, silent, apiUrl);
            })

    },

    /**
     * Выполняет несколько методов REST API
     * @param {Array} requests массив запросов
     * @param {Boolean} silent тихий режим (при ошибке сети ничего не показывает экран ошибки сети)
     */
    batch(requests, silent = false) {

        let config = {
            method: 'post',
            timeout: this.timeout,
            data: {
                REQUESTS: requests
            }
        };

        this.addToken(config);
        this.addWebsocketId(config);

        return axios(store.getters.getApiUrl + "batch", config)
            .then((response) => {
                return this.processResponse(response, true);
            }).catch((error) => {
                return this.processError(error, "batch", config, silent);
            })

    },

    /**
     * Добавляет токен к запросу
     * @param {Object} config конфиг axios (без указания url)
     */
    addToken(config) {

        let token = store.getters.getAccessToken;
        if (token) {
            config.headers = {
                "Authorization": "Bearer " + store.getters.getAccessToken
            };
        }
    },

    /**
     * Добавляет UUID вебсокета к заголовкам запроса
     * @param {Object} config конфиг axios (без указания url)
     */
    addWebsocketId(config) {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers['sbs-ws-id'] = store.getters.getWebSocketId;
    },


    /**
     * Обрабатывает успешный ответ 
     * @param {Object} response объект axios
     * @param {Boolean} isBatch является ли запрос batch-запросом
     * @returns 
     */
    processResponse(response, isBatch) {

        let data = isBatch ? (response.data instanceof Object ? response.data[Object.keys(response.data)[0]] : response.data[0]) : response.data;

        //если API вернул ошибку
        if (!data.success) {
            //если требуется авторизация или токен устарел или аккаунт заблокирован
            if (data.errorCode === "AUTH_REQUIRED" || data.errorCode === "TOKEN_EXPIRED" || data.errorCode === "ACCOUNT_BLOCKED") {
                //генерим ошибку:
                //- во первых чтобы обработка ошибок от Axios была централизованная (см. catch ниже), 
                //- а во вторых чтобы ответ не дошёл до компонента-источника вызова (компоненты не должны использовать catch)
                throw this.createError(data.errorText, data.errorCode, response.config, response.request, response.data);
            }
        }

        return response.data;
    },

    /**
     * Обрабатывает ошибку
     * @param {Error} error 
     * @param {string} method метод
     * @param {Object} config  конфиг axios
     * @param {Boolean} silent тихий режим (при ошибке сети ничего не показывает экран ошибки сети)
     * @returns 
     * 
     */
    async processError(error, method, config, silent, apiUrl = false) {

        if (error.response) {

            if (error.code && error.code === "AUTH_REQUIRED") {
                //переход на экран авторзации
                router.replace({ name: 'login' })
            }
            else if (error.code && error.code === "TOKEN_EXPIRED") {
                //возвращаем прамис - обновление токена
                return this.refreshTokenAndContinue(method, config);
            }
            else if (error.code && error.code === "ACCOUNT_BLOCKED") {

                //если пользователь ещё авторизован
                if(store.getters.isAuthorized) {
                    //сброс данных авторизации и данных пользователя
                    store.dispatch("logout");
                    //показываем сообщение об ошибке
                    bus.emit('SBS_MESSAGE_E_SHOW', { message: "Ваш аккаунт заблокирован." });
                    throw new AuthTokenExpiredError("Ваш аккаунт заблокирован.")
                }
                //просто вернём ошибку дальше
                throw error;
            }
            else {
                //ответ получен, но статус не 200 (4xx,5xx)
                console.log("ответ получен, но статус не 200 (4xx,5xx), статус: " + error.response.status);
            }

        }
        //ответ не получен или запрос вообще не смог отправиться  
        else if (error.request) {

            //если тихий режим
            if (silent) {
                //просто вернём ошибку дальше
                throw error;
            }

            //если браузер имеет доступ к сети
            let isOnline = await this.isOnline();
            if (isOnline) {
                //показываем сообщение об ошибке
                bus.emit('SBS_MESSAGE_E_SHOW', { message: "Не удалось подключиться к серверу." });
                //вернём ошибку дальше
                throw error;
            }
            //иначе не удалось выполнить запрос по указанному URL
            else {
                bus.emit("NETWORK_ERROR");
                store.commit('setOnline', false);
                return this.waitAndContinue(method, config, apiUrl);
            }

        } else {
            console.log("другая ошибка");
        }

        return error;
    },

    /**
     * Ожидает подключения сети и продолжает выполнения метода REST
     * @param {string} method метод
     * @param {Object} config  конфиг axios
     * @returns 
     */
    async waitAndContinue(method, config, apiUrl = false) {

        //создаём прамис, чтобы вызывающий компонент ждал
        let result = await new Promise(resolve => {

            //следим за изменением переменной online
            const watcher = store.watch((state) => { return state.online }, (newVal) => {
                //если поменялся на true
                if (newVal) {
                    //прекращаем отслеживание
                    watcher();

                    //вызываем повторно метод REST API
                    this.call(method, config, false, apiUrl)
                        .then(data => {
      
                            resolve(data);
                        })
                }
            });
        });

        return result;
    },

    /**
     * Ожидает обновления токена и продолжает выполнения метода REST
     * @param {string} method метод
     * @param {Object} config конфиг axios
     * @returns 
     */
    refreshTokenAndContinue(method, config) {

        //создаём прамис, чтобы вызывающий компонент ждал
        return new Promise((resolve, reject) => {

            if (this.refreshToken) {

                let onRefresh = () => {
                    //вызываем повторно метод REST API
                    this.call(method, config)
                        .then(data => {
                            resolve(data);
                        });
                    bus.off('SBS_APP_E_REFRESH_TOKEN', onRefresh);
                };

                bus.on('SBS_APP_E_REFRESH_TOKEN', onRefresh);

            } else {

                this.refreshToken = true;

                //вызываем обновление токена
                this.call("auth.token", {
                    method: 'post',
                    data: {
                        refresh_token: store.getters.getRefreshToken
                    }
                })
                    .then(data => {

                        this.refreshToken = false;

                        if (data.success) {
                            this.onRefreshToken(data);
                            //передаём приложению данне об успешном обновлении токена
                            bus.emit('SBS_APP_E_REFRESH_TOKEN');

                            //вызываем повторно метод REST API
                            this.call(method, config)
                                .then(data => {
                                    resolve(data);
                                })
                        } else {

                            //сброс данных авторизации и пользователя
                            store.dispatch("logout");

                            //показываем сообщение об ошибке
                            bus.emit('SBS_MESSAGE_E_SHOW', { message: "Ваша авторизация истекла. Повторите вход." });

                            reject(new AuthTokenExpiredError("Ваша авторизация истекла. Повторите вход."));
                        }

                    });

            }
        });
    },

    /**
     * Обновление токена
     */
    onRefreshToken(data) {
      let arConnection = store.getters.getConnection;

      //меняем в текущем подключени токены
      arConnection["access_token"] = data.access_token;
      arConnection["refresh_token"] = data.refresh_token;

      //сохраняем подключение в текущем состоянии
      store.commit("updateConnection", arConnection);
    },

    /**
     * Создаёт ошибкуREST API
     * @param {string} message Текст ошибки
     * @param {string} code код ошибки
     * @param {Object} config конфиг запроса
     * @param {Object} request объект запроса
     * @param {Object} response объект ответа
     * @returns 
     */
    createError(message, code, config, request, response) {
        var error = new Error(message);

        if (code) {
            error.code = code;
        }

        error.request = request;
        error.response = response;
        error.isRestError = true;

        error.toJSON = function toJSON() {
            return {
                // Standard
                message: this.message,
                name: this.name,
                // Microsoft
                description: this.description,
                number: this.number,
                // Mozilla
                fileName: this.fileName,
                lineNumber: this.lineNumber,
                columnNumber: this.columnNumber,
                stack: this.stack,
                // Axios
                config: this.config,
                code: this.code
            };
        };

        return error
    },

    /**
     * Проверяем есть ли подключение к интернету
     */
    async isOnline() {
        if (!window.navigator.onLine) {
            return false;
        }

        // avoid CORS errors with a request to your own origin
        let url = new URL(window.location.origin);

        // random value to prevent cached responses
        url.searchParams.set('rand', Math.random().toString(36).substring(2, 15))

        try {
            const response = await fetch(url.toString(), { method: 'HEAD' });
            return response.ok;
        } catch {
            return false
        }
    }
}

export default rest;