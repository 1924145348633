<template>
  <div class="sbs-request-view-actions">
    <sbs-popup v-model:show="display" :loading="loading">
      <div class="actions">
        <!-- Редактировать -->
        <div v-if="permissions.includes('edit')" class="action" @click="edit">
          <span class="icon fi fi-edit"></span>
          <div class="text">Редактировать</div>
        </div>
        <!-- Назначить себе -->
        <div v-if="permissions.includes('take')" class="action" @click="take">
          <span class="icon fi fi-add-outline"></span>
          <div class="text">Назначить себе</div>
        </div>
        <!-- Делегировать -->
        <div v-if="permissions.includes('delegate')" class="action" @click="delegate">
          <span class="icon fi fi-sound-outline"></span>
          <div class="text">Делегировать</div>
        </div>
        <!-- Начать выполнение -->
        <div v-if="permissions.includes('start')" class="action" @click="start">
          <span class="icon fi fi-play-outline"></span>
          <div class="text">Начать выполнение</div>
        </div>
        <!-- Приостановить -->
        <div v-if="permissions.includes('stop')" class="action" @click="openStop">
          <span class="icon fi fi-pause-outline"></span>
          <div class="text">Приостановить</div>
        </div>
        <!-- Завершить -->
        <div v-if="permissions.includes('complete')" class="action" @click="complete">
          <span class="icon fi fi-stop-outline"></span>
          <div class="text">Завершить</div>
        </div>
        <!-- Вернуть в работу -->
        <div v-if="permissions.includes('reopen')" class="action" @click="reopen">
          <span class="icon fi fi-refresh"></span>
          <div class="text">Вернуть в работу</div>
        </div>
        <!-- Оценить -->
        <div v-if="permissions.includes('rate')" class="action" @click="openRate">
          <span class="icon fi fi-star"></span>
          <div class="text">Оценить</div>
        </div>

        <!-- Подписаться -->
        <div class="action" @click="subscribe" v-if="!subscribedInternal">
          <span class="icon fi fi-notifications"></span>
          <div class="text">Подписаться на обновления</div>
        </div>
        <!-- Отписаться -->
        <div class="action" @click="unSubscribe" v-else>
          <span class="icon fi fi-notifications-off"></span>
          <div class="text">Отписаться от обновлений</div>
        </div>

      </div>
    </sbs-popup>

    <sbs-popup v-model:show="displayNfc">
      Для начала работы необходимо отсканировать NFC метку оборудования
      <br /><br />
      <div class="btn btn-block btn1" @click="scanNfc">Сканировать NFC</div>
    </sbs-popup>

    <sbs-request-rate v-model:visible="displayRate" :id="id" />

    <sbs-request-stop v-model:visible="displayStop" :id="id" />


  </div>
</template>

<script>
import sbsPopup from "@/components/popup.vue";
import sbsRequestRate from "@/components/requests/rate.vue";
import sbsRequestStop from "@/components/requests/stop.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-request-view-actions",
  components: {
    sbsPopup,
    sbsRequestRate,
    sbsRequestStop
  },
  emits: ["update:show"],
  data() {
    return {
      loading: false,
      display: this.show,
      displayNfc: false,
      displayRate: false,
      displayStop: false,
      subscribedInternal: this.subscribed
    };
  },
  props: {
    id: {
      type: [Number, String],
      default() {
        return 0;
      },
    },
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    needNfcStart: {
      type: Boolean,
      default() {
        return false;
      },
    },
    permissions: {
      type: Array,
      default() {
        return [];
      },
    },
    checklist: {
      type: Array,
      default() {
        return [];
      },
    },
    equipmentId: {
      type: String,
      default() {
        return "";
      },
    },
    reportRequired: {
      type: String,
      default() {
        return "N";
      },
    },
    subscribed: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    checklistComplited() {
      return !(this.checklist.filter((i) => i.status == "N").length > 0);
    },
  },
  watch: {
    show: function (newVal) {
      this.display = newVal;
    },
    display: function (newVal) {
      this.$emit("update:show", newVal);
    },
  },

  methods: {
    close() {
      this.displayNfc = false;
      this.$emit("update:show", false);
    },

    /**
     * Редактировать
     */
    edit() {
      this.close();
      this.$router.push({ name: "request-form", params: { id: this.id } });
    },

    /**
     * Назначить себе
     */
    take() {
      this.loading = true;

      //выполняем запрос
      rest
        .call("request.take", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Начать выполнение
     */
    start() {
      if (!this.needNfcStart) {
        this.startImmediatly();
      } else {
        this.$emit("update:show", false);
        this.displayNfc = true;
      }
    },

    /**
     * Сканировать NFC метку
     */
    scanNfc() {
      let vm = this;
      this.displayNfc = false;
      bus.emit("SBS_NFC_SCANNER_E_OPEN", {
        resolve: function (item) {
          if (item.id == vm.equipmentId) {
            vm.startImmediatly();
          } else {
            bus.emit("SBS_MESSAGE_E_SHOW", {
              message:
                "Метка не закрепленна за оборудованием по данной заявке.",
            });
          }
        },
      });
    },

    /**
     * Начать выполнение
     */
    startImmediatly() {
      this.loading = true;

      //выполняем запрос
      rest
        .call("request.start", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Открывает попап остановки задачи
     */
    openStop() {
      this.close();
      this.displayStop = true;
    },

    /**
     * Завершить
     */
    complete() {

      //если чек-лист не пройден
      if (!this.checklistComplited) {
        this.close();

        this.$router.push({
          name: "request-checklist-execute",
          params: { id: this.id },
        });
        return;
      }

      //если требуется отчёт по завершении
      if (this.reportRequired == "Y") {
        this.close();

        this.$router.push({
          name: "request-report-execute",
          params: { id: this.id },
        });

        return;
      }
      this.loading = true;

      //выполняем запрос
      rest
        .call("request.complete", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Делегировать
     */
    delegate() {
      this.close();

      this.$router.push({
        name: "request-change-responsible",
        params: { id: this.id },
      });
    },

    /**
     * Подписаться
     */
    subscribe() {

      this.loading = true;

      //выполняем запрос
      rest
        .call("request.subscribe", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            this.subscribedInternal = true;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Отписаться
     */
    unSubscribe() {

      this.loading = true;

      //выполняем запрос
      rest
        .call("request.unsubscribe", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            this.subscribedInternal = false;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Возобновить
     */
    reopen() {

      this.loading = true;

      //выполняем запрос
      rest
        .call("request.reopen", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Открыть форму оценки заявки
     */
    openRate() {

      this.close();
      this.displayRate = true;
    },

  },
};
</script>

<style lang="scss">
.sbs-request-action-rate {
  padding: 50px 25px;

}
</style>