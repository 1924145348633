import router from '@/router'
import store from '@/store'
import bus from '@/plugins/bus'
import { NFC } from '@awesome-cordova-plugins/nfc';

export default {

    subscription: false,

    /**
     * Инициализация
     */
    init() {

        //подписываемся на событие вызова сканера
        bus.on('SBS_NFC_SCANNER_E_OPEN', (params) => {
            this.open(params);
        });

        //подписываемся на событие закрытие сканера
        bus.on('SBS_NFC_SCANNER_E_CLOSE', () => {
            this.close();
        });

    },

    /**
     * Открывает экран сканера
     */
    open(params) {

        router.push({ name: "scanner-nfc" });



        //задержка, чтобы экран успел загрузится и принимал события
        setTimeout(() => {

            //проверяем включен ли NFC
            NFC.enabled(
                () => {
                    if(store.getters.getPlatform == "android") {
                        this.androidScan(params);
                    } else if (store.getters.getPlatform == "ios") {
                        this.iosScan(params);
                    }
                },
                () => {
                    bus.emit("SBS_MESSAGE_E_SHOW", { message: "NFC не включен" });
                }
            );

        }, 500);


    },

    /**
     * Действие при закрытии сканера
     */
    close() {

        if(store.getters.getPlatform == "android") {
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
        } else if (store.getters.getPlatform == "ios") {
            NFC.cancelScan();
        }

        
    },

    /**
     * Сканирование для Android
     */
    androidScan(params) {
        this.subscription = NFC.addNdefListener(() => {
            bus.emit("SBS_NFC_SCANNER_E_READY", params);
        }, () => {
            bus.emit("SBS_MESSAGE_E_SHOW", { message: "Не удалось инициализировать NFC." });
        }).subscribe((event) => {
            this.onNfcRead(event);
        });
    },

    /**
     * Сканирование для ios
     */
    iosScan(params) {
        bus.emit("SBS_NFC_SCANNER_E_READY", params);
        
        NFC.scanTag().then(
            tag => {
                let event = {
                    tag: tag
                }
                this.onNfcRead(event);
            },
            err => {
                bus.emit("SBS_MESSAGE_E_SHOW", { message: err });
            });
    },

    /**
    * Обработка считывания метки NFC
    */
    onNfcRead(event) {

        let serial = [];
        for (let byte of event.tag.id) {
            serial.push(("0" + (byte & 0xff).toString(16)).slice(-2));
        }
        let id = serial.join(":").toUpperCase();

        bus.emit("SBS_NFC_SCANNER_E_SCAN", id);
    },

}