<template>
    <sbs-view :class="'sbs-v-request-templates'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
        <template v-slot:header>
            <sbs-header>
                <template v-slot:right>
                    <!-- Добавить -->
                    <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="onAddClick">
                        <span class="icon fi fi-add"></span>
                    </div>
                </template>
            </sbs-header>
        </template>

        <template v-slot:content>
            <div class="h1">Шаблоны заявок</div>

            <template v-if="items != null">

                <!-- Если список пуст-->
                <template v-if="items.length == 0">
                    <div class="empty-list">
                        <div class="title">Шаблонов нет...</div>
                        <div class="text">
                            Добавьте новые шаблоны. С помощью шаблонов Вы можете настроить выпуск новых заявок по
                            расписанию.
                        </div>
                        <div class="btn btn1 slim" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="onAddClick">+ Добавить
                        </div>
                    </div>
                </template>

                <sbs-request-templates-list v-else :items="items" />

            </template>

            <div ref="pager"></div>

            <!-- Загрузка -->
            <template v-if="loading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>


        </template>

    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsRequestTemplatesList from '@/components/request.templates/list.vue'

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-v-request-templates",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsRequestTemplatesList
    },
    data() {
        return {
            hideMenu: false,
            loading: true,
            items: null,
            pager: {
                size: 20,
                page: 1,
                cnt: 1,
            },
        };
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            //покажем экран загрузки
            this.loading = resolve ? false : true;

            //запрашиваем данные
            rest
                .call("request.template.list", {
                    method: "post",
                    data: {
                        paging: this.pager
                    },
                })
                .then((data) => {
                    if (data.success) {
                        if (this.pager.page == 1) {
                            this.items = data.items;
                        } else {
                            this.items = this.items.concat(data.items);
                        }
                        this.pager = data.pager;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    //скроем экран загрузки
                    this.loading = false;
                });
        },

        /**
         * Перезагрузка данных
         */
        refresh(resolve) {
            this.pager.page = 1;
            this.loadData(resolve);
        },

        /**
         * Обработка срола списка
         */
        onScroll(e) {
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;
            //если экран прокрутился до пейджера (высоту экрана за вычетом 70px под меню которые заререзрвированы)
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (!this.loading && this.pager.page < this.pager.cnt) {
                    this.pager.page++;
                    this.loadData();
                }
            }
        },

        /**
         * Клик по "Добавить"
         */
        onAddClick() {

            setTimeout(() => {
                this.$router.push({ name: "request-template-form", params: { id: 0 } });
            }, 300);
        },

        /**
        * Обработка события сохранения шаблона в форме
        */
        onTemplateSave(id) {

            let item = this.items.find((i) => i.id == id);

            //если шаблон в списке отображения уже есть
            if (item) {
                this.syncItem(item);
            } else {
                this.refresh(false);
            }
        },

        /**
         * Синхронизация
         */
        syncItem(item) {
            //запрашиваем данные
            rest
                .call("request.template.get", {
                    method: "post",
                    data: {
                        id: item.id,
                    },
                })
                .then((data) => {
                    let index = this.items.indexOf(item);
                    //если завка найдена
                    if (data.success) {
                        this.items[index] = data.template;
                    }
                    //иначе заявка не доступна и нужно её удалить из списка
                    else {
                        this.items.splice(index, 1);
                    }
                });
        },

        /**
         * Обработка удаления шаблона
         */
        onTemplateDelete() {
            this.refresh(false);
        }
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.loadData();
    },

    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);

        //подписваемся на события
        bus.on("SBS_REQUEST_TEMPLATE_FORM_E_SAVE", this.onTemplateSave);
        bus.on("SBS_REQUEST_TEMPLATE_FORM_E_DELETE", this.onTemplateDelete);
    },

    beforeUnmount() {
        this.$refs.pager
            .closest(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },

    unmounted() {
        //отписываемся от событий
        bus.off("SBS_REQUEST_TEMPLATE_FORM_E_SAVE", this.onTemplateSave);
        bus.off("SBS_REQUEST_TEMPLATE_FORM_E_DELETE", this.onTemplateDelete);
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },
}

</script>

<style lang="scss">
.sbs-v-request-templates {

    //Список пуст
    .empty-list {
        text-align: center;

        .title {
            color: var(--color-text-title);
            margin-bottom: 15px;
            font-size: 18px;
        }

        .text {
            font-size: 16px;
            margin-bottom: 15px;
        }
    }

}
</style>