<template>
  <sbs-view :class="'sbs-v-equipment-filter'" :isMenuHidden="hideMenu" :refreshDisabled="true" @refresh="refresh"
    ref="view">
    <template v-slot:header>
      <sbs-header />
    </template>
    <template v-slot:content>
      <div class="h1">Фильтр</div>

      <template v-if="loading">
        <sbs-loader-inline />
      </template>

      <template v-else>

        <div class="filters">
          <sbs-control-filter label="Подразделение" method="dictionary.departments.list" v-model="filter.department_id"
            :params="{
    filter: [
      {
        active: 'Y',
      },
    ],
  }" />
          <sbs-control-filter label="Тип" method="dictionary.equipment.types.list" v-model="filter.type_id" />

          <template v-for="field in fields" :key="field.id">
            <sbs-control-filter v-if="field.stat_items.length > 0" :label="field.name" :items="field.stat_items"
              v-model="filter['entity.' + field.code]" />
          </template>

        </div>

      </template>

    </template>

    <template v-slot:footer>
      <div class="buttons">
        <div class="container">
          <div class="page-padding">
            <div class="row">
              <div class="col-6">
                <div class="btn btn-block btn2" @click="clear">Сбросить</div>
              </div>
              <div class="col-6">
                <div class="btn btn-block btn1" @click="apply">Применить</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsControlFilter from "@/components/controls/filter";
import sbsLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-v-equipment-filter",
  components: {
    sbsView,
    sbsHeader,
    sbsControlFilter,
    sbsLoaderInline
  },
  data() {
    return {
      loading: false,
      fields: [],
      filter: {},
      reset: 0,
      hideMenu: false
    };
  },

  computed: {
    globalFilter() {
      return this.$store.getters.getFilter("EQUIPMENT");
    },
  },

  watch: {
    fields: function () {
      this.setDefaultsFields();
    }
  },

  methods: {

    getBatchRequests() {
      return {
        fields: {
          ENTITY: "equipment",
          METHOD: "field.statistics",
          PARAMS: {
          },
        }
      };

    },

    /**
     * Загрузка данных
     */
    loadData() {

      this.loading = true;

      rest.batch(this.getBatchRequests())
        .then((data) => {
          this.processResponse(data);
        })
        .finally(() => {
          this.loading = false;
        });

    },

    /**
     * Обработка результата загрузки
     */
    processResponse(results) {

      for (var key in results) {
        let data = results[key];

        if (data.success) {
          if (key == "fields") {
            this.fields = data.fields;
          }
        } else {
          //показываем сообщение об ошибке
          bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          return;
        }
      }
    },

    setDefaults() {
      this.filter = {
        department_id: [],
        type_id: [],
      };
      this.setDefaultsFields();
    },

    setDefaultsFields() {

      this.fields.forEach(field => {
        this.filter["entity." + field.code] = []
      });
    },

    apply() {
      //сохраняем Фильтр в текущем состоянии
      this.$store.commit("setFilter", {
        name: "EQUIPMENT",
        value: this.filter
      });

      //переход на предыдущий экран
      this.$router.go(-1);
    },

    /**
     * Сбросить фильтр
     */
    clear() {
      //сбрасываем фильтр
      this.setDefaults();
      //сохраняем Фильтр в текущем состоянии
      this.$store.commit("setFilter", {
        name: "EQUIPMENT",
        value: this.filter
      });
      //переход на предыдущий экран
      this.$router.go(-1);
    },

    refresh() {
      for (let code in this.filter) {
        if (this.globalFilter[code]) {
          this.filter[code] = this.globalFilter[code];
        } else {
          this.filter[code] = this.filter[code] instanceof Array ? [] : "";
        }
      }
    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
    this.setDefaults();
    this.refresh();
    this.loadData();
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.refresh();
  },

  /**
   * Вызывается перед переходом в этот экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.refresh();
    });
  },
};
</script>

<style lang="scss">
.sbs-v-equipment-filter {

  .buttons {
    padding: 20px 0px;
  }

}
</style>