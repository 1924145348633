import moment from "moment";
const format = "DD.MM.YYYY";

const types = [
    {
        id: "",
        name: "не выбрано",
        variants: [],
    },
    {
        id: "day",
        name: "день",
        variants: [
            {
                id: "yesterday",
                name: "вчера",
                nameFull: "вчера",
                from: moment().add(-1, "d").format(format),
                to: moment().add(-1, "d").format(format),
            },
            {
                id: "today",
                name: "сегодня",
                nameFull: "сегодня",
                from: moment().format(format),
                to: moment().format(format),
            },
            {
                id: "tomorrow",
                name: "завтра",
                nameFull: "завтра",
                from: moment().add(1, "d").format(format),
                to: moment().add(1, "d").format(format),
            },
        ],
    },
    {
        id: "week",
        name: "неделя",
        variants: [
            {
                id: "prev_week",
                name: "прошлая",
                nameFull: "прошлая неделя",
                from: moment().startOf("week").add("-7", "d").format(format),
                to: moment().endOf("week").add("-7", "d").format(format),
            },
            {
                id: "current_week",
                name: "текущая",
                nameFull: "текущая неделя",
                from: moment().startOf("week").format(format),
                to: moment().endOf("week").format(format),
            },
            {
                id: "next_week",
                name: "следующая",
                nameFull: "следующая неделя",
                from: moment().startOf("week").add("7", "d").format(format),
                to: moment().endOf("week").add("7", "d").format(format),
            },
        ],
    },
    {
        id: "month",
        name: "месяц",
        variants: [
            {
                id: "prev_month",
                name: "прошлый",
                nameFull: "прошлый месяц",
                from: moment().startOf("month").subtract(1, "M").format(format),
                to: moment().endOf("month").subtract(1, "M").format(format),
            },
            {
                id: "current_month",
                name: "текущий",
                nameFull: "текущий месяц",
                from: moment().startOf("month").format(format),
                to: moment().endOf("month").format(format),
            },
            {
                id: "next_month",
                name: "следующий",
                nameFull: "следующий месяц",
                from: moment().startOf("month").add(1, "M").format(format),
                to: moment().endOf("month").add(1, "M").format(format),
            },
        ],
    },
    {
        id: "quarter",
        name: "квартал",
        variants: [
            {
                id: "prev_quarter",
                name: "прошлый",
                nameFull: "прошлый квартал",
                from: moment().startOf("quarter").subtract(1, "Q").format(format),
                to: moment().endOf("quarter").subtract(1, "Q").format(format),
            },
            {
                id: "current_quarter",
                name: "текущий",
                nameFull: "текущий квартал",
                from: moment().startOf("quarter").format(format),
                to: moment().endOf("quarter").format(format),
            },
            {
                id: "next_quarter",
                name: "следующий",
                nameFull: "следующий квартал",
                from: moment().startOf("quarter").add(1, "Q").format(format),
                to: moment().endOf("quarter").add(1, "Q").format(format),
            },
        ],
    },
    {
        id: "year",
        name: "год",
        variants: [
            {
                id: "prev_year",
                name: "прошлый",
                nameFull: "прошлый год",
                from: moment().startOf("year").subtract(1, "y").format(format),
                to: moment().endOf("year").subtract(1, "y").format(format),
            },
            {
                id: "current_year",
                name: "текущий",
                nameFull: "текущий год",
                from: moment().startOf("year").format(format),
                to: moment().endOf("year").format(format),
            },
            {
                id: "next_year",
                name: "следующий",
                nameFull: "следующий год",
                from: moment().startOf("year").add(1, "y").format(format),
                to: moment().endOf("year").add(1, "y").format(format),
            },
        ],
    },
    {
        id: "interval",
        name: "интервал",
        variants: [],
    },
];

export default types
