<template>
  <sbs-view :class="'sbs-v-departments'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
    <template v-slot:header>
      <sbs-header />
    </template>
    <template v-slot:content>
      <div class="h1">Подразделения</div>
      <sbs-departments-list :reload="reload" />
    </template>
  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsDepartmentsList from "@/components/departments/list.vue";

export default {
  name: "sbs-v-departments",
  components: {
    sbsView,
    sbsHeader,
    sbsDepartmentsList,
  },
  data() {
    return {
      reload: 0,
      hideMenu: false
    };
  },

  methods: {
    refresh(resolve) {
      this.reload++;
      setTimeout(() => {
        resolve();
      }, 300);
    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
    });
  },
};
</script>

<style></style>