<template>
    <div class="sbs-equipment-widget" :class="{ open: open }">
        <div class="wrap">
            <div class="container">
                <div class="outer">
                    <div class="inner">
                        <div class="btn-widget" @click="onBtnClick">
                            <span class="icon icon-add fi fi-search"></span>
                            <span class="icon icon-close fi fi-close"></span>
                        </div>
                        <div class="actions">
                            <div class="action" @click="openQrScanner">
                                <span class="icon fi fi-qr-scanner"></span>
                            </div>
                            <div class="action" @click="openNfcScanner">
                                <span class="icon fi fi-nfc"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shadow" @click="onShadowClick"></div>
    </div>
</template>


<script>
import bus from "@/plugins/bus";
import rest from "@/plugins/rest";
import { NotFoundError } from '@/plugins/errors.js'

import { mapGetters } from "vuex";

export default {
    name: "sbs-equipment-widget",
    data() {
        return {
            open: false,
        };
    },

    computed: {
        ...mapGetters({
            access: "getAccess"
        }),

        permissions() {
            if (!this.access || !this.access.permissions.equipment) {
                return [];
            }

            return this.access.permissions.equipment;
        },


        /**
         * Доступность кнопки добавления
         */
        canAdd() {
            if (!this.access) {
                return false;
            }

            if (this.permissions.includes('MANAGE_ALL') ||
                this.permissions.includes('MANAGE_OWN_DEPARTMENT') ||
                this.permissions.includes('MANAGE_SELECTED_DEPARTMENTS')) {
                return true;
            }

            return false;

        }
    },
    methods: {
        /**
         * Клик по кнопке
         */
        onBtnClick() {
            this.open = !this.open;
        },

        /**
         * Клик по тени
         */
        onShadowClick() {
            this.open = false;
        },

        /**
         * Переход к Сканеру QR
         */
        openQrScanner() {
            this.open = false;

            let vm = this;
            bus.emit("SBS_QR_SCANNER_E_OPEN", {
                mode: "SIMPLE",
                resolve: function (code) {

                    //пробуем найти оборудование
                    vm.loadEquipment({
                        PROPERTY_QR_CODE: code,
                    }).then((item) => {
                        //переход к просмотру
                        vm.goToEquipmentView(item);
                    }).catch(error => {
                        //если не найдено
                        if (error instanceof NotFoundError) {
                            //если есть право добавления
                            if (vm.canAdd) {
                                vm.confirmCreateIfNotFound({ qr_code: code });
                            } else {
                                bus.emit("SBS_MESSAGE_E_SHOW", {
                                    message: "По данному QR оборудование не найдено",
                                });
                            }

                        }
                    })

                },
            });
        },

        /**
         * Переход к сканеру NFC
         */
        openNfcScanner() {

            this.open = false;

            let vm = this;
            bus.emit("SBS_NFC_SCANNER_E_OPEN", {
                mode: "SIMPLE",
                resolve: function (code) {

                    //пробуем найти оборудование
                    vm.loadEquipment({
                        PROPERTY_NFC_ID: code,
                    }).then((item) => {
                        //переход к просмотру
                        vm.goToEquipmentView(item);
                    }).catch(error => {
                        //если не найдено
                        if (error instanceof NotFoundError) {
                            //если есть право добавления
                            if (vm.canAdd) {
                                vm.confirmCreateIfNotFound({ nfc_id: code });
                            } else {
                                bus.emit("SBS_MESSAGE_E_SHOW", {
                                    message: "По данной метке оборудование не найдено",
                                });
                            }

                        }
                    })

                },
            });
        },

        /**
         * Переход к просмотру оборудования
         */
        goToEquipmentView(item) {

            //даём время чтобы возврат назад по  истории брузера прошёл
            setTimeout(() => {
                this.$router.push({
                    name: "equipment-view",
                    params: { id: item.id },
                });

            }, 800);
        },

        /**
         * Загрузка оборудования по фильтру
         */
        loadEquipment(filter) {

            return new Promise((resolve, reject) => {

                rest
                    .call("equipment.list", {
                        method: "post",
                        data: {
                            filter: filter,
                        },
                    })
                    .then((data) => {
                        if (data.success) {
                            if (data.items.length > 0) {
                                let item = data.items[0];
                                resolve(item);
                            } else {
                                reject(new NotFoundError("Not found"));
                            }
                        } else {
                            //показываем сообщение об ошибке
                            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                            reject(false);
                        }

                    });

            });
        },

        /**
         * Запрос добавления оборудования если не найдено
         */
        confirmCreateIfNotFound(data) {

            bus.emit("SBS_CONFIRM_E_SHOW", {
                message: "Оборудование не найдено. Добавить по указанному коду новое оборудование?",
                resolveText: "Добавить",
                rejectText: "Отменить",
                resolve: function (value) {

                    if (value) {
                        this.$store.commit("setTempData", { name: "equipment-form-data", value: data });
                        //переход на экран добавления заявки
                        this.$router.push({ name: "equipment-form", params: { id: 0 } });
                    }

                },
            });

        }


    },
};
</script>

<style lang="scss">
.sbs-equipment-widget {
    .wrap {
        position: absolute;
        bottom: 80px;
        right: 0px;
        left: 0px;
        z-index: 1150;

        .outer {
            position: relative;
        }

        .inner {
            position: absolute;
            right: 5px;
            bottom: 0px;
        }
    }

    .btn-widget {
        position: relative;
        border-radius: 50%;
        border: 1px solid var(--color-color1);
        background-color: var(--color-block-background);
        width: 56px;
        height: 56px;
        color: var(--color-color1);

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: -2px;
            right: -2px;
            bottom: -2px;
            left: -2px;
            border-radius: 50%;
            border: 1px solid var(--color-block-background);
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
        }

        .icon-close {
            opacity: 0;
        }
    }

    &.open {
        .btn-widget {
            background-color: var(--color-color1);
            color: var(--color-text);

            .icon-close {
                opacity: 1;
            }

            .icon-add {
                opacity: 0;
            }
        }
    }

    .shadow {
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        visibility: hidden;
        z-index: 1100;

        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.open {
        .shadow {
            visibility: visible;
            opacity: 1;
            transition: visibility 0s linear 0s, opacity 300ms;
        }
    }

    .actions {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms, bottom 300ms;
    }

    &.open {
        .actions {
            visibility: visible;
            opacity: 1;
            bottom: 100%;
            transition: visibility 0s linear 0s, opacity 300ms, bottom 300ms;
        }
    }

    .action {
        position: relative;
        border-radius: 50%;
        border: 1px solid var(--color-color1);
        background-color: var(--color-block-background);
        width: 56px;
        height: 56px;
        margin-bottom: 15px;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: var(--color-color1);
        }
    }
}
</style>