<template>
  <sbs-view :class="'sbs-v-request-form'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:right> </template>
      </sbs-header>
    </template>
    <template v-slot:content>
      <div class="h1">
        <template v-if="mode == 'ADD'"> Добавление заявки </template>
        <template v-else> Изменение заявки </template>
      </div>

      <sbs-request-form :id="id" v-model:checkLeave="checkLeave" :reset="reset" />
    </template>
    <template v-slot:footer>
      <div id="sbs-rfvb"></div>
    </template>

  </sbs-view>
</template>

<script>
import bus from "@/plugins/bus";

import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsRequestForm from "@/components/requests/form.vue";

export default {
  name: "sbs-v-request-form",
  components: {
    sbsView,
    sbsHeader,
    sbsRequestForm,
  },
  data() {
    return {
      showConfirm: false,
      checkLeave: true,
      reset: 0,
      hideMenu: false
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    mode() {
      return this.id > 0 ? "EDIT" : "ADD";
    },
  },

  methods: {},

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  /**
   * Вызывается перед переходом в этот экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //выставляем необходимость подтверждения выхода из экрана
      vm.checkLeave = true;
      if (vm.id > 0) {
        vm.reset++;
      }
    });
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  async beforeRouteLeave(to) {
    if (to.meta.skipConfirm) {
      return true;
    }

    if (this.checkLeave) {
      let result = await new Promise((resolve) => {
        bus.emit("SBS_CONFIRM_E_SHOW", {
          message:
            this.mode == "ADD"
              ? "Отменить создание заявки?"
              : "Отменить изменение заявки?",
          resolveText: "Продолжить заполнение",
          rejectText: "Отменить",
          resolve: function (value) {
            resolve(value);
          },
          reverse: true,
        });
      });

      return result;
    }
    return true;
  },
};
</script>

<style  lang="scss"></style>