<template>
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transitionName" :css="route.meta.transitionName ? true : false">
      <keep-alive :include="include">
        <component :is="Component" />
      </keep-alive>
    </transition>
  </router-view>

  <sbs-menu />
  <sbs-message />
  <sbs-confirm />
  <sbs-network-error />
  <sbs-update />
  <sbs-speech />
</template>

<script>
import bus from "@/plugins/bus";
import { mapGetters } from "vuex";
import ws from "@/plugins/websocket";

import sbsMessage from "@/components/message";
import sbsConfirm from "@/components/confirm";
import sbsNetworkError from "@/components/network-error";
import sbsMenu from "@/components/menu";
import sbsUpdate from "@/components/update";
import sbsSpeech from "@/components/speech";

export default {
  name: "App",
  components: {
    sbsMessage,
    sbsConfirm,
    sbsNetworkError,
    sbsMenu,
    sbsUpdate,
    sbsSpeech
  },
  data() {
    return {
      qrScannerResolve: false,
    };
  },
  computed: {
    ...mapGetters({
      theme: "getTheme",
      isAuthorized: "isAuthorized",
      isConnectionChanging: "isConnectionChanging",
      badgetCount: "getBadgetCount",
      user: "getUserProfile",
      service: "getService"
    }),

    include() {
      //если не авторизован или идёт смена аккаунта
      if (!this.isAuthorized || this.isConnectionChanging) {
        //разрешаем кешировать только экран входа (т.е. по сути сбрасываем все остальные экраны)
        return ["sbs-v-login"];
      }

      let routes = this.$router
        .getRoutes()
        .filter((r) => r.meta.noCache !== true);
      let components = routes.map((r) => r.components.default.name);
      return components;
    },
  },
  watch: {
    theme: function () {
      this.applyTheme();
    },
    badgetCount: function () {
      //обновление счётчика у иконки приложения
      bus.emit("SBS_BADGE_E_SET", this.badgetCount);
    }
  },
  methods: {
    /**
     * Применение темы
     */
    applyTheme: function () {
      let root = document.documentElement;
      root.classList.remove("theme-" + root.dataset.theme);
      root.classList.add("theme-" + this.theme);
      root.dataset.theme = this.theme;

      //генерим событие завершения смены темы
      bus.emit("SBS_APP_E_THEME_CHANGED", this.theme);
    },

    /**
     * Обработка входа пользователя
     */
    async onLogin() {
      //сохраняем FCM токен на сервере
      bus.emit("SBS_PUSH_E_SAVE_TOKEN_TO_SERVER");

      //регистрируем подключение по вебсокету
      ws.register();

      //загрузим настройки клиента
      try {
        await this.$store.dispatch("loadClientSettings");
      } catch (e) {
        //здесь уже подразумевается, что приложение загружено
        //может пользователь добавляет новый аккаунт
        //а может разлогинился и пытается снова зайти
        //так вот в этом случае все ошибки через bus будут показываться 
        //стандартным нашим уведолмением
        //а вот в main.js уведомления недоступны
        //поэтому пришлось в loadClientSettings сделать Reject и в местах использования try/catch
      }

      //загрузим профиль пользователя
      this.$store.dispatch("loadUserData");

      //переход на главный экран
      this.$router.replace({ name: "home" });
    },

    /**
     * Выход пользователя
     */
    logout() {

      //специально убрал весь код по сбросу авторизации в store
      //так как при старте страницы ещё до создания/монтирования App
      //выполняется запрос на получение настроек и если в ответе
      //будет "срок действия токена истёк", то нужно как-то сбросить авторизацию
      //без App

      //переход на страницу входа
      this.$router.push({ name: "login" });

    },

    /**
     * Смена аккунта
     */
    accountChange(key) {
      //закрываем подключение по вебсокету
      ws.close();
      //сбрасываем текущее состояние
      this.$store.dispatch("reset");
      //фиксируем факт, что идёт смена аккаунта
      this.$store.commit("setConnectionChanging", true);
      //удаляем FCM токен с сервера
      bus.emit("SBS_PUSH_E_DELETE_TOKEN_FROM_SERVER");
      //меняем ключ текущего подключения
      this.$store.commit("setCurrentConnection", key);

      setTimeout(() => {
        //завершаем смену аккаунта
        this.$store.commit("setConnectionChanging", false);

        //событие завершения входа пользователя
        bus.emit("SBS_APP_E_USER_LOGIN");
      }, 100);
    },

    /**
     * Обработка обновления пользователя
     */
    onUserUpdate() {
      //загрузим профиль пользователя
      this.$store.dispatch("loadUserData");
    },

    /**
     * Обработка порлучения сообщения через вебсокет
     */
    onMessageReceive(data) {

      //если событие - новое сообщение в чате заявки
      if (data.event == "new_request_message") {
        //если автор не текущий пользователь
        if (this.user && this.user.id != data.userId) {
          //увеличиваем кол-во непрочитанных у заявки
          this.$store.commit("increaseRequestUnread", { id: data.requestId, count: 1 });
        }
      }
      //если факт прочтения сообщений (нескольких)
      else if (data.event == "request_message_read") {

        //добавляем сообщение в стэк прочитанных сообщений, чтобы Push по такому сообщению уже не показывать
        this.$store.commit("addReadMessages", data.ids);
        //обновляем по заявке кол-во непрочитанных сообщений
        this.$store.commit("updateRequestUnread", { id: data.requestId, count: data.unread_count });

      }
    }
  },

  mounted() {
    this.applyTheme();

    //проверяем настйроки оптимизации приложения Android
    setTimeout(() => {
      bus.emit("SBS_POWER_OPTIMIZATIO_E_CHECK");
    }, 500);

    //подписваемся на событие авторизации пользователя
    bus.on("SBS_APP_E_USER_LOGIN", this.onLogin);
    //подписваемся на событие выхода
    bus.on("SBS_APP_E_LOGOUT", this.logout);
    //подписываемся на событие смены аккаунта
    bus.on("SBS_APP_E_ACCOUNT_CHANGE", this.accountChange);
    //событие обновления профиля
    bus.on("SBS_USER_PROFILE_E_UPDATE", this.onUserUpdate);
    //событие получения сообщения через веб-сокет
    bus.on("SBS_WS_MESSAGE_E_RECEIVE", this.onMessageReceive);
  },
  unmounted() {
    //отписываемся от событий
    bus.off("SBS_APP_E_USER_LOGIN", this.onLogin);
    bus.off("SBS_APP_E_LOGOUT", this.logout);
    bus.off("SBS_APP_E_ACCOUNT_CHANGE", this.accountChange);
    bus.off("SBS_USER_PROFILE_E_UPDATE", this.onUserUpdate);
    bus.off("SBS_WS_MESSAGE_E_RECEIVE", this.onMessageReceive);
  },
};
</script>

<style lang="scss">
#app {
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
