<template>
    <sbs-sheet v-model:visible="visibleInternal">
        <template v-slot:body>
            <div class="sbs-request-stop">

                <template v-if="loading">
                    <div class="text-center">
                        <sbs-loader-inline />
                    </div>
                </template>

                <template v-else>
                    <div ref="form">

                        <sbs-control-datetime v-model="fields.deadline" label="Крайний срок" />


                        <div class="btn btn1 btn-block" :class="saving ? 'wait' : ''" v-ripple.300 @click="save">
                            Остановить
                            <div v-if="saving" class="btn-loader"></div>
                        </div>

                    </div>

                </template>
            </div>
        </template>
    </sbs-sheet>
</template>

<script>
import sbsSheet from '@/components/sheet.vue'
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlDatetime from "@/components/controls/datetime.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";

import { mapGetters } from "vuex";

export default {
    name: "sbs-request-stop",
    emits: ["update:visible"],
    components: {
        sbsSheet,
        sbsLoaderInline,
        sbsControlDatetime
    },
    data() {
        return {
            visibleInternal: this.visible,
            saving: false,
            loaded: false,
            loading: false,

            fields: {
                deadline: "",
            },

            errors: {
                deadline: false,
            },
            valids: {
                deadline: true,
            }

        }
    },
    props: {
        id: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        ...mapGetters({
            isNativePlatform: "isNativePlatform",
        }),
    },
    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
        },
        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);

            if (this.visibleInternal) {
                this.onOpen();
            }
        }
    },

    methods: {

        /**
         * Дейтвие при открытии
         */
        onOpen() {

            if (!this.loaded) {
                this.loadData();
            }

        },

        /**
         * Загрузка данных о текущем крайнем сроке
         */
        loadData() {

            //покажем экран загрузки
            this.loading = true;

            //запрашиваем данные
            rest
                .call("request.get", {
                    method: "post",
                    data: {
                        id: this.id
                    }
                })
                .then((data) => {
                    if (data.success) {
                        this.fields.deadline = data.request.deadline;

                        this.loaded = true;

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    //скроем экран загрузки
                    this.loading = false;
                });
        },

        /**
         * Сохранение оценки
         */
        save() {

            //првоерка введённых данных
            if (!this.checkValues()) {
                return;
            }

            if (this.saving) {
                return;
            }
            this.saving = true;

            //выполняем сохранение
            rest
                .call("request.stop", {
                    method: "post",
                    data: {
                        id: this.id,
                        deadline: this.fields.deadline
                    }
                })
                .then((data) => {
                    if (data.success) {
                        this.visibleInternal = false;

                        let vm = this;
                        setTimeout(() => {
                            vm.reset();
                            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
                            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
                        }, 400)

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.saving = false;
                });

        },

        /**
         * Проверяет значения
         */
        checkValues(showErrors = true) {

            Object.keys(this.fields).forEach((field) => {
                let error = !this.valids[field];

                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors[field] = error;
                }
            });

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }

            return true;
        },

        /**
         * Сброс полей
         */
        reset() {
            this.fields = {
                deadline: "",
            };

            this.valids = {
                deadline: true,
            }

            this.errors = {
                deadline: false,
            }
        }

    },

}
</script>

<style lang="scss">
.sbs-request-stop {
    padding: 50px 25px;

}
</style>