<template>
  <div class="sbs-control-filter-dates" @click="onClick">
    <div class="label">
      {{ this.label }}
      <div class="icon">
        <span class="fi fi-arrow-right"></span>
      </div>
    </div>
    <TransitionGroup v-if="values.length > 0" class="values" name="values" tag="div">
      <div v-for="value in values" :key="value.id" class="value btn btn1" @click.stop="removeValue(value.id)">
        {{ formatName(value) }}
        <span class="icon fi fi-close"></span>
      </div>
    </TransitionGroup>

    <teleport to="#app">
      <sbs-dialog-dates v-model:visible="showDialog" :title="label" v-model:value="value" v-model:dateFrom="from"
        v-model:dateTo="to" />
    </teleport>
  </div>
</template>


<script>
import sbsDialogDates from "@/components/dialogs/dates.vue";

import types from "@/plugins/dates.variants";

export default {
  name: "sbs-control-filter-dates",
  components: {
    sbsDialogDates,
  },
  data() {
    return {
      values: this.modelValue,
      value: false,
      from: "",
      to: "",
      showDialog: false,
    };
  },
  emits: ["update:dateFrom", "update:dateTo", "update:modelValue"],
  props: {
    label: {
      type: String,
      default() {
        return "Метка";
      },
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    modelValue: function (newVal) {
      this.values = newVal;
    },

    values: function () {
      this.$emit("update:modelValue", this.values);
    },

    value: function (newVal) {
      if (newVal) {
        //пробуем найти такео значение в списке
        let i = this.values.find(
          (i) => i.from == newVal.from && i.to == newVal.to
        );
        if (!i) {
          this.values.push(newVal);
          this.from = "";
          this.to = "";
        }
      }
    },
  },

  methods: {
    /**
     * Клик по фильтру
     */
    onClick() {
      this.showDialog = true;
    },

    removeValue(id) {
      this.values = this.values.filter((item) => item.id != id);
    },

    formatName(item) {
      let type = types.find(
        (t) =>
          t.variants.filter((v) => v.from == item.from && v.to == item.to)
            .length > 0
      );
      if (type) {
        let variant = type.variants.find(
          (v) => v.from == item.from && v.to == item.to
        );
        if (variant) {
          return variant.nameFull;
        }
      }
      return "с " + item.from + " по " + item.to;
    },
  },
};
</script>

<style lang="scss">
.sbs-control-filter-dates {
  margin: 0px -20px;
  padding: 15px 20px;
  background-color: var(--color-block-background);
  margin-bottom: 2px;

  .label {
    position: relative;
    color: var(--color-block-value);
    font-weight: 700px;
    padding-right: 50px;

    .icon {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      color: var(--color-icon-active);
    }
  }

  .values-enter-active,
  .values-leave-active {
    transition: all 0.5s ease;
  }

  .values-enter-from,
  .values-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .values {
    margin-top: 10px;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .value {
      display: inline-block;
      text-transform: none;
      margin-bottom: 6px;
      padding: 7px 40px 7px 10px;
      font-size: 14px;
      margin-right: 6px;
      text-align: left;

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 13px;
        font-weight: 17px;
      }
    }
  }
}
</style>
