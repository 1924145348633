<template>
  <sbs-view :class="'sbs-v-request-master-multi'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:right>
          <div class="action sbs-rmm-ic" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="close">
            <span class="icon fi fi-close"></span>
          </div>
        </template>
      </sbs-header>

    </template>
    <template v-slot:content>
      <div class="h1">РЕЖИМ АУДИТА</div>

      <sbs-request-master-multi :reload="reload" v-model:step="step" v-model:skipBack="skipBack" />
    </template>

  </sbs-view>
</template>

<script>
import bus from "@/plugins/bus";

import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsRequestMasterMulti from "@/components/requests/master.multi.vue";

export default {
  name: "sbs-v-request-master-multi",
  components: {
    sbsView,
    sbsHeader,
    sbsRequestMasterMulti,
  },
  data() {
    return {
      reload: 0,
      step: 1,
      skipBack: false,
      closing: false,
      hideMenu: false
    };
  },

  methods: {
    /**
     * Закрывает экран
     */
    close() {
      this.closing = true;
      //переход обратно
      this.$router.go(-1);
    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  /**
   * Вызывается перед переходом в этот экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //Если возврат с экрана сканера QR или NFC
      if (from.meta.skipConfirm) {
        return;
      }
      vm.skipBack = false;
      vm.closing = false;
      vm.step = 1;
    });
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  async beforeRouteLeave(to) {
    if (to.meta.skipConfirm || this.skipBack) {
      return true;
    }

    //если шаг 1 - то запросим подтверждение выхода
    if (this.step == 1 || this.closing) {
      let result = await new Promise((resolve) => {
        bus.emit("SBS_CONFIRM_E_SHOW", {
          message: "Отменить создание заявки?",
          resolveText: "Продолжить заполнение",
          rejectText: "Отменить",
          resolve: function (value) {
            resolve(value);
          },
          reverse: true,
        });
      });
      return result;
    }

    //иначе делаем шаг назад
    this.step--;
    return false;
  },
};
</script>

<style  lang="scss">
.sbs-rmm-ic {
  .icon {
    font-size: 14px !important;
  }
}
</style>

