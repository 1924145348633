<template>
  <div class="sbs-department-view">
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>

    <template v-if="!loading">
      <!-- Не найдена-->
      <div v-if="department == null">Подразделение не найдено</div>
      <!-- Иначе -->
      <div v-else>
        <h1 v-html="department.name"></h1>

        <div class="description" v-if="department.detail_text.trim() != ''" v-html="department.detail_text"></div>

        <sbs-props :props="department.props" />

        <div class="childs" v-if="childs.length > 0">
          <h2>Подразделения</h2>
          <sbs-department-item v-for="item in childs" :key="item.id" :item="item" />
        </div>

        <div class="equipment-types" v-if="equipment_types.length > 0">
          <h2>Оборудование</h2>
          <template v-for="item in equipment_types" :key="item.id">
            <div class="item" @click="onEquipmentTypeClick(item)">
              <div class="name">{{ item.type_name }}</div>
              <div class="cnt">{{ item.cnt }}</div>
              <span class="icon fi fi-arrow-right"></span>
            </div>
          </template>
        </div>

        <sbs-requests-statuses :items="requests_created" :prefilter="prefilterOwn" :title="'Собственные заявки'"
          titleClass="h2" />

        <sbs-requests-statuses :items="requests_reponsible" :prefilter="prefilterResponsible"
          :title="'Назначенные заявки'" titleClass="h2" />

        <div class="users">
          <h2>Сотрудники</h2>
          <template v-if="users.length > 0">
            <sbs-user-item v-for="item in users" :key="item.id" :item="item" />
          </template>

          <div v-if="permissions.includes('add_employees')" class="btn btn1 btn-block" @click="addUser" v-ripple.300>+
            Добавить
            сотрудника</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsProps from "@/components/props.vue";
import sbsDepartmentItem from "@/components/departments/item.vue";
import sbsRequestsStatuses from "@/components/requests/statuses.vue";
import sbsUserItem from "@/components/users/item.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-department-view",
  components: {
    sbsLoaderInline,
    sbsProps,
    sbsDepartmentItem,
    sbsRequestsStatuses,
    sbsUserItem
  },
  data() {
    return {
      loading: true,
      department: null,
      childs: [],
      requests_created: [],
      requests_reponsible: [],
      equipment_types: [],
      users: [],
      permissions: []
    };
  },
  computed: {
    prefilterOwn() {
      if (this.department == null) {
        return {};
      }
      return {
        createdDepartment: [
          {
            id: this.department.id,
            name: this.department.name,
          },
        ],
      };
    },
    prefilterResponsible() {
      if (this.department == null) {
        return {};
      }
      return {
        responsibleDepartment: [
          {
            id: this.department.id,
            name: this.department.name,
          },
        ],
      };
    },
  },

  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  watch: {
    id: function () {
      this.loadData();
    },
    reload: function () {
      this.loadData();
    },
  },

  methods: {
    /**
     * Подготовка запросов
     */
    getBatchRequests() {
      return {
        department: {
          ENTITY: "department",
          METHOD: "get",
          PARAMS: {
            id: this.id,
          },
        },
        childs: {
          ENTITY: "department",
          METHOD: "list",
          PARAMS: {
            filter: {
              section_id: this.id,
              active: "Y",
            },
          },
        },
        requests_created: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: ["status_id", "cnt"],
            group: ["status_id"],
            order: { "status.sort": "asc" },
            filter: {
              created_department_id: this.id,
            },
          },
        },
        requests_reponsible: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: ["status_id", "cnt"],
            group: ["status_id"],
            order: { "status.sort": "asc" },
            filter: {
              responsible_department_id: this.id,
            },
          },
        },
        users: {
          ENTITY: "users",
          METHOD: "list",
          PARAMS: {
            filter: {
              department_id: this.id,
            },
          },
        },
        equipment_types: {
          ENTITY: "equipment",
          METHOD: "statistics",
          PARAMS: {
            select: { "type_name": "type.name", 0: "cnt", 1: "type_id" },
            group: ["type_id"],
            order: { "type_name": "asc" },
            filter: {
              department_id: this.id
            }

          },
        }
      };
    },

    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;
      this.department = null;

      //запрашиваем данные
      rest
        .batch(this.getBatchRequests())
        .then((arResults) => {
          //обработка результатов
          this.processResponse(arResults);
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Обработка результата
     */
    processResponse(results) {
      for (var key in results) {
        let data = results[key];

        if (data.success) {
          if (key == "department") {
            this.department = data.department;
            this.permissions = data.permissions;
          } else if (key == "childs") {
            this.childs = data.items;
          } else if (key == "requests_created") {
            this.requests_created = data.items;
          } else if (key == "requests_reponsible") {
            this.requests_reponsible = data.items;
          } else if (key == "users") {
            this.users = data.items;
          } else if (key == 'equipment_types') {
            this.equipment_types = data.items;
          }
        } else {
          //показываем сообщение об ошибке
          bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          return;
        }
      }
    },

    onReload() {
      this.loadData();
    },

    /**
     * Пригласить сотрудника
     */
    addUser() {
      setTimeout(() => {
        this.$router.push({ name: "user-add" });
      }, 250);
    },

    /**
     * Клик по типу оборудования
     */
    onEquipmentTypeClick(item) {
      //сохраняем Фильтр в текущем состоянии
      this.$store.commit("setFilter", {
        name: "EQUIPMENT",
        value: {
          type_id: [
            {
              id: item.type_id,
              name: item.type_name,
            },
          ],
          department_id: [
            {
              id: this.id,
              name: this.department.name,
            }
          ]
        }
      });

      this.$router.push({ name: "equipment" });
    }
  },

  created() {
    //загружаем данные
    this.loadData();
  },
};
</script>

<style lang="scss">
.sbs-department-view {
  .description {
    margin-bottom: 15px;
  }

  .equipment-types {
    .item {
      position: relative;
      padding: 10px;
      padding-right: 55px;
      border-radius: 8px;
      background-color: var(--color-block-background);
      margin-bottom: 20px;
      cursor: pointer;

      .name {
        font-size: 14px;
        line-height: 1.2;
      }

      .cnt {
        position: absolute;
        top: 50%;
        right: 35px;
        transform: translateY(-50%);
        color: var(--color-text-label);
        line-height: 1;
      }

      .icon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        color: var(--color-text-label);
        line-height: 1;
      }
    }
  }
}
</style>