<template>
    <sbs-view :class="'sbs-v-edu-posts-search'" :isMenuHidden="hideMenu" :refreshDisabled="true" ref="view">

        <template v-slot:header>
            <sbs-header />
        </template>

        <template v-slot:content>

            <div class="h1">{{ $t('common.search') }}</div>

            <div class="search-wrap">
                <sbs-control-input v-model="search" :placeholder="$t('common.search')" class="search">
                    <template v-slot:icon>
                        <div v-if="isNativePlatform && search == ''" class="icon" @click="speech">
                            <span class="fi fi-mic"></span>
                        </div>

                        <div v-if="search != ''" class="icon" @click="clearSearch()">
                            <span class="fi fi-close"></span>
                        </div>
                    </template>
                </sbs-control-input>

            </div>

            <!-- Загрузка -->
            <template v-if="loading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>

            <template v-else>

                <template v-if="items.length > 0">

                    <div class="items row">

                        <div class="col-12 col-sm-12 col-md-6 col-lg-4" v-for="item in items" :key="item.id">
                            <sbs-education-post-item :item="item" class="item" />
                        </div>

                    </div>

                </template>

                <template v-else-if="search && !timer">

                    <sbs-empty :title="$t('education.posts.search.empty.title')"
                        :text="$t('education.posts.search.empty.text')" />

                </template>

            </template>

        </template>

    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationPostItem from "@/components/education/post.item.vue"
import sbsEmpty from "@/components/empty.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "sbs-v-education-posts-search",
    components: {
        sbsView,
        sbsHeader,
        sbsControlInput,
        sbsLoaderInline,
        sbsEducationPostItem,
        sbsEmpty
    },
    data() {
        return {
            hideMenu: false,

            loading: false,
            search: "",
            items: [],
            timer: false,
            pager: {
                size: 20,
                page: 1,
                cnt: 1,
            },
        };
    },
    computed: {
        ...mapGetters({
            isNativePlatform: "isNativePlatform"
        }),
    },

    watch: {
        search: function () {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                this.timer = false;
                this.applySearch();
            }, 500);
        },
    },

    methods: {

        /**
         * Очищает поиск
         */
        clearSearch() {
            this.search = "";
            this.items = [];
        },

        /**
         * Поиск
         */
        applySearch() {
            if (this.search.trim() == "") {
                this.items = [];
                return;
            }

            if (this.loading) {
                return;
            }
            this.loading = true;

            //запрашиваем данные
            rest
                .call("education.post.list", {
                    method: "post",
                    data: {
                        select: ["user_photo", "user_name", "user_last_name"],
                        paging: this.pager,
                        resize_options: {
                            PREVIEW: { WIDTH: 400, HEIGHT: 300 },
                            PRELOAD: "Y"
                        },
                        order: {
                            "publication_date": "desc"
                        },
                        filter: {
                            search: this.search
                        },
                    },
                })
                .then((data) => {
                    if (data.success) {
                        if (this.pager.page == 1) {
                            this.items = data.items;
                        } else {
                            this.items = this.items.concat(data.items);
                        }
                        this.pager = data.pager;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    //скроем экран загрузки
                    this.loading = false;
                });
        },

        /**
         * Распознование речи для навания
         */
        speech() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.search = text;
                },
            });
        },
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
    },
};
</script>

<style lang="scss">
.sbs-v-edu-posts-search {

    @media (min-width: 768px) {
        .items {
            margin: 0px -10px;

            >div {
                padding: 0px 10px;
            }

            .item {
                min-height: calc(100% - 20px);
            }
        }
    }
}
</style>