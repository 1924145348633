<template>
  <div class="sbs-rce">
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>

    <template v-else>
      <template v-if="stage == 'start_by_nfc'">
        <div class="need-nfc-start">
          Для начала работы необходимо отсканировать NFC метку оборудования
          <br /><br />
          <div class="btn btn-block btn1" @click="scanNfc">Сканировать NFC</div>
        </div>
      </template>

      <template v-if="stage == 'steps'">
        <div class="h1">
          #{{ request.number }} чек лист {{ step }}/{{ items.length }}
        </div>

        <div class="items">
          <div class="item" v-for="(item, index) in items" :key="item.id" v-show="index == step - 1" :ref="setItemRef">
            <div class="name">{{ item.name }}</div>

            <template v-if="item.type == 'TEXT'">

              <sbs-control-textarea v-model="items[index].value" label="Комментарий" :required="true" :rows="5" />

            </template>

            <template v-else-if="item.type == 'PHOTO'">

              <sbs-control-photos v-model="item.value" label="Фото" :class="{ 'form-group-error': photoError }"
                :required="true"
                :disableGallery="item.options && typeof item.options === 'object' && item.options.disable_gallery == 'Y'" />

            </template>

            <template v-else-if="item.type == 'NUMBER'">

              <sbs-control-input v-model="items[index].value" type="text" label="Значение" :required="true" />

            </template>

            <template v-else-if="item.type == 'CHECKBOX'">

              <div>
                <sbs-control-radio v-model="items[index].value" value="Y" label="Да" :form="true" :required="true" />
              </div>
              <div>
                <sbs-control-radio v-model="items[index].value" value="N" label="Нет" :form="true" :required="true" />
              </div>

            </template>

          </div>
        </div>

        <sbs-teleport-wrapper to="#sbs-rcevb">
          <div class="sbs-rce-btns">
            <div class="container">
              <div class="page-padding">
                <div class="row">
                  <div class="col">
                    <div class="btn btn-block btn2" :class="savingCancel ? 'wait' : ''" v-ripple.300 @click="cancel">
                      Не пройдено
                    </div>
                  </div>
                  <div class="col">
                    <div class="btn btn-block btn1" :class="savingDone ? 'wait' : ''" v-ripple.300 @click="done">
                      Пройдено
                      <div v-if="savingDone" class="btn-loader"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </sbs-teleport-wrapper>
      </template>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlTextarea from "@/components/controls/textarea.vue";
import sbsControlPhotos from "@/components/controls/photos.vue";
import sbsControlRadio from "@/components/controls/radio.vue";
import sbsTeleportWrapper from "@/components/teleport-wrapper.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import validator from "@/plugins/validator";

export default {
  name: "sbs-request-checklist-exec",
  components: {
    sbsLoaderInline,
    sbsControlInput,
    sbsControlTextarea,
    sbsControlPhotos,
    sbsTeleportWrapper,
    sbsControlRadio
  },
  emits: ["update:step", "update:skipBack"],
  data() {
    return {
      loading: false,
      request: null,
      items: [],
      itemRefs: [],
      photoError: false,
      savingDone: false,
      savingCancel: false,
      displayNfc: false,
      confirm: false,
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
    step: {
      type: Number,
      default() {
        return 1;
      },
    },
  },

  computed: {
    index() {
      return this.step - 1;
    },
    current() {
      return this.items[this.index];
    },
    stage() {
      if (this.displayNfc) {
        return "start_by_nfc";
      }
      return "steps";
    },
  },

  watch: {
    id: function () {
      this.loadData();
    },
    reload: function () {
      this.loadData();
    },
  },

  methods: {
    /**
     * Загрузка данных
     */
    loadData() {
      if (this.loading) {
        return;
      }

      //покажем экран загрузки
      this.loading = true;
      this.request = null;
      this.items = [];
      this.photoError = false;

      //запрашиваем данные
      rest
        .call("request.get", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            this.onLoad(data);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Обработка загрузки данных о заявке
     */
    onLoad(data) {
      this.request = data.request;
      this.items = data.checklist;

      //если можно начать работу по заявке
      if (data.permissions.includes("start")) {
        //если для старта НЕ нужен NFC
        if (!data.need_start_by_nfc) {
          this.startImmediatly();
        } else {
          this.displayNfc = true;
        }
      }
    },

    /**
     * Сканировать NFC метку
     */
    scanNfc() {
      let vm = this;

      bus.emit("SBS_NFC_SCANNER_E_OPEN", {
        resolve: function (item) {
          if (item.id == vm.equipmentId) {
            vm.displayNfc = false;
            vm.startImmediatly();
          } else {
            bus.emit("SBS_MESSAGE_E_SHOW", {
              message:
                "Метка не закрепленна за оборудованием по данной заявке.",
            });
          }
        },
      });
    },

    /**
     * Начать выполнение
     */
    startImmediatly() {
      rest
        .call("request.start", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },

    /**
     * Запоминает ссылки на элементы
     */
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },

    /**
     * Выполнено
     */
    done() {
      if (!this.check()) {
        return;
      }

      if (this.savingDone || this.savingCancel) {
        return;
      }
      this.savingDone = true;

      this.items[this.index].status = "F";

      this.save();
    },

    /**
     * Не выполнено
     */
    cancel() {
      if (!this.check()) {
        return;
      }

      if (this.savingDone || this.savingCancel) {
        return;
      }
      this.savingCancel = true;

      this.items[this.index].status = "C";

      this.save();
    },

    /**
     * Проверки
     */
    check() {

      this.photoError = this.current.type == "PHOTO" && this.current.value.length == 0;

      //проверка введённых данных
      if (
        !validator.check({ container: this.itemRefs[this.index] }) ||
        this.photoError
      ) {
        return false;
      }

      return true;
    },

    /**
     * Сохраняет данные по текущему пункту
     */
    save() {
      //выполняем сохранение
      rest
        .call("request.checklist.item.update", {
          method: "post",
          data: this.items[this.index],
        })
        .then((data) => {
          if (data.success) {
            this.onSave();
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.savingDone = false;
          this.savingCancel = false;
        });
    },

    /**
     * Обработка успешного сохранения
     */
    onSave() {
      let vm = this;
      if (this.index < this.items.length - 1) {
        this.$emit("update:step", this.step + 1);
      } else {
        //генерим событие успешного обновления заявки
        bus.emit("SBS_REQUEST_E_UPDATED", this.id);

        //разрешает экрану переходить на шаг назад
        this.$emit("update:skipBack", false);

        //спрашиваем завершить ли заявку
        bus.emit("SBS_CONFIRM_E_SHOW", {
          message: "Чек-лист пройден. Закрыть заявку?",
          resolveText: "Да",
          rejectText: "Нет",
          resolve: function (value) {
            if (value) {
              vm.complete();
            }
            //переход обратно
            vm.$router.go(-1);
          },
          reverse: false,
        });
      }
    },

    /**
     * Завершить заявку
     */
    complete() {
      //выполняем запрос
      rest
        .call("request.complete", {
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },
  },

  created() {
    //загружаем данные
    this.loadData();
  },

  beforeUpdate() {
    this.itemRefs = [];
  },
};
</script>


<style lang="scss">
.sbs-rce {
  .need-nfc-start {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 300px;
  }

  .item {
    .name {
      margin-bottom: 40px;
      font-size: 16px;
    }
  }
}

.sbs-rce-btns {
  padding: 15px 0px;
  z-index: 1000;
  background-color: var(--color-background);

  .btn {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
