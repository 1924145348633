<template>
  <div class="sbs-user-accounts">
    <div class="list">
      <div class="item" v-for="(item, key) in connections" :key="key">
        <div class="control">
          <sbs-control-radio name="currentConnection" :value="'' + key" v-model="currentInternal" />
        </div>

        <div class="name">
          <template v-if="item.profile && item.profile.last_name">
            {{ item.profile.name }} {{ item.profile.last_name }}
          </template>
          <template v-else>
            {{ item.login }}
          </template>
        </div>
        <div class="description">{{ item.server }}</div>

        <div class="delete" @click="remove(key)">
          <span class="fi fi-close"></span>
        </div>
      </div>
    </div>

    <div class="btn btn1 btn-block" v-ripple.300 @click="add">Добавить</div>
  </div>
</template>

<script>
import sbsControlRadio from "@/components/controls/radio.vue";
import { mapGetters } from "vuex";
import bus from "@/plugins/bus";

export default {
  name: "sbs-user-accounts",
  components: {
    sbsControlRadio,
  },
  data() {
    return {
      currentInternal: "" + this.$store.getters.getCurrentConnectionKey,
    };
  },
  computed: {
    ...mapGetters({ connections: "getConnections" }),
    ...mapGetters({ current: "getCurrentConnectionKey" }),
  },

  watch: {
    currentInternal: function (value) {
      if (value != this.current) {
        bus.emit("SBS_APP_E_ACCOUNT_CHANGE", value);
      }
    },
    current: function (value) {
      if (value != this.currentInternal) {
        this.currentInternal = value;
      }
    },
  },

  methods: {
    /**
     * Добавить подключение
     */
    add() {
      this.$router.push({
        name: "accounts-add",
      });
    },

    remove(key) {
      if (key == this.current) {
        bus.emit("SBS_MESSAGE_E_SHOW", {
          message: "Нельзя удалить текущий аккаунт.",
        });
      } else {
        bus.emit("SBS_CONFIRM_E_SHOW", {
          message: "Вы действительно хотите удалить аккаунт?",
          resolveText: "Удалить",
          rejectText: "Отменить",
          resolve: function (value) {
            if (value) {
              this.$store.dispatch("removeConnection", key);
            }
          },
        });
      }
    },
  },

  /**
   * Создание эксземпляра компонента
   */
  created() {
    this.themeInternal = this.theme;
  },
};
</script>

<style lang="scss">
.sbs-user-accounts {
  .list {
    margin-bottom: 40px;
  }

  .item {
    position: relative;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--color-block-background);
    margin-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;

    .name {
      font-size: 16px;
    }

    .description {
      color: var(--color-text-label);
      font-size: 12px;
    }

    .control {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }

    .delete {
      position: absolute;
      top: 50%;
      right: 0px;
      padding: 15px;
      transform: translateY(-50%);
    }
  }
}
</style>