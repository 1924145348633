import { Preferences } from '@capacitor/preferences';

const capacitorPreferences = {
    name: "capacitorPreferences",

    set(key, value) {
        Preferences.set({
            key: key,
            value: value,
        });
    },
    async get(key) {
        let v = await Preferences.get({ key: key });
        if (v) {
            return v.value;
        } else {
            return null;
        }
    },
    async keys() {
        return Preferences.keys();
    },
    remove(key) {
        Preferences.remove({ key: key });
    },
}


const windowLocalStorage = {
    name: "windowLocalStorage",
    set(key, value) {
        window.localStorage.setItem(key, value);
    },
    async get(key) {
        let value = window.localStorage.getItem(key);
        return value;
    },
    async keys() {
        let keys = Object.keys(window.localStorage);
        return keys;
    },
    remove(key) {
        window.localStorage.removeItem(key);
    }
}


const storage = {

    transport: windowLocalStorage,
    prefix: "sbs_",
    clearExclude: [
        "last_server",
        "last_login",
        "theme"
    ],

    set(key, value) {

        //если объект, то обязательно конвертируем в строку
        if ((typeof value === 'object') && value !== null) {
            value = JSON.stringify(value);
        }

        this.transport.set(this.prefix + key, value);
    },

    async get(key) {

        let value = await this.transport.get(this.prefix + key);

        if (value !== null) {
            //пытаемся конвертировать из строки в объект
            try {
                value = JSON.parse(value);
            } catch (e) {
                //to do
            }
        }

        return value;
    },

    /**
     * Очищает все данные
     */
    clear() {
        let keys = this.transport.keys();

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key.startsWith(this.prefix)) {

                let k = key.replace(this.prefix, "");

                if (!this.clearExclude.includes(k)) {
                    window.localStorage.removeItem(key);
                }
            }
        }
    },

    /**
     * Перехорд на использование Capacitir
     */
    async migrateToCapacitor() {
        let migrated = await capacitorPreferences.get("migrated");
        if (migrated != "Y") {
            let keys = await windowLocalStorage.keys();
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let value = await windowLocalStorage.get(key);
                capacitorPreferences.set(key, value);
            }
            capacitorPreferences.set("migrated", "Y");
        }

        this.transport = capacitorPreferences;
    }

}

export default storage;