<template>
  <sbs-view :class="['sbs-v-login', 'mode-' + mode]" :isMenuHidden="hideMenu" :refreshDisabled="true" ref="view">
    <template v-slot:header>
      <sbs-header v-if="mode == 'add'" />
    </template>

    <template v-slot:content>
      <div class="h1" v-if="mode == 'add'">Новый аккаунт</div>
      <sbs-auth-login :mode="mode" />
    </template>
  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsAuthLogin from "@/components/auth/login.vue";
import sbsHeader from "@/components/header.vue";

export default {
  name: "sbs-v-login",
  components: {
    sbsView,
    sbsAuthLogin,
    sbsHeader,
  },
  data() {
    return {
      hideMenu: false
    };
  },
  props: {
    mode: {
      type: String,
      default() {
        return "login";
      },
    },
  },
  methods: {},
  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
    });
  },
};
</script>

<style lang="scss">
.sbs-v-login {
  &.mode-login {
    .view-content {
      padding-top: 110px !important;
    }
  }
}
</style>