import store from '@/store';

export default {
    onError(e) {

        var message = "";
        var stackTrace = "";
        var filename = "";

        if (e.message) {
            message = e.message;
        } else if (e.error) {
            message = e.error.toString();
        }

        if (e.error && e.error.stack) {
            stackTrace = e.error.stack;
        }

        if (e.filename) {
            filename += e.filename;
        }

        var userAgent = window.navigator && window.navigator.userAgent ? window.navigator.userAgent : "";

        if (window.XMLHttpRequest) {
            var xhr = new XMLHttpRequest();
            xhr.open('POST', store.getters.getApiUrl + '/system.error.log', true);

            var fd = new FormData();
            fd.append("message", message);
            fd.append("stackTrace", stackTrace);
            fd.append("filename", filename);

            fd.append("userAgent", userAgent);
            fd.append("url", window.location.href);
            if (e) {
                fd.append("error", JSON.stringify(e, ["message", "arguments", "type", "name", "reason"]));
            }

            xhr.send(fd);
        }
    }
}