<template>
    <Sheet v-model:visible="visibleInternal" sheetColor="var(--color-block-background)"
        sliderIconColor="var(--color-block-label)" maxWidth="680px" :style="{ paddingBottom: this.paddingBottom + 'px' }">
        <slot name="body"></slot>
    </Sheet>
</template>

<script>
import { Sheet } from "bottom-sheet-vue3";
import { mapGetters } from "vuex";

export default {
    name: "sbs-sheet",
    emits: ["update:visible"],
    components: {
        Sheet,
    },
    data() {
        return {
            visibleInternal: this.visible,
            watchVisible: false
        }
    },
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        ...mapGetters({
            safeArea: "getSafeArea",
            platform: "getPlatform"
        }),
        paddingBottom() {
            if (this.safeArea && this.safeArea.bottom > 0 && this.platform == "ios") {
                return this.safeArea.bottom;
            }

            return 0;
        }
    },
    watch: {
        visible: function (newVal) {
            this.visibleInternal = newVal;
            if (newVal) {
                this.onOpen();
            }
        },
        visibleInternal: function (newVal) {
            if (!newVal) {

                if (this.watchVisible) {
                    //эмитируем переход назад
                    history.back();
                }
            }

            this.$emit("update:visible", newVal);
        }
    },
    methods: {
        /**
         * Обработка открытия
         */
        onOpen() {

            this.watchVisible = true;

            //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
            window.history.pushState({}, "", window.location.href + "?sheetOpen");

            //добавляем закрытие в стэк обработчиков возврата назад
            this.$store.commit("addToBackStack", this.closeFromBaсkBtn);
        },

        /**
         * Закрыть при нажатие Назад в браузере
         */
        closeFromBaсkBtn() {
            this.watchVisible = false;
            this.visibleInternal = false;
            //удаляем закрытие из стэка обработчиков возврата назад
            this.$store.commit("removeFromBackStack", this.closeFromBaсkBtn);
        },
    },

    /**
     * Обработка создания экземпляра
     */
    created() {
        //если создаётся уже открытым
        if (this.visible) {
            this.onOpen();
        }
    }
}
</script>

<style lang="scss"></style>