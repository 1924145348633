<template>
  <sbs-view :class="'sbs-v-nfc-scanner'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header />
    </template>
    <template v-slot:content>
      <div class="picture">
        <img src="../assets/images/nfc.svg" />
      </div>

      <div class="message">
        Приложите устройство к NFC-метке оборудования.
      </div>

      <template v-if="loading">
        <div class="text-center">
          <sbs-loader-inline />
        </div>
      </template>
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";

export default {
  name: "sbs-v-nfc-scanner",
  components: {
    sbsView,
    sbsHeader,
    sbsLoaderInline,
  },
  data() {
    return {
      loading: false,
      resolve: null,
      nfcId: "",
      mode: "EQUIPMENT",
      hideMenu: false
    };
  },
  methods: {
    /**
     * Обработка готовности сканера
     */
    onReady(params) {
      this.resolve = params.resolve;
      this.nfcId = "";

      //режим
      if (params.mode) {
        this.mode = params.mode;
      } else {
        this.mode = "EQUIPMENT"
      }
    },

    /**
     * Обработка результата сканирования
     */
    onScan(id) {
      if (id != this.nfcId && !this.loading) {
        //выключим сканер
        bus.emit("SBS_NFC_SCANNER_E_CLOSE");

        this.nfcId = id;

        if (this.mode == "EQUIPMENT") {
          this.searchEquipment();
        } else if (this.mode == "SIMPLE") {
          this.resolve(id);
          this.$router.go(-1);
        }

      }
    },

    /**
     * Ищет оборудование по метке
     */
    searchEquipment() {
      this.loading = true;

      //запрашиваем данные
      rest
        .call("equipment.list", {
          method: "post",
          data: {
            filter: {
              PROPERTY_NFC_ID: this.nfcId,
            },
          },
        })
        .then((data) => {
          if (data.success) {
            if (data.items.length > 0) {
              let item = data.items[0];
              this.resolve(item);
            } else {
              bus.emit("SBS_MESSAGE_E_SHOW", {
                message: "По данной метке оборудование не найдено",
                timeout: 10000
              });
            }
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText, timeout: 10000 });
          }
          this.$router.go(-1);
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  mounted() {
    //подписваемся на событие готовности сканера
    bus.on("SBS_NFC_SCANNER_E_READY", this.onReady);

    //подписваемся на событие сканирования
    bus.on("SBS_NFC_SCANNER_E_SCAN", this.onScan);
  },
};
</script>

<style lang="scss">
.sbs-v-nfc-scanner {
  .picture {
    width: 100px;
    margin: 50px auto 30px;

    img {
      width: 100%;
    }
  }

  .message {
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
  }
}
</style>