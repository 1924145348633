<template>
    <div class="sbs-rt-l">

        <div class="item" v-for="item in items" :key="item.ID" @click="onItemClick(item)">
            <div clas="en">
                <div class="equipment" v-if="item.equipment_id > 0">
                    {{
                        item.equipment_short_name
                        ? item.equipment_short_name
                        : item.equipment_name
                    }}
                </div>
                <div class="name">
                    {{ item.name }}
                </div>
            </div>
            <div class="repeat" v-if="item.repeat == 'Y'">
                Повторяется
            </div>

            <div class="props">
                <div class="prop" v-if="item.repeat == 'Y'">
                    <div class="label">Следующее повторение:</div>
                    <div class="value">{{ item.repeat_next_date }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "sbs-v-request-templates-list",
    components: {

    },
    data() {
        return {

        };
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
    },


    methods: {

        /**
         * Переход к шаблону
         */
        onItemClick(item) {
            this.$router.push({
                name: "request-template-form",
                params: { id: item.id },
            });
        },

        /**
         * Форматирует дату
         */
        formatDate(date) {
            return moment(date, "DD.MM.YYYY hh:mm:ss").format("DD.MM.YYYY HH:mm");
        },

    },
}

</script>

<style lang="scss">
.sbs-rt-l {
    .item {
        position: relative;
        padding: 10px;
        border-radius: 8px;
        background-color: var(--color-block-background);
        margin-bottom: 20px;

        .en {
            padding-right: 120px;
        }

        .equipment {
            color: var(--color-block-text);
            font-size: 18px;
        }

        .name {
            color: var(--color-block-text);
            line-height: 1.14;
        }

        .repeat {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 6px 5px;
            min-width: 90px;
            text-align: center;
            color: #1e1f22;
            border-radius: 4px;
            line-height: 1;
            background-color: var(--color-active);
        }

        .prop {
            margin-top: 5px;

            .label {
                line-height: 1;
                color: var(--color-block-label);
                font-size: 13px;
            }

            .value {
                color: var(--color-block-value);
                font-size: 13px;
            }

        }


    }
}
</style>




