<template>
  <sbs-view :class="'sbs-v-requests'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:right>
          <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="onSearchClick">
            <span class="icon fi fi-search"></span>
          </div>
          <div class="action" :class="{ on: filtered }" v-ripple.300="'rgba(100, 100, 100, 0.15)'"
            @click="onFilterClick">
            <span class="icon fi fi-filter"></span>
          </div>
        </template>
      </sbs-header>
    </template>
    <template v-slot:content>
      <div class="h1">Заявки</div>

      <sbs-requests-list :reload="reload" />


    </template>
    <template v-slot:outer>
      <!-- Кнопка -->
      <sbs-requests-widget />
    </template>
  </sbs-view>
</template>

<script>
import { mapGetters } from "vuex";

import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsRequestsList from "@/components/requests/list.vue";
import sbsRequestsWidget from "@/components/requests/widget.vue";

export default {
  name: "sbs-v-requests",
  components: {
    sbsView,
    sbsHeader,
    sbsRequestsList,
    sbsRequestsWidget
  },
  data() {
    return {
      reload: 0,
      hideMenu: false,
    };
  },
  computed: {

    ...mapGetters({
      tab: "getRequestsTab",
      filterKey: "getRequestsTabFilterKey"
    }),

    globalFilter() {
      return this.$store.getters.getFilter("REQUEST-" + this.filterKey);
    },

    filtered() {
      if (!this.globalFilter || Object.keys(this.globalFilter).length == 0) {
        return false;
      }
      for (let key in this.globalFilter) {
        let value = this.globalFilter[key];
        if (value instanceof Array) {
          if (value.length > 0) {
            return true;
          }
        } else if (value) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    refresh(resolve) {
      this.reload++;
      setTimeout(() => {
        resolve();
      }, 300);
    },

    onFilterClick() {
      this.$router.push({ name: "requests-filter" });
    },

    onSearchClick() {
      this.$router.push({
        name: "requests-search",
      });
    },

    onFilterApply() { },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
    });
  },
};
</script>
