<template>
  <sbs-view :class="'sbs-v-users'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
    <template v-slot:header>
      <sbs-header />
    </template>
    <template v-slot:content>
      <div class="h1">Сотрудники</div>
      <sbs-users-list :departmentId="departmentId" :reload="reload" />
    </template>
  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsUsersList from "@/components/users/list.vue";

export default {
  name: "sbs-v-users",
  components: {
    sbsView,
    sbsHeader,
    sbsUsersList,
  },
  data() {
    return {
      reload: 0,
      hideMenu: false
    };
  },
  props: {
    departmentId: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  methods: {
    refresh(resolve) {
      this.reload++;
      setTimeout(() => {
        resolve();
      }, 300);
    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$refs.view.onBeforeRouteEnter();
    });
  },
};
</script>
