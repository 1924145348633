<template>
  <div class="sbs-request-view-chat" :style="{ top: top + 'px', bottom: bottom + 'px' }">
    <div class="container">
      <div class="page-padding">
        <div class="messages" ref="messages" :style="{ bottom: writerHeight + 20 + 'px' }">
          <div ref="pager"></div>

          <!-- Загрузка -->
          <template v-if="loading">
            <div class="text-center">
              <sbs-loader-inline />
            </div>
          </template>

          <template v-for="(item, i) in items" :key="item.id">
            <!-- Дата -->
            <div v-if="isDateChanged(i > 0 ? items[i - 1] : false, item)" class="date">
              {{ formatDate(item) }}
            </div>

            <div class="item" :class="{
    my: this.user.id == item.user_id,
    alien: this.user.id != item.user_id,
    audio: item.file && item.file.audio,
  }" :ref="setItemRef">
              <!-- Аватар -->
              <div v-if="(isAutorChanged(i > 0 ? items[i - 1] : false, item) ||
    isDateChanged(i > 0 ? items[i - 1] : false, item)) &&
    this.user.id != item.user_id
    " class="avatar" @click="onUserClick(item.user_id)">
                <div v-if="!item.user_photo" class="initials">
                  {{ item.user_last_name.charAt(0) + item.user_name.charAt(0) }}
                </div>
                <div v-else class="photo" :style="{
    backgroundImage: 'url(' + item.user_photo.preview + ')',
  }"></div>
              </div>

              <div class="wrap">
                <!-- Сообщение (текст или обычный файцл) -->
                <div v-if="!item.file ||
    (!item.file.image && !item.file.video && !item.file.audio)
    " class="message">
                  <div v-if="(isAutorChanged(i > 0 ? items[i - 1] : false, item) ||
    isDateChanged(i > 0 ? items[i - 1] : false, item)) &&
    this.user.id != item.user_id
    " class="fio">
                    {{ item.user_last_name }} {{ item.user_name }}
                  </div>
                  <div v-html="item.message"></div>
                  <template v-if="item.file">
                    <span class="a" @click="download(item)">{{
    item.file.name
  }}</span>
                    <span class="filesize">{{ formatSize(item) }}</span>
                  </template>
                </div>
                <!-- Или фото/видео -->
                <div v-else-if="item.file.image || item.file.video" class="picture-outer">

                  <!-- В процессе синхронизации-->
                  <template v-if="item.sync">

                    <div class="picture sync" :style="{ paddingTop: '75%' }">
                      <div class="loader">
                        <sbs-loader-inline />
                      </div>
                    </div>

                  </template>

                  <!-- Иначе уже добавлено-->
                  <template v-else>

                    <div class="picture" @click="openPhoto(item)" :style="{
    paddingTop:
      (item.file.height / item.file.width) * 100 + '%',
    backgroundImage: 'url(' + item.file.preview + ')',
  }">
                      <div v-if="item.file.video" class="video">
                        <span class="fi fi-play icon"></span>
                      </div>
                    </div>

                  </template>


                </div>
                <!-- Или аудио -->
                <div v-else-if="item.file.audio" class="audio">
                  <audio controls>
                    <source v-if="item.file.data" :src="item.file.data" :type="item.file.mimeType" />
                    <source v-if="item.file.src" :src="item.file.src" type="audio/mpeg" />
                  </audio>
                </div>

                <!-- Время -->
                <div v-if="!item.saving && item.status != 'error'" class="time">
                  {{ formatTime(item) }}
                </div>
                <!-- Статус -->
                <div v-if="item.saving && !item.status" class="status">
                  <span class="fi fi-time"></span>
                </div>
                <!-- Ошибка -->
                <div v-if="!item.saving && item.status == 'error'" class="status" @click="retry(item)">
                  <span class="fi fi-error"></span>
                </div>
              </div>
            </div>
          </template>
        </div>

        <!-- Написать/отправить-->
        <div class="writer" ref="writer">
          <div class="message-outer">
            <div class="message-wrap">
              <textarea class="form-control" v-model="message" @input="handleInputMessage" rows="1"
                placeholder="Написать сообщение..." maxlength="2000"></textarea>
              <div class="replacer form-control" v-html="message"></div>
            </div>
            <!-- отправить-->
            <div v-if="message" class="send" @click="send">
              <span class="icon fi fi-send"></span>
            </div>
            <!-- запись аудио сообщения-->
            <div v-else class="voice-capture" :class="{ recording: voiceCapture }">
              <div class="timer">
                {{ formatVoiceDuration() }}
              </div>
              <div class="icon-wrap" @click.prevent="onVoiceCaptureClick()" @touchstart.prevent="onVoiceCaptureClick()"
                @touchend.prevent="onVoiceCaptureClick()">
                <span class="icon fi fi-mic"></span>
              </div>
            </div>
          </div>

          <div class="actions">
            <!-- добавить файл-->
            <div class="action add-file">
              <input type="file" ref="file" @change="onFileChange" />
              <span class="icon fi fi-attach"></span>
            </div>
            <!-- Открыть камеру-->
            <div class="action" @click="getPicture">
              <span class="icon fi fi-camera"></span>
            </div>
            <!-- Выбрать из медиабиблиотеки-->
            <div class="action" @click="getPictureFromLibrary">
              <span class="icon fi fi-photo-library"></span>
            </div>
            <!-- Снять видео -->
            <div class="action" v-if="isNativePlatform" @click="getVideo">
              <span class="icon fi fi-video"></span>
            </div>

            <!-- Распознование-->
            <div class="action" v-if="isNativePlatform" @click="speech">
              <span class="icon fi fi-mic"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";
import { mapGetters } from "vuex";
import { filesize } from "filesize";
import resizebase64 from "@/plugins/resizebase64";
import utils from "@/plugins/utils";

export default {
  name: "sbs-request-view-chat",
  components: {
    sbsLoaderInline,
  },
  data() {
    return {
      message: "",
      file: false,
      items: [],
      itemRefs: [],
      loading: true,
      scroll: true,
      savedScrollTop: -1,
      writerHeight: 45,
      writerResizeObsover: false,
      pager: {
        size: 10,
        page: 1,
        cnt: 1,
      },
      voiceCapture: false,
      voiceTimer: false,
      voiceDuration: 0,

      isActive: true
    };
  },

  props: {
    id: {
      type: [Number, String],
      default() {
        return 0;
      },
    },
  },

  computed: {
    ...mapGetters({
      user: "getUserProfile",
      isNativePlatform: "isNativePlatform",
      safeArea: "getSafeArea",
      platform: "getPlatform",
      service: "getService"
    }),

    needShowRequired() {
      return this.required && this.showRequired;
    },
    top() {
      if (this.safeArea && this.safeArea.top > 0) {
        return 178 + this.safeArea.top;
      }
      return 178;
    },
    bottom() {
      if (this.safeArea && this.safeArea.bottom > 0 && this.platform == "ios") {
        return 5 + this.safeArea.bottom;
      }
      return 5;
    },
    gallery() {
      let images = this.items
        .filter((item) => item.file && (item.file.image || item.file.video))
        .map((item) => {
          let src = item.file.full ? item.file.full : item.file.src;
          let type = "image";
          if (item.file.video) {
            src =
              '<video controls height="100%" width="100%" poster="' +
              item.file.preview +
              '"/>' +
              '<source src="' + item.file.src + '" type="video/mp4"/>'
            '</video>';
            type = "html";
          }

          return {
            src: src,
            type: type,
            width: item.file.width,
            height: item.file.height,
            thumb: item.file.preview ? item.file.preview : item.file.src,
            caption: "",
            id: item.file.id,
          };
        });
      return images;
    },
  },

  methods: {
    /**
     * Запоминает ссылки на элементы сообщений
     */
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },

    loadItems(scroll = true) {
      //покажем экран загрузки
      this.loading = true;

      this.scroll = scroll;

      //запрашиваем данные
      rest
        .call("request.message.list", {
          method: "post",
          data: {
            paging: this.pager,
            request_id: this.id,
          },
        })
        .then((data) => {
          if (data.success) {
            if (this.pager.page == 1) {
              this.items = data.items.reverse();
            } else {
              this.items = data.items.reverse().concat(this.items);
            }
            this.pager = data.pager;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Загружает одно сообщение
     */
    loadItem(id) {
      //запрашиваем данные
      rest
        .call("request.message.list", {
          method: "post",
          data: {
            request_id: this.id,
            filter: {
              id: id,
            },
          },
        })
        .then((data) => {
          if (data.success) {
            this.scroll = true;
            this.items = this.items.concat(data.items);
          }
        });
    },

    /**
     * Синхронизирует сообщение
     */
    syncItem(id, targetId) {
      //запрашиваем данные
      rest
        .call("request.message.list", {
          method: "post",
          data: {
            request_id: this.id,
            filter: {
              id: id,
            },
          },
        })
        .then((data) => {
          if (data.success && data.items.length > 0) {
            let item = data.items[0];
            let index = this.items.findIndex(i => i.id == targetId);
            this.items[index] = item;
          }
        });
    },

    /**
     * Отправка сообщения
     */
    send() {
      //если ничего не ввели
      if (this.message.trim() == "") {
        return;
      }

      //доабвляем сообщение в чат
      let item = {
        id: moment().format("x"),
        message: this.message,
        user_id: this.user.id,
        user_name: this.user.name,
        user_last_name: this.user.last_name,
        date: moment().format("DD.MM.YYYY HH:mm:ss"),
        saving: true,
      };
      this.scroll = true;
      this.items.push(item);

      //сброс набранного текста
      this.message = "";

      this.save(item);
    },

    /**
     * Обработка изменения файла
     */
    async onFileChange(event) {
      if (event.target.files.length == 0) {
        return;
      }
      let file = event.target.files[0];
      let isImage = file.type.includes("image");
      let isVieo = file.type.includes("video");

      //доабвляем сообщение в чат
      let item = {
        id: moment().format("x"),
        message: "",
        user_id: this.user.id,
        user_name: this.user.name,
        user_last_name: this.user.last_name,
        date: moment().format("DD.MM.YYYY HH:mm:ss"),
        file: {
          image: isImage,
          video: isVieo,
          name: file.name,
          src: URL.createObjectURL(file),
          preview: URL.createObjectURL(file),
          id: moment().format("x"),
          size: file.size,
        },
        fileOrigin: file,
        saving: true,
        sync: isImage || isVieo
      };
      if (isImage) {
        let sizes = await this.getImageSizes(file);
        item.file.width = sizes.width;
        item.file.height = sizes.height;
      }

      this.scroll = true;
      this.items.push(item);

      this.save(item);
    },

    /**
     * Получает по указанному файлу-картинке размеры
     */
    getImageSizes(file) {
      let img = new Image();

      let promise = new Promise((resolve, reject) => {
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = reject;
      });
      img.src = URL.createObjectURL(file);

      return promise;
    },

    /**
     * Получает по указанному файлу-картинке размеры
     */
    getImageBase64Sizes(data) {
      let img = new Image();

      let promise = new Promise((resolve, reject) => {
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = reject;
      });
      img.src = data;

      return promise;
    },

    /**
     * Попытка отправить повторно
     */
    retry(item) {
      this.save(item);
    },

    /**
     * Сохранение сообщения
     */
    save(item) {
      //поля для отправки
      let fields = {
        request_id: this.id,
        message: item.message,
        file: item.fileOrigin,
        photo: item.photo ? item.photo : "",
        audio: item.audio ? item.audio : "",
      };

      let formData = new FormData();
      for (let code in fields) {
        formData.append(code, fields[code]);
      }

      //сохранение сообщения
      rest
        .call("request.message.add", {
          method: "POST",
          data: formData,
          timeout: 0,
        })
        .then((data) => {
          if (data.success) {
            this.setItemSaveStatus(item.id, "success");
            //если требуется синхронизировать добавляемое сообщение с сохранённым на сервере сообщением
            if (item.sync) {
              this.syncItem(data.id, item.id)
            }

          } else {
            this.setItemSaveStatus(item.id, "error");
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },

    /**
     * форматирует время
     */
    formatTime(item) {
      return moment(item.date, "DD.MM.YYYY HH:mm:ss").format("HH:mm");
    },

    /**
     * форматирует дату
     */
    formatDate(item) {
      return moment(item.date, "DD.MM.YYYY HH:mm:ss").format("DD MMMM YYYY");
    },

    /**
     * Форматирует размер файла
     */
    formatSize(item) {
      if (!item.file.size) {
        return "";
      }
      return filesize(item.file.size);
    },

    /**
     * Устанавилвает сообщению статус сохранения
     */
    setItemSaveStatus(id, status) {
      let item = this.items.find((i) => i.id == id);
      if (item) {
        item.saving = false;
        item.status = status;
      }
    },

    /**
     * Проверяет изменилась ли дата между двумя сообщениями
     */
    isDateChanged(itemPrev, item) {
      if (itemPrev == false) {
        return true;
      }

      let d1 = moment(itemPrev.date, "DD.MM.YYYY HH:mm:ss").format(
        "DD.MM.YYYY"
      );
      let d2 = moment(item.date, "DD.MM.YYYY HH:mm:ss").format("DD.MM.YYYY");
      return d1 != d2;
    },

    /**
     * Проверяет изменился ли автор
     */
    isAutorChanged(itemPrev, item) {
      if (itemPrev == false) {
        return true;
      }

      return itemPrev.user_id != item.user_id;
    },

    /**
     * Событие прокрутки списка сообщений
     *
     */
    onScroll(e) {
      let scroll = e.target.scrollTop;
      //если экран прокрутился до пейджера
      if (scroll <= 50) {
        //если экран не в стадии загрузки и есть следующие страницы
        if (!this.loading && this.pager.page < this.pager.cnt) {
          this.pager.page++;
          this.loadItems(false);
        }
      }
      //если не треубется прокрутки в конец
      if (!this.scroll) {
        //проверка непрочитанных сообщений
        this.checkReadMessages();
      }
    },

    /**
     * Установка высоты контрола ввода сообщения
     */
    setWriterHeight() {
      this.writerHeight = this.$refs.writer.offsetHeight;
      if (this.writerHeight == 0) {
        this.writerHeight = 45;
      }
    },

    /**
     * Обработка изменения значения в поле ввода сообщения
     * а то v-model на мобильнике раотает не корректно см.
     * https://github.com/vuejs/vue/issues/9777
     */
    handleInputMessage(event) {
      this.message = event.target.value
    },

    /**
     * Сохранить файл
     */
    download(item) {
      bus.emit("SBS_FILE_E_DOWNLOAD", {
        url: item.file.src,
        name: item.file.name,
      });
    },

    /**
     * Открыть фото на просмотр
     */
    openPhoto(item) {
      let start = this.gallery.findIndex((i) => i.id == item.file.id);

      Fancybox.show(this.gallery, {
        startIndex: start,
        preload: 0, //иначе видео mp4 слева/справа начинает сразу воспроизводиться
        infinite: false,
        Toolbar: {
          display: [
            { id: "prev", position: "center" },
            { id: "counter", position: "center" },
            { id: "next", position: "center" },
            "zoom",
            "slideshow",
            "thumbs",
            "close",
          ],
        },
        Hash: false,
        on: {
          ready: (fancybox) => {
            utils.fancyBoxOnReady(fancybox);
          },
          closing: () => {
            utils.fancyBoxOnClose();
          },
        },
      });
    },

    /**
     * Получить фото с камеры
     */
    getPicture() {
      bus.emit("SBS_CAMERA_E_GET_PICTURE", {
        resolve: this.onGetPicture,
      });
    },

    /**
     * Получить фото с медиабиблиотеки
     */
    getPictureFromLibrary() {
      bus.emit("SBS_CAMERA_E_GET_PICTURE", {
        resolve: this.onGetPicture,
        photoLibrary: true,
      });
    },

    /**
     * Обработка получения фото с камеры
     */
    async onGetPicture(imageData) {
      var newImg = await resizebase64(
        "data:image/jpeg;base64," + imageData,
        1200,
        1200
      );

      let sizes = await this.getImageBase64Sizes(newImg);

      //доабвляем сообщение в чат
      let item = {
        id: moment().format("x"),
        message: "",
        user_id: this.user.id,
        user_name: this.user.name,
        user_last_name: this.user.last_name,
        date: moment().format("DD.MM.YYYY HH:mm:ss"),
        file: {
          image: true,
          name: "",
          src: newImg,
          preview: newImg,
          width: sizes.width,
          height: sizes.height,
          id: moment().format("x"),
        },
        photo: newImg.replace("data:image/jpeg;base64,", ""),
        saving: true,
        sync: true
      };

      this.scroll = true;
      this.items.push(item);

      this.save(item);
    },

    speech() {
      bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
        resolve: (text) => {
          this.message = text;
        },
      });
    },

    /**
     * Проверяет непрочитанные сообщения на прочитку
     */
    checkReadMessages() {
      let messages = this.items.filter((i) => i.unread);

      let boxScroll = this.$refs.messages.scrollTop;
      let boxHeight = this.$refs.messages.clientHeight;

      let readMessages = [];
      messages.forEach((message) => {
        let index = this.items.findIndex((i) => i.id == message.id);
        let node = this.itemRefs[index];
        let top = node.offsetTop;
        let bottom = node.offsetTop + node.clientHeight;

        let read = false;

        //если верхняя граница сообщения в боксе просмотра (берём отступ 20px чтобы наверняка)
        if (top > boxScroll + 20 && top < boxScroll + boxHeight - 20) {
          read = true;
        }

        //если нижняя граница сообщения в боксе просмотра (берём отступ 20px чтобы наверняка)
        if (bottom > boxScroll + 20 && bottom < boxScroll + boxHeight - 20) {
          read = true;
        }

        if (read) {
          readMessages.push(message);
        }
      });

      if (readMessages.length > 0) {
        this.readMessages(readMessages);
      }
    },

    /**
     * Фиксирует прочтение сообщений
     */
    readMessages(messages) {
      //пометим сообщения как прочитанные
      messages.forEach((message) => {
        message.unread = false;
      });

      //добавляем сообщение в стэк прочитанных сообщений, чтобы Push по такому сообщению уже не показывать
      this.$store.commit("addReadMessages", messages.map((m) => m.id));

      //фкисация прочтения
      rest.call("request.message.read", {
        method: "POST",
        data: {
          messages: messages.map((m) => m.id),
        },
      }).then(() => {
        //уменьшаем кол-во непрочитанных у заявки
        this.$store.commit("decreaseRequestUnread", { id: this.id, count: messages.length });
      });
    },

    /**
     * Переход к пользователю
     */
    onUserClick(id) {
      this.$router.push({
        name: "user-view",
        params: { id: id, service: this.service.toLowerCase() },
      });
    },

    /**
     * Клик по записи голоса
     */
    onVoiceCaptureClick() {
      this.voiceCapture = !this.voiceCapture;

      if (this.voiceCapture) {
        this.voiceCaptureStart();
      } else {
        this.voiceCaptureEnd();
      }
    },

    /**
     * Начинает запись аудио
     */
    voiceCaptureStart() {
      //запрашиваем старт записи сообщения
      bus.emit("SBS_VOICE_CAPTURE_E_START", {
        onStart: () => {
          this.voiceTimer = setInterval(() => {
            this.voiceDuration += 1;
          }, 1000);
        },
        onError: () => {
          this.voiceCapture = false;
        },
      });
    },

    /**
     * Завершение записи аудио
     */
    voiceCaptureEnd() {
      //запрашиваем завершение записи сообщения
      bus.emit("SBS_VOICE_CAPTURE_E_END", {
        onEnd: (value) => {
          this.voiceCaptureAddMessage(value);
        },
        onError: () => { },
      });

      if (this.voiceTimer) {
        clearInterval(this.voiceTimer);
        this.voiceTimer = false;
      }
      this.voiceDuration = 0;
    },

    /**
     * Добавления аудио сообщения в чат
     */
    voiceCaptureAddMessage(value) {
      let item = {
        id: moment().format("x"),
        message: "",
        user_id: this.user.id,
        user_name: this.user.name,
        user_last_name: this.user.last_name,
        date: moment().format("DD.MM.YYYY HH:mm:ss"),
        file: {
          image: false,
          audio: true,
          name: "",
          data: `data:${value.mimeType};base64,${value.recordDataBase64}`,
          mimeType: value.mimeType,
          id: moment().format("x"),
        },
        audio: `data:${value.mimeType};base64,${value.recordDataBase64}`,

        saving: true,
      };

      this.scroll = true;
      this.items.push(item);

      this.save(item);
    },

    /**
     * Форматирует длительность записи
     */
    formatVoiceDuration() {
      if (this.voiceDuration > 3600) {
        return moment.utc(this.voiceDuration * 1000).format("HH:mm:ss");
      }

      return moment.utc(this.voiceDuration * 1000).format("mm:ss");
    },

    /**
     * Получает видео с камеры
     */
    getVideo() {
      bus.emit("SBS_VIDEO_CAPTURE_E_OPEN", {
        options: {
          limit: 1,
          duration: 10,
        },
        resolve: this.onGetVideo,
      });
    },

    /**
     * Обработка получения видео
     */
    onGetVideo(data) {
      //доабвляем сообщение в чат
      let item = {
        id: moment().format("x"),
        message: "",
        user_id: this.user.id,
        user_name: this.user.name,
        user_last_name: this.user.last_name,
        date: moment().format("DD.MM.YYYY HH:mm:ss"),
        file: {
          video: true,
          name: "Видео",
          id: moment().format("x"),
          type: data.type,
        },
        fileOrigin: data.blob,
        saving: true,
        sync: true
      };

      this.scroll = true;
      this.items.push(item);

      this.save(item);
    },

    /**
     * Обработка порлучения сообщения через вебсокет
     */
    onMessageReceive(data) {
      //если событие - новое сообщение
      if (data.event == "new_request_message") {
        //если ид заявки просматриваемой совпадает
        if (data.requestId == this.id) {
          //пробуем найти сообщение
          let item = this.items.find((i) => {
            i.id == data.messageId;
          });
          if (!item) {
            this.loadItem(data.messageId);
            //если текущий пользователь не является автором сообщения
            if (this.user.id != data.userId) {

              //если чат активен
              if (this.isActive) {
                //добавляем сообщение в стэк прочитанных сообщений, чтобы Push по такому сообщению уже не показывать
                this.$store.commit("addReadMessage", data.messageId);
              }
            }
          }
        }
      }
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    onBeforeRouteLeave() {
      //запоминаем прокрутку
      this.savedScrollTop = this.$refs.messages.scrollTop;
    },

    /**
     * Вызывается перед переходом в этот экран
     */
    onBeforeRouteEnter() {
      if (this.$refs.messages && this.savedScrollTop >= 0) {
        //восстанавливаем прокрутку
        this.$refs.messages.scrollTop = this.savedScrollTop;
      }
    },

  },

  /**
   * Событие создания экземпляра
   */
  created() {
    this.writerResizeObsover = new ResizeObserver(this.setWriterHeight);
  },

  /**
   * Событие привязки экземпляра к DOM
   */
  mounted() {
    //загружаем данные
    this.loadItems();

    //ыключаем слежение за изменением высоты контрола ввода
    this.writerResizeObsover.observe(this.$refs.writer);

    //подписываемся на скролинг экрана
    this.$refs.messages.addEventListener("scroll", this.onScroll);

    //обработка получения сообщения
    bus.on("SBS_WS_MESSAGE_E_RECEIVE", this.onMessageReceive);
  },

  /**
   * Событие отсоединения экземпляра от DOM
   */
  beforeUnmount() {
    //отписываемся от событий
    this.$refs.messages.removeEventListener("scroll", this.onScroll);

    //откючаем слежение за изменением высоты контрола ввода
    this.writerResizeObsover.unobserve(this.$refs.writer);
  },

  unmounted() {
    bus.off("SBS_WS_MESSAGE_E_RECEIVE", this.onMessageReceive);
  },

  /**
   * Событие перед обновлением DOM
   */
  beforeUpdate() {
    this.itemRefs = [];
  },

  /**
   * Событие после обновления DOM
   */
  updated() {
    //если требуется скрол вниз
    if (this.scroll) {
      //если контейнер сообщений вообще имеет высоту
      if (this.$refs.messages && this.$refs.messages.scrollHeight > 0) {
        if (this.itemRefs.length > 0) {
          this.itemRefs[this.itemRefs.length - 1].scrollIntoView(false);
          this.scroll = false;

          //проверка непрочитанных сообщений
          this.checkReadMessages();
        }
      }
    }
  },

  activated() {
    this.isActive = true;
  },

  deactivated() {
    this.isActive = false;
  }
};
</script>

<style lang="scss">
.sbs-request-view-chat {
  position: fixed;
  top: 173px;
  bottom: 5px;
  left: 0px;
  right: 0px;

  >.container {
    height: 100%;

    >.page-padding {
      height: 100%;
      position: relative;
    }
  }

  .messages {
    position: absolute;
    top: 0px;
    bottom: 65px;
    left: 5px;
    right: 5px;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--color-block-background);
    overflow-y: auto;

    .date {
      text-align: center;
      margin-bottom: 30px;
    }

    .item {
      padding-bottom: 20px;
      position: relative;

      .avatar {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--color-chat-fio);

        .initials {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: var(--color-chat-background);
        }

        .photo {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-size: cover;
        }
      }

      .wrap {
        position: relative;
        display: inline-block;
        max-width: 70%;
      }

      &.audio {
        .wrap {
          min-width: 300px;
        }
      }

      .fio {
        color: var(--color-chat-fio);
        font-size: 10px;
      }

      .message {
        border-radius: 8px;
        padding: 10px;
        color: var(--color-chat-text);

        padding-bottom: 20px;
        min-width: 50px;
      }

      .filesize {
        display: block;
        font-size: 12px;
        margin-top: 3px;
        color: var(--color-chat-time);
      }

      .picture-outer {
        width: 150px;
      }

      .picture {
        position: relative;
        background-size: cover;
        height: 0px;
        border-radius: 8px;

        .video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #efefef;

          .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #444;
          }
        }

        &.sync {
          background-color: #000;

          .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

      }

      .time,
      .status {
        position: absolute;
        bottom: 5px;
        font-size: 10px;
        color: var(--color-chat-time);
      }

      &.my {
        text-align: right;

        .wrap {
          padding-right: 0px;
        }

        .message {
          background-color: var(--color-color1);
          border-top-right-radius: 0px;
        }

        .picture {
          text-align: right;
          border-top-right-radius: 0px;
        }

        .time,
        .status {
          right: 10px;
        }
      }

      &.alien {
        padding-left: 40px;

        .wrap {
          padding-right: 0px;
        }

        .message {
          background-color: var(--color-chat-background);
          border-top-left-radius: 0px;
        }

        .picture {
          border-top-left-radius: 0px;
        }

        .time,
        .status {
          right: 10px;
        }
      }
    }
  }

  .writer {
    position: absolute;
    bottom: 0px;
    left: 5px;
    right: 5px;

    .message-outer {
      position: relative;
    }

    .message-wrap {
      /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
      display: grid;

      .replacer {
        /* This is how textarea text behaves */
        white-space: pre-wrap;
        /* Hidden from view, clicks, and screen readers */
        visibility: hidden;
      }

      textarea {
        /* You could leave this, but after a user resizes, then it ruins the auto sizing */
        resize: none;
        /* Firefox shows scrollbar on growth, you can hide like this. */
        overflow: hidden;
      }

      textarea,
      .replacer {
        /* Place on top of each other */
        grid-area: 1 / 1 / 2 / 2;
        max-height: 150px;
        padding-right: 44px;
      }
    }

    .actions {
      &:after {
        clear: both;
      }

      .action {
        position: relative;
        float: left;
        width: 44px;
        height: 44px;
        overflow: hidden;

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 18px;
          color: var(--color-color1);
        }
      }

      .add-file {
        input[type="file"] {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;
          opacity: 0;
          filter: alpha(opacity=0);
          transform: translate(-300px, 0) scale(4);
          font-size: 23px;
          direction: ltr;
          cursor: pointer;
          z-index: 2;
        }
      }
    }

    .send {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 44px;
      height: 44px;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: var(--color-color1);
      }
    }

    .voice-capture {
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 100%;
      background-color: var(--color-control-background);
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;

      transition: left ease-in-out 0.3s;

      .icon-wrap {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: var(--color-btn1-background);

        transition: width ease-in-out 0.15s, height ease-in-out 0.15s,
          right ease-in-out 0.15s;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        color: var(--color-btn1-text);

        transition: font-size ease-in-out 0.15s;
      }

      .timer {
        position: absolute;
        top: 12px;
        left: 20px;
        color: var(--color-control-text);
        font-size: 16px;
        font-weight: 400;
        opacity: 0;
        visibility: hidden;

        transition: opacity 0.3s ease-in-out;
      }

      &.recording {
        left: 0px;

        .icon-wrap {
          width: 56px;
          height: 56px;
          right: -5px;

          .icon {
            font-size: 26px;
          }
        }

        .timer {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
</style>