import store from '@/store';
import bus from '@/plugins/bus'

const websocket = {
    ws: null,
    timer: false,
    pingTimer: 0,

    /**
     * Инициализация
     */
    register() {

        //таймер на автоматическое восстановление подключения
        if (this.timer === false) {
            this.timer = setInterval(() => {
                this.reconnect();
            }, 15000);
        }

        if (this.ws) {
            if(this.ws.readyState == WebSocket.CONNECTING || this.ws.readyState == WebSocket.OPEN) {
                return;
            }
        }

        this.ws = new WebSocket("wss://" + store.getters.getServer + ":2346/");

        this.ws.onclose = () => {
            //останавливаем пинг
            this.cancelKeepAlive();
            //попытаемся сразу же переподключиться
            this.reconnect();
        };

        //отсылаем комманду на регистрацию соединения (с проверкой токена)
        this.ws.onopen = () => {
            this.send({
                "command": "register",
                "token": store.getters.getAccessToken,
                "application": "app",
                "platform": store.getters.getPlatform,
                "webVersion": process.env.VUE_APP_VERSION,
                "nativeVersion": store.getters.getAppVersion
            });
        };

        let _this = this;
        this.ws.onmessage = function (evt) {
            let arData = JSON.parse(evt.data);
            //успешная регистрация
            if (arData.event == "register") {
                //запоминаем ID соединения
                store.commit("setWebSocketId", arData.id);
                //запускаем пинг
                _this.keepAlive();
                
            } 
            //ошибка регистрации
            else if (arData.event === "register_error") {
                //связана с тем, что при входе токен может быть уже устарвшим 
                //и нужно некоторое время чтобы токен обновился
                setTimeout(() => {
                    _this.close();
                },2000);
            } else {

                //отправим событие всем подписанным компонентам
                bus.emit('SBS_WS_MESSAGE_E_RECEIVE', arData);
            }
        };
    },

    /**
     * Завершение
     */
    close() {
        if (this.ws) {
            this.ws.close();
        }
    },

    /**
     * Проверяет поключение и переподключается если нужно
     */
    reconnect() {

        //0 - подключается, 1 - подключено
        if (this.ws.readyState == WebSocket.CONNECTING || this.ws.readyState == WebSocket.OPEN) {
            return;
        }

        if (store.getters.isAuthorized) {
            this.register();
        }

    },

    /**
     * Отправка сообщения
     */
    send(data) {

        this.checkConnection().then(() => {
            this.ws.send(JSON.stringify(data));
        })
    
    },

    /**
     * Проверяет соединение
     */
    checkConnection() {
        return new Promise((resolve) => {
            if(this.ws.readyState == WebSocket.OPEN) {
                resolve();
            } else {
                let timer = setInterval(() => {
                    if(this.ws.readyState == WebSocket.OPEN) {
                        clearInterval(timer);
                        resolve();
                    }
                }, 1000);
            }
            
        });
    },

    /**
     * Запускает пинг 1 раз в 2 минуты
     */
    keepAlive() {

        if(this.pingTimer > 0) {
            clearInterval(this.pingTimer);
        }

        //запускаем пинг 1 раз в 2 минуты
        this.pingTimer = setInterval(() => {
            this.send({
                "command": "ping",
            });
        },1000 * 60* 2);  

    },

    /**
     * Останавливает пинг
     */
    cancelKeepAlive() {

        if(this.pingTimer > 0) {
            clearInterval(this.pingTimer);
        }

    }


};

export default websocket;