<template>
  <div class="sbs-user-profile">
    <!-- Загрузка -->
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>
    <template v-else>
      <!-- Не найдена-->
      <div v-if="user == null">Не удалось загрузить профиль</div>
      <div v-else>
        <!-- Действия в шапке-->
        <sbs-teleport-wrapper v-if="changed" to="#profile-view-header-right">
          <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="save">
            <span class="icon fi fi-save"></span>
          </div>
        </sbs-teleport-wrapper>

        <div class="photo-block">
          <div class="photo">
            <template v-if="this.user.photo && (this.user.photo.src || this.user.photo.data)
      ">
              <div v-if="this.user.photo.data" class="picture" :style="{
      backgroundImage:
        'url(data:image/jpeg;base64,' + this.user.photo.data + ')',
    }"></div>
              <div v-else class="picture" :style="{
      backgroundImage: 'url(' + this.user.photo.preview + ')',
    }"></div>
            </template>
            <template v-else>
              <span class="icon fi fi-user-circle"></span>
            </template>
          </div>
          <div class="change-wrap">
            <div class="change" @click="phoneActions">изменить фото</div>
          </div>

          <sbs-popup v-model:show="photoActionsOpen">
            <div class="actions">
              <div class="action" @click="getPictureFromCamera">
                <span class="icon fi fi-camera"></span>
                <div class="text">Снять на камеру</div>
              </div>
              <div class="action" @click="getPictureFromMedia">
                <span class="icon fi fi-photo-library"></span>
                <div class="text">Выбрать из медиатеки</div>
              </div>
              <div class="action" @click="deletePhoto">
                <span class="icon fi fi-close"></span>
                <div class="text">Удалить фото</div>
              </div>
            </div>
          </sbs-popup>
        </div>

        <h2>Личные данные</h2>
        <div class="block">
          <div class="prop" v-if="user.last_name">
            <div class="label">Фамилия:</div>
            <div class="value">
              {{ user.last_name }}
            </div>
          </div>
          <div class="prop" v-if="user.name">
            <div class="label">Имя:</div>
            <div class="value">
              {{ user.name }}
            </div>
          </div>
          <div class="prop" v-if="user.second_name">
            <div class="label">Отчество:</div>
            <div class="value">
              {{ user.second_name }}
            </div>
          </div>
          <div class="prop" v-if="user.email">
            <div class="label">E-Mail:</div>
            <div class="value">
              {{ user.email }}
            </div>
          </div>
        </div>

        <h2>Рабочие данные</h2>
        <div class="block">
          <div class="prop" v-if="user.department_name">
            <div class="label">Подразделение:</div>
            <div class="value">
              {{ user.department_name }}
            </div>
          </div>
          <div class="prop" v-if="user.work_position">
            <div class="label">Должность:</div>
            <div class="value">
              {{ user.work_position }}
            </div>
          </div>
        </div>

        <h2>Аккаунт</h2>
        <div class="block">
          <div class="prop">
            <div class="label">Логин:</div>
            <div class="value">
              {{ user.login }}
            </div>
          </div>
          <div class="prop">
            <div class="value change-password" @click="changePassword">
              Сменить пароль
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsTeleportWrapper from "@/components/teleport-wrapper.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import sbsPopup from "@/components/popup.vue";

export default {
  name: "sbs-user-profile",
  components: {
    sbsPopup,
    sbsLoaderInline,
    sbsTeleportWrapper,
  },
  data() {
    return {
      loading: true,
      user: null,
      photoActionsOpen: false,
      changed: false,
    };
  },

  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;

      //запрашиваем данные
      rest
        .call("user.profile.get", {}, false)
        .then((data) => {
          if (data.success) {
            this.user = data.fields;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Открывает popup действий с фото
     */
    phoneActions() {
      this.photoActionsOpen = true;
    },

    /**
     * Снять фото на камеру
     */
    getPictureFromCamera() {
      this.photoActionsOpen = false;
      bus.emit("SBS_CAMERA_E_GET_PICTURE", {
        resolve: this.onGetPictureFromCamera,
      });
    },

    /**
     * Получение фото из фото библиотеки
     */
    getPictureFromMedia() {
      this.photoActionsOpen = false;
      bus.emit("SBS_CAMERA_E_GET_PICTURE", {
        resolve: this.onGetPictureFromCamera,
        photoLibrary: true,
      });
    },

    /**
     * Обработка получения фото с камеры
     */
    onGetPictureFromCamera(imageData) {
      this.$store.commit("setPictureEditorParams", {
        image: "data:image/jpeg;base64," + imageData,
        save: this.setCroippedPicture,
      });
      this.$router.push({ name: "picture-editor" });
    },

    /**
     * Устанавливает обрезанное фото
     */
    setCroippedPicture(data) {
      if (!this.user.photo) {
        this.user.photo = {};
      }

      this.user.photo.data = data.replace("data:image/jpeg;base64,", "");
      this.changed = true;
    },

    /**
     * Удалить фото
     */
    deletePhoto() {
      this.photoActionsOpen = false;
      this.user.photo = false;
      this.changed = true;
    },

    /**
     * Сохранение
     */
    save() {
      let fields = {};
      if (this.user.photo.data) {
        fields.photo = this.user.photo.data;
      } else if (this.user.photo == false) {
        fields.photo = false;
      }

      //запрашиваем данные
      rest
        .call("user.profile.update", {
          method: "post",
          data: {
            fields: fields,
          },
        })
        .then((data) => {
          if (data.success) {
            this.changed = false;
            this.loadData();
            //генерим событие успешного обнолвения профиля
            bus.emit("SBS_USER_PROFILE_E_UPDATE");
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },

    /**
     * Переход к смене пароля
     */
    changePassword() {
      this.$router.push({ name: "password-change" });
    },
  },

  /**
   * Создание эксземпляра компонента
   */
  created() {
    //загружаем данные
    this.loadData();
  },
};
</script>

<style lang="scss">
.sbs-user-profile {
  .photo-block {
    margin-bottom: 30px;

    .photo {
      width: 140px;
      height: 140px;
      margin: 0px auto 20px auto;
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 120px;
        color: var(--color-color1);
      }

      .picture {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-size: cover;
      }
    }

    .change-wrap {
      text-align: center;
    }

    .change {
      display: inline-block;
    }
  }

  .block {
    padding: 10px;
    border-radius: 8px;
    background-color: var(--color-block-background);
    margin-bottom: 20px;

    .prop {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    .label {
      line-height: 1;
      color: var(--color-block-label);
      font-size: 16px;
    }

    .value {
      color: var(--color-block-value);
      line-height: 1.14;
    }

    .change-password {
      display: inline-block;
      border-bottom: 1px dashed currentColor;
      color: var(--color-text-primary);
    }
  }
}
</style>