class AuthTokenExpiredError extends Error {

    constructor(message) {
        super(message); 
    }
}

class NotFoundError extends Error {

    constructor(message) {
        super(message); 
    }
}

export {AuthTokenExpiredError,NotFoundError};