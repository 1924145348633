
import bus from '@/plugins/bus'
import { SpeechRecognition } from "@awesome-cordova-plugins/speech-recognition";

export default {

    /**
     * Инициализация
     */
    init() {
        //подписываемся на событие вызова
        bus.on('SBS_SPEECH_RECOGNITION_E_START', (params) => {
            this.prepare(params);
        });

        bus.on('SBS_SPEECH_RECOGNITION_E_STOP', () => {
            this.stop();
        });

        
    },

    /**
     * Подготовка
     */
    prepare(params) {
        //проверяем есить ли разрешение на микрофон
        SpeechRecognition.hasPermission().then((result) => {
            //если нет
            if (!result) {
                //запрашиваем разрешение
                SpeechRecognition.requestPermission().then(() => {
                    //проверим дал ли пользователь разрешение
                    SpeechRecognition.hasPermission().then((result) => {
                        if (result) {
                            //запустим распознование речи
                            this.start(params);
                        }
                    });
                });
            } else {
                //сразу запустим распознование речи
                this.start(params);
            }
        });
    },

    /**
     * Старт
     */
    start(params) {
        let data = {
            language: "ru-RU",
            maxResults: 2,
            partialResults: true,
            showPartial: true,
            showPopup: false
        };
        
        if(params.onStart) {
            params.onStart();
        }
        SpeechRecognition.startListening(data).subscribe({
            next: (matches) => {
                if (matches.length > 0) {
                                params.resolve(matches[0]);
                }
            },
            error: (error) => {
                if(error == "No match") {
                    //не удалось распознать текст
                }
            }
        });
    },

    /**
     * Остановка
     */
    stop() {
        SpeechRecognition.stopListening();
    }

}