<template>
  <div class="sbs-requests-statistics-ctbd">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend
);

import moment from "moment";
import bus from "@/plugins/bus";
import { shallowRef } from "vue";

export default {
  name: "sbs-requests-statistics-closetime-by-responsible-users",
  components: {},
  data() {
    return {
      chart: false,
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    stacked() {
      return this.items.filter((i) => i.status_id > 0).length > 0;
    },
  },
  methods: {
    draw() {
      let ctx = this.$refs.canvas;

      this.chart = shallowRef(
        new Chart(ctx, {
          type: "line",
          data: this.data(),
          options: this.options(),
        })
      );
    },

    refresh() {
      this.chart.options = this.options();
      this.chart.update();
    },

    data() {
      let data = this.items.map(
        (item) =>
          Math.round((parseInt(item.close_period_avg) / 60 / 60) * 100) / 100
      );

      let color = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("--color-color1");

      return {
        labels: this.items.map((item) =>
          moment(item.date_close_date, "DD.MM.YYYY hh:mm:ss").format("DD.MM")
        ),
        datasets: [
          {
            data: data,
            borderColor: color,
            backgroundColor: color,
          },
        ],
      };
    },

    /**
     * Опции графика
     */
    options() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },

        aspectRatio: 1.1,

        scales: {
          x: {
            ticks: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-text-label"),
            },
            grid: {
              borderColor: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-text-label"),
              display: false,
            },
          },
          y: {
            ticks: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-text-label"),
            },
            grid: {
              borderColor: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-text-label"),
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-control-background"),
            },
            beginAtZero: true,
          },
        },
      };
    },
  },
  mounted() {
    this.draw();

    //подписываемся на событие завершения смены темы
    bus.on("SBS_APP_E_THEME_CHANGED", this.refresh);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_APP_E_THEME_CHANGED", this.refresh);
  },
};
</script>


<style lang="scss">
.sbs-requests-statistics-ctbd {
  margin-bottom: 30px;
}
</style>