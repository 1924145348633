<template>
  <div class="sbs-tabs pull-to-refresh--consume">
    <div v-for="item in items" class="item" :class="{ active: item.code == active }" :key="item.code"
      @click="onClick(item)">
      {{ item.name }}
      <div class="count" v-if="item.count > 0">
        <div class="value">{{ item.count <= 99 ? item.count : "99+" }}</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "sbs-tabs",
  emits: ["update:active"],
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    active: {
      type: String,
      default() {
        return "";
      },
    },
  },

  methods: {
    onClick(item) {
      this.$emit("update:active", item.code);
    },
  },
};
</script>

<style lang="scss">
.sbs-tabs {
  padding-top: 12px;
  margin-top: -12px;
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  .item {
    position: relative;
    display: inline-block;
    font-size: 16px;
    padding: 0px 5px 10px;
    color: var(--color-text-label);
    border-bottom: 1px solid var(--color-text-label);
    margin: 5px 5px;

    .count {
      position: absolute;
      top: -12px;
      right: -12px;

      width: 20px;
      height: 20px;
      background-color: var(--color-message-error-background);
      color: var(--color-message-error-text);
      border-radius: 50%;

      .value {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 12px;
        transform: translate(-50%, -50%);
      }
    }

    &:last-child {
      margin-right: 20px;
    }

    &.active {
      color: var(--color-color1);
      border-bottom-color: var(--color-color1);
    }
  }
}

@media (max-width:1199px){
  .sbs-tabs{
    white-space: nowrap;
    overflow-x: scroll;
    margin-left: -20px;
    margin-right: -20px;
    .item {
      margin: 0px 5px;
    }
    &:first-child {
      margin-block: 20px;
    }
  }
}
</style>