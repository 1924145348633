<template>
  <div class="sbs-menu-more">
    <div class="items">
      <div v-for="item in itemsReduced" :key="item.id" class="item" @click="onItemClick(item)">
        <span class="icon fi" :class="item.icon"></span>
        <div class="name">{{ item.name }}</div>
        <div class="description" v-if="item.description" v-html="item.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "sbs-menu-more",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      items: "getMenu",
      service: "getService"
    }),

    /**
     * Пункты меню, относящиеся к текущему сервису
     */
    serviceItems() {
      return this.items.filter(item => item.service == this.service);
    },

    /**
     * Пункты меню, обрезанные не больше 4-х
     */
    itemsReduced() {
      return this.serviceItems.length <= 4 ? [] : this.serviceItems.slice(3);
    },
  },
  methods: {
    //клик по пункту
    onItemClick(item) {

      if (item.url == "logout") {
        //сброс данных авторизации и данных пользователя
        this.$store.dispatch("logout");
        return;
      }

      this.$router.push({ path: item.url });
    },
  },
};
</script>

<style lang="scss">
.sbs-menu-more {
  .item {
    position: relative;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--color-block-background);
    margin-bottom: 20px;
    padding-left: 50px;

    .icon {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      font-size: 20px;
      color: var(--color-icon-active);
    }

    .name {
      font-size: 16px;
    }

    .description {
      color: var(--color-text-label);
      font-size: 12px;
    }
  }
}
</style>