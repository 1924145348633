<template>
    <sbs-view :class="'sbs-v-edu-training-posts'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
        <template v-slot:header>
            <sbs-header :showBackBtnText="false">
                <template v-slot:center>
                    <div class="title">{{ training ? training.name : "" }}</div>
                </template>
            </sbs-header>
        </template>

        <template v-slot:content>

            <!-- Загрузка -->
            <template v-if="loading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>

            <div class="items row">

                <div class="col-12 col-sm-12 col-md-6 col-lg-4" v-for="(item, index) in items" :key="item.id">
                    <sbs-education-training-post-item :item="item" class="item" :index="index + 1"
                        :locked="isLocked(item)" :trainingId="id" />
                </div>

                <div v-if="items.length > 0" class="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="finish">

                        <div class="content">

                            <img :src="imageFinish" />

                            <div class="title">{{ $t("education.training.posts.finish") }}</div>
                            <div class="description">{{ $t("education.training.posts.success") }}</div>

                        </div>

                        <div v-if="!training.result || training.result.status != 'S'" class="lock">
                            <div class="lock-content">
                                <div><span class="fi fi-lock icon"></span></div>

                            </div>
                        </div>



                    </div>
                </div>

            </div>
        </template>

    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsEducationTrainingPostItem from "@/components/education/training.post.item.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import imageFinishPath from "@/assets/images/finish.svg";

export default {
    name: "sbs-v-education-training-posts",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsEducationTrainingPostItem
    },
    data() {
        return {
            hideMenu: false,
            loading: true,

            training: false,
            items: [],

            imageFinish: imageFinishPath
        };
    },
    props: {
        id: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    computed: {

    },
    watch: {
        id: function () {
            this.$refs.view.resetSavedScroll();
            this.training = false;
            this.items = [];
            this.loadData();
        },
    },

    methods: {

        getBatchRequests() {
            let requests = {
                training: {
                    ENTITY: "education",
                    METHOD: "training.get",
                    PARAMS: {
                        mode: "public",
                        id: this.id,
                        load_my_results: "Y",
                    },
                },

                posts: {
                    ENTITY: "education",
                    METHOD: "training.post.list",
                    PARAMS: {
                        mode: "public",
                        load_my_results: "Y",
                        training_id: this.id,
                        resize_options: {
                            PREVIEW: { WIDTH: 400, HEIGHT: 300 },
                            PRELOAD: "Y"
                        },
                    },
                }
            };

            return requests;
        },

        /**
         * Загружает данные
         */
        loadData(resolve = false) {

            let requests = this.getBatchRequests();

            //покажем экран загрузки
            this.loading = resolve ? false : true;

            rest.batch(requests)
                .then((results) => {
                    for (var key in results) {
                        let data = results[key];
                        if (data.success) {
                            if (key == "training") {
                                this.training = data.training;
                            } else if (key == "posts") {
                                this.items = data.items;
                            }
                        } else {
                            //показываем сообщение об ошибке
                            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                            return;
                        }

                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    //скроем экран загрузки
                    this.loading = false;
                });

        },

        /**
         * Перезагрузка данных
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Возвращает флаг необходимости блокировки поста
         */
        isLocked(item) {

            let index = this.items.findIndex(p => p.id == item.id);
            if (index == 0) {
                return false;
            }

            let prevItem = this.items[index - 1];
            if (!prevItem.result || prevItem.result.status != 'S') {
                return true;
            }

            return false;
        },

        /**
         * Обработка события сохранение результата
         */
        onPostResultSave(fields) {

            let item = this.items.find((i) => i.id == fields.id);

            //если пост в списке отображения  есть
            if (item) {
                this.syncPost(item);
                this.syncTraining();
            }

        },

        /**
         * Синхронизация
         */
        syncPost(item) {
            //запрашиваем данные
            rest
                .call("education.training.post.list", {
                    method: "post",
                    data: {
                        mode: "public",
                        load_my_results: "Y",
                        training_id: this.id,
                        resize_options: {
                            PREVIEW: { WIDTH: 400, HEIGHT: 300 },
                            PRELOAD: "Y"
                        },
                        filter: {
                            id: item.id
                        }
                    },
                })
                .then((data) => {
                    let index = this.items.indexOf(item);
                    //если завка найдена
                    if (data.success && data.items.length > 0) {
                        this.items[index] = data.items[0];
                    }

                });
        },

        /**
         * Обновление данные тренировки
         */
        syncTraining() {
            //запрашиваем данные
            rest
                .call("education.training.get", {
                    method: "post",
                    data: {
                        mode: "public",
                        id: this.id,
                        load_my_results: "Y",
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.training = data.training;
                    }

                });
        }
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.loadData();
    },

    mounted() {
        //подписваемся на события
        bus.on("SBS_EDU_POST_RESULT_E_SAVE", this.onPostResultSave);
    },

    unmounted() {
        //отписываемся от событий
        bus.off("SBS_EDU_POST_RESULT_E_SAVE", this.onPostResultSave);
    },


    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },

}
</script>

<style lang="scss">
.sbs-v-edu-training-posts {

    @media (min-width: 768px) {
        .items {
            margin: 0px -10px;

            >div {
                padding: 0px 10px;
            }

            .item {
                min-height: calc(100% - 20px);
            }
        }
    }

    .finish {
        position: relative;
        min-height: calc(100% - 20px);
        margin-bottom: 20px;
        border-radius: 8px;

        background-color: var(--color-block-background);


        .content {
            text-align: center;

            padding: 40px 0px;

            img {
                max-width: 100px;
                margin-bottom: 15px;
            }

            .title {
                font-size: 17px;
                font-weight: 500;
            }

            .description {
                font-size: 12px;
            }
        }

        .lock {
            position: absolute;
            z-index: 10;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;

            //цвет фиксируем, так как отображается на тёмном фоне всегда
            color: #fff;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                z-index: 11;
                //цвет фиксируем, так как в любой теме будет затенение
                background-color: #000;
                opacity: 0.5;
            }

            .lock-content {
                position: absolute;
                left: 30px;
                right: 30px;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                z-index: 12;

                .icon {
                    font-size: 40px;
                }
            }

        }


    }
}
</style>