<template>
    <div class="sbs-requests-statistics-rbru">
        <div class="items">
            <div class="item" v-for="item in sortedItems" :key="item.responsible_user_id">
                <div>
                    <div class="avatar">
                        <div v-if="!item.responsible_user_photo" class="initials">
                            {{ item.responsible_user_last_name.charAt(0) + item.responsible_user_name.charAt(0) }}
                        </div>
                        <div v-else class="photo" :style="{
                backgroundImage: 'url(' + item.responsible_user_photo.preview + ')',
            }"></div>
                    </div>
                </div>
                <div>
                    <div class="fio">
                        {{ item.responsible_user_last_name }} {{ item.responsible_user_name }}
                    </div>
                </div>
                <div>

                    <sbs-control-stars :modelValue="item.rating_avg" :readonly="true" :noMargin="true" />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sbsControlStars from "@/components/controls/stars.vue";

export default {
    name: "sbs-requests-statistics-rating-by-responsible-users",
    components: {
        sbsControlStars
    },
    data() {
        return {

        };
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        sortedItems() {
            let itemsSorted = this.items.map((i) => i);

            itemsSorted.sort((a, b) => parseFloat(b.rating_avg) - parseFloat(a.rating_avg));

            return itemsSorted;
        }
    },
    methods: {
    },

};
</script>


<style lang="scss">
.sbs-requests-statistics-rbru {
    margin-bottom: 30px;

    .item {
        display: grid;
        grid-template-columns: 30px 90px auto;
        column-gap: 10px;
        align-items: center;
        margin-bottom: 15px;

        .avatar {
            position: relative;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--color-chat-fio);

            .initials {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: var(--color-chat-background);
            }

            .photo {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-size: cover;
            }
        }

        .fio {
            font-size: 12px;
            font-weight: 500;
            color: var(--color-chat-fio);
        }
    }
}
</style>