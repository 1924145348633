<template>
  <div class="sbs-dialog-dates" :class="[visibleInternal ? 'show' : '']"
    :style="{ paddingTop: paddingTop + 'px', paddingBottom: paddingBottom + 'px' }">

    <sbs-header> </sbs-header>

    <div class="outer">
      <div class="container">
        <div class="page-padding">
          <div class="h2" v-html="title"></div>

          <div class="row">
            <div class="col">
              <div class="types">
                <div v-for="t in types" :key="t.id" class="type">
                  <sbs-control-radio :value="t.id" v-model="type" :label="t.name" />
                </div>
              </div>
            </div>
            <div class="col">
              <div class="variants">
                <div v-for="v in variants" :key="v.id" class="variant">
                  <sbs-control-radio :value="v.id" v-model="variant" :label="v.name" />
                </div>
              </div>
            </div>
          </div>

          <div v-if="type == 'interval'" class="interval">
            <div class="row">
              <div class="col">
                <sbs-control-date v-model="from" label="Дата с" />
              </div>
              <div class="col">
                <sbs-control-date v-model="to" label="Дата по" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <div class="container">
        <div class="page-padding">
          <div class="row">
            <div class="col-6">
              <div class="btn btn-block btn2" @click="reset">Сбросить</div>
            </div>
            <div class="col-6">
              <div class="btn btn-block btn1" @click="apply">Выбрать</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import sbsHeader from "@/components/header.vue";
import sbsControlRadio from "@/components/controls/radio.vue";
import sbsControlDate from "@/components/controls/date.vue";

import moment from "moment";
import types from "@/plugins/dates.variants";

import { mapGetters } from "vuex";

export default {
  name: "sbs-dialog-dates",
  components: {
    sbsHeader,
    sbsControlRadio,
    sbsControlDate,
  },
  emits: [
    "update:visible",
    "update:dateFrom",
    "update:dateTo",
    "update:variant",
    "update:value",
  ],
  data() {
    return {
      visibleInternal: this.visible,
      watchVisible: false,

      type: "",
      variant: "",
      from: "",
      to: "",
      types: types,
    };
  },
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dateFrom: {
      type: String,
      default() {
        return "";
      },
    },
    dateTo: {
      type: String,
      default() {
        return "";
      },
    },
  },
  computed: {
    ...mapGetters({
      safeArea: "getSafeArea",
      platform: "getPlatform"
    }),
    paddingTop() {
      if (this.safeArea && this.safeArea.top > 0) {
        return this.safeArea.top;
      }

      return 0;
    },
    paddingBottom() {
      if (this.safeArea && this.safeArea.bottom > 0 && this.platform == "ios") {
        return this.safeArea.bottom;
      }
      return 0;
    },
    variants() {
      if (!this.types) {
        return [];
      }
      return this.types.find((t) => t.id == this.type).variants;
    },
  },
  watch: {

    visible: function (newVal) {
      this.visibleInternal = newVal;
      if (newVal) {
        this.onOpen();
      }
    },

    visibleInternal: function (newVal) {
      if (!newVal) {

        if (this.watchVisible) {
          //эмитируем переход назад
          history.back();
        }
      }

      this.$emit("update:visible", newVal);
    },

    type: function () {
      this.variant = "";
    },
    dateFrom: function (newVal) {
      if (this.from != newVal) {
        this.from = newVal;
        this.refresh();
      }
    },
    dateTo: function (newVal) {
      if (this.to != newVal) {
        this.to = newVal;
        this.refresh();
      }
    },
  },
  methods: {

    /**
     * Открытие диалога
     */
    onOpen() {

      this.watchVisible = true;

      //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
      window.history.pushState({}, "", window.location.href + "?dialogOpen");

      //добавляем закрытие в стэк обработчиков возврата назад
      this.$store.commit("addToBackStack", this.closeFromBaсkBtn);

    },

    /**
     * Закрыть при нажатие Назад в браузере
     */
    closeFromBaсkBtn() {
      this.watchVisible = false;
      this.visibleInternal = false;
      //удаляем закрытие из стэка обработчиков возврата назад
      this.$store.commit("removeFromBackStack", this.closeFromBaсkBtn);
    },

    /**
     * Применить
     */
    apply() {
      if (this.type && this.variant) {
        let variant = this.variants.find((v) => v.id == this.variant);
        if (variant) {
          this.from = variant.from;
          this.to = variant.to;
        }
      }

      this.$emit("update:dateFrom", this.from);
      this.$emit("update:dateTo", this.to);
      if (this.to || this.from) {
        this.$emit("update:value", {
          from: this.from,
          to: this.to,
          id: moment().format("x"),
        });
      } else {
        this.$emit("update:value", false);
      }

      this.visibleInternal = false;
    },

    /**
     * Сбросить
     */
    reset() {
      this.type = "";
      this.variant = "";
      this.from = "";
      this.to = "";
      this.$emit("update:dateFrom", this.from);
      this.$emit("update:dateTo", this.to);
      this.$emit("update:value", false);
      this.visibleInternal = false;
    },

    refresh() {
      //пробуем найти вариант, подходящий под выбранные даты
      var type = this.types.find((t) =>
        t.variants.find((v) => v.from == this.from && v.to == this.to)
      );
      //если есть такой предустановленный вариант
      if (type) {
        this.type = type.id;
        this.variant = type.variants.find(
          (v) => v.from == this.from && v.to == this.to
        ).id;
      }
      //иначе если задана какая-то дата
      else if (this.from || this.to) {
        this.type = "interval";
      }
      //иначе никакие даты не заданы
      else {
        this.type = "";
      }
    },
  },
};
</script>

<style lang="scss">
.sbs-dialog-dates {
  display: none;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2000;
  background: var(--color-background);

  .btn-close {
    display: inline-block;
    color: var(--color-text-label);
    font-size: 16px;
    padding-left: 22px;
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      font-size: 14px;
      color: var(--color-icon-active);
    }
  }

  &.show {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }

  .outer {
    position: relative;
    padding-bottom: 20px;

    .type {
      padding: 5px 0px;
    }

    .variant {
      padding: 5px 0px;
    }

    .interval {
      margin-top: 30px;
    }
  }

  .buttons {
    padding-bottom: 20px;
  }
}
</style>
