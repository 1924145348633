<template>
  <div class="sbs-control-datetime">
    <div class="row">
      <div class="col">
        <sbs-control-date v-model="date" :label="label" :required="required" />
      </div>
      <div class="col col-time">
        <sbs-control-time v-model="time" :required="required" />
      </div>
    </div>
  </div>
</template>

<script>
import sbsControlDate from "@/components/controls/date.vue";
import sbsControlTime from "@/components/controls/time.vue";

import moment from "moment";

export default {
  name: "sbs-control-datetime",
  components: {
    sbsControlDate,
    sbsControlTime,
  },
  data() {
    return {
      value: this.modelValue,
      date: this.getDefaultDate(),
      time: this.getDefaultTime(),
      ignore: false,
    };
  },
  emits: ["update:modelValue"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },

    modelValue: {
      type: String,
      default() {
        return "";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {},
  watch: {
    modelValue: function (newVal) {
      if (newVal !== this.value) {
        this.ignore = true;
        this.value = newVal;
        this.date = this.getDefaultDate();
        this.time = this.getDefaultTime();
        this.false = true;
      }
    },
    value: function () {
      if (!this.ignore) {
        this.$emit("update:modelValue", this.value);
      }
    },
    date: function () {
      if (!this.ignore) {
        if (!this.date) {
          this.time = "";
        }
        this.setValue();
      }
    },
    time: function () {
      if (!this.ignore) {
        this.setValue();
      }
    },
  },
  methods: {
    getDefaultDate() {
      if (this.modelValue != undefined && this.modelValue != "") {
        return moment(this.modelValue, "DD.MM.YYYY HH:mm:ss").format(
          "DD.MM.YYYY"
        );
      }
      return "";
    },
    getDefaultTime() {
      if (this.modelValue != undefined && this.modelValue != "") {
        return moment(this.modelValue, "DD.MM.YYYY HH:mm:ss").format("HH:mm");
      }
      return "";
    },

    setValue() {
      let val = this.date;
      if (this.time) {
        val += " " + this.time + ":00";
      }
      this.value = val;
    },
  },
};
</script>

<style lang="scss">
.sbs-control-datetime {
  .row {
    margin-left: -10px;
    margin-right: -10px;

    > div {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .col-time {
    flex: 0 0 120px;
  }
}
</style>
