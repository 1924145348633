

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

const user = {

    state: () => {
        return {

            //профиль пользователя
            user: false,

            //права доступа
            access: false,

            requestUnread: [],

            //последние прочитанные сообщения в данном сеансе
            readMessagesStack:[],

            //список сохранённых фильтров
            userFilters: [],

            //рейтинг
            requestRating: 0
            
        }
    },
    getters: {
        getUserProfile(state) {
            return state.user;
        },

        getAccess(state) {
            return state.access;
        },

        getServices(state,getters,rootState) {

            let services = rootState.clientSettings ? rootState.clientSettings.services : [];

            return services;
        },

        getUserDataBatch(state,getters,rootState ) {

            let services = rootState.clientSettings ? rootState.clientSettings.services : [];

            let requests = {
                user: {
                    ENTITY: "user",
                    METHOD: "profile.get",
                },
                access: {
                    ENTITY: "user",
                    METHOD: "access"
                },
                filters: {
                    ENTITY: "user",
                    METHOD: "filter.list",
                    PARAMS: {}
                }
                
            };

            if(services.includes("REQUESTS")) {
                requests.requestUnread = {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: ["id","unread_messages_cnt"],
                        group: ["id"]
                    }
                }

                requests.rating = {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "rating_avg",
                        },
                        filter: {
                            "responsible_user_id": "MY_SELF",
                            ">rating": 0
                        },

                    },
                }
            }

            return requests;
        },

        /**
         * Счётчик для иконки приложения (непрочитанных сообщений)
         */
        getBadgetCount(state) {
            let sum = 0;
            state.requestUnread.forEach(item => {
                sum += parseInt(item.unread_messages_cnt)
                
            });
            return sum;
        },

        /**
         * Общее кол-во непрочитанных сообщений по заявкам
         */
        getRequestUnread(state) {
            return state.requestUnread;
        },

        /**
         * Общее кол-во непрочитанных сообщений по заявкам
         */
        getRequestUnreadCount(state) {
            let sum = 0;
            state.requestUnread.forEach(item => {
                sum += parseInt(item.unread_messages_cnt)
                
            });
            return sum;
        },

        /**
         * Возвращает стэк прочитанных сообщений
         */
        getReadMessagesStack(state) {
            return state.readMessagesStack;
        },

        /**
         * Возвращает по типу сохранённые фильтры пользователя
         */
        getUserFiltersByType: state => type => {
            let items = state.userFilters.filter(item => item.type == type)
            return items;
        },

        /**
         * Рейтинг по заявкам
         */
        getRequestRating(state) {
            return state.requestRating;
        }
    },

    mutations: {

        /**
         * Устанавливает данные профиля пользователя
         */
        setUserProfile: (state, value) => {
            state.user = value;
        },

        /**
         * Устанавливает права доступа пользователя
         */
        setAccess: (state, value) => {
            state.access = value;
        },

        /**
         * Устанавилвает статистику непрочитанных сообщений по заявкам
         */
        setRequestUnread: (state, value) => {
            state.requestUnread = value;
        },

        /**
         * Устанавилвает по заявке кол-во непрочитанных сообщений
         */
        updateRequestUnread: (state, data) => {

            let item = state.requestUnread.find(i => i.id == data.id);
            if(item) {
                item.unread_messages_cnt = data.count
                if(item.unread_messages_cnt <=0) {
                    var index = state.requestUnread.indexOf(item);
                    if (index !== -1) {
                        state.requestUnread.splice(index, 1);
                    }
                }
            }
        },

        /**
         * Увеличивает по заявке кол-во непрочитанных сообщений
         */
        increaseRequestUnread: (state, data) => {
            let item = state.requestUnread.find(i => i.id == data.id);
            if(item) {
                item.unread_messages_cnt = parseInt(item.unread_messages_cnt) + data.count
            } else {
                state.requestUnread.push({
                    id: data.id,
                    unread_messages_cnt: data.count
                })
            }
        },

        /**
         * Уменьшает по заявке кол-во непрочитанных сообщений 
         */
        decreaseRequestUnread: (state, data) => {
            let item = state.requestUnread.find(i => i.id == data.id);
            if(item) {
                item.unread_messages_cnt = parseInt(item.unread_messages_cnt) - data.count
                if(item.unread_messages_cnt <=0) {
                    var index = state.requestUnread.indexOf(item);
                    if (index !== -1) {
                        state.requestUnread.splice(index, 1);
                    }
                }
            }
        },

        /**
         * Добавляет прочитанное сообщение
         */
        addReadMessage: (state, id) => {
            if(state.readMessagesStack.includes(id)) {
                return;
            }
            state.readMessagesStack.unshift(id);
            if(state.readMessagesStack.length > 50) {
                state.readMessagesStack.length = 50
            }
        },

        /**
         * Добавляет несколько прочитанных сообщений
         */
        addReadMessages: (state, ids) => {
            ids.forEach((id) => {
                if(!state.readMessagesStack.includes(id)) {
                    state.readMessagesStack.unshift(id);
                }
            })
            
            if(state.readMessagesStack.length > 50) {
                state.readMessagesStack.length = 50
            }
        },

        /**
         * Устанавливает сохранённые фильтры пользователя
         */
        setUserFilters: (state, value) => {
            state.userFilters = value;
        },

        /**
         * Добавляет сохранённый фильтры пользователя
         */
        addUserFilter: (state, value) => {
            state.userFilters.push(value);
        },

        /**
         * Удаляет сохранённый фильтр пользователя
         */
        removeUserFilter: (state, value) => {
            state.userFilters = state.userFilters.filter( f => f.id != value);
        },

        /**
         * Устанавилвает рейтинг по заявкам
         */
        setRequestRating: (state, value) => {
            state.requestRating = value;
        }

    },

    actions: {

        /**
         * Загружает данные пользователя
         */
        loadUserData(context) {

            //запрашиваем данные
            rest
                .batch(context.getters.getUserDataBatch)
                .then((results) => {
                    for (var key in results) {
                        let data = results[key];
                        if (data.success) {
                            //профиль пользователя
                            if (key == "user") {
                                context.commit('setUserProfile', data.fields);
                                context.dispatch('saveUserProfileData');
                            }
                            //права пользователя
                            else if (key =="access") {
                                context.commit('setAccess', data.access);
                            }
                            //кол-во непрочитанных сообщений по заявкам
                            else if (key =="requestUnread") {
                                context.commit('setRequestUnread', data.items);
                            } 
                            //сохранённые фильтры
                            else if (key == 'filters') {

                                context.commit('setUserFilters', data.items);
                                
                                data.items.forEach(item => {
                                    context.commit("setFilter", {
                                        name: item.type+"-saved-filter-" + item.id,
                                        value: item.filter
                                    });

                                    if(item.sort) {
                                        context.commit("setSort", {
                                            name: item.type+"-saved-filter-" + item.id,
                                            value: item.sort 
                                        });
                                    }
                                    
                                })
                                
                            }
                            //рейтинг по заявкам
                            else if (key == 'rating') {
                                context.commit('setRequestRating', data.items.length > 0 ? data.items[0].rating_avg : 0);
                            }
                            
                        } else {
                            //показываем сообщение об ошибке
                            bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: data.errorText });
                            return;
                        }
                    }
                });
         
        },

        /**
         * Сохраняет в данных подключения некоторые поля профиля
         */
        saveUserProfileData(context) {

            let arConnection = context.getters.getConnection
            let userProfice = context.getters.getUserProfile
            arConnection.profile = {
                name: userProfice.name,
                last_name: userProfice.last_name
            };
            context.commit("updateConnection", arConnection);
        },

        /**
         * Добавляет фильтр пользователя
         */
        addUserFilter(context, item) {
            context.commit("addUserFilter", item);
            context.commit("setFilter", {
                name: item.type+"-saved-filter-" + item.id,
                value: item.filter
            });
            if(item.sort) {
                context.commit("setSort", {
                    name: item.type+"-saved-filter-" + item.id,
                    value: item.sort
                });
            }
        }

    }

}

export default user;