<template>
  <div class="sbs-requests-widget" :class="{ open: open }">
    <div class="wrap">
      <div class="container">
        <div class="outer">
          <div class="inner">
            <div class="btn-widget" @click="onBtnClick">
              <span class="icon icon-add fi fi-add"></span>
              <span class="icon icon-close fi fi-close"></span>
            </div>
            <div class="actions">
              <div class="action">
                <span class="icon fi fi-form" @click="goToForm"></span>
              </div>
              <div class="action">
                <span class="icon fi fi-copy" @click="goToMultyForm"></span>
              </div>
              <div class="action" @click="goToMaster">
                <span class="icon fi fi-steps"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="shadow" @click="onShadowClick"></div>
  </div>
</template>


<script>
export default {
  name: "sbs-requests-widget",
  data() {
    return {
      open: false,
    };
  },
  methods: {
    /**
     * Клик по кнопке
     */
    onBtnClick() {
      this.open = !this.open;
    },

    /**
     * Клик по тени
     */
    onShadowClick() {
      this.open = false;
    },

    /**
     * Переход к обычной форме
     */
    goToForm() {
      this.open = false;
      //переход на экран добавления заявки
      this.$router.push({ name: "request-form", params: { id: 0 } });
    },

    /**
     * Переход к мастеру создания заявки
     */
    goToMaster() {
      this.open = false;
      //переход на экран добавления заявки
      this.$router.push({ name: "request-master" });
    },

    /**
     * Переход к форме мультизаявки
     */
    goToMultyForm() {
      this.open = false;
      //переход на экран мультизаявки
      this.$router.push({ name: "request-master-multi" });
    },
  },
};
</script>

<style lang="scss">
.sbs-requests-widget {
  .wrap {
    position: absolute;
    bottom: 80px;
    right: 0px;
    left: 0px;
    z-index: 1150;

    .outer {
      position: relative;
    }

    .inner {
      position: absolute;
      right: 5px;
      bottom: 0px;
    }
  }

  .btn-widget {
    position: relative;
    border-radius: 50%;
    border: 1px solid var(--color-color1);
    background-color: var(--color-block-background);
    width: 56px;
    height: 56px;
    color: var(--color-color1);

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      border-radius: 50%;
      border: 1px solid var(--color-block-background);
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
    }

    .icon-close {
      opacity: 0;
    }
  }

  &.open {
    .btn-widget {
      background-color: var(--color-color1);
      color: var(--color-text);

      .icon-close {
        opacity: 1;
      }

      .icon-add {
        opacity: 0;
      }
    }
  }

  .shadow {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 1100;

    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
  }

  &.open {
    .shadow {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 300ms;
    }
  }

  .actions {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms, bottom 300ms;
  }

  &.open {
    .actions {
      visibility: visible;
      opacity: 1;
      bottom: 100%;
      transition: visibility 0s linear 0s, opacity 300ms, bottom 300ms;
    }
  }

  .action {
    position: relative;
    border-radius: 50%;
    border: 1px solid var(--color-color1);
    background-color: var(--color-block-background);
    width: 56px;
    height: 56px;
    margin-bottom: 15px;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      color: var(--color-color1);
    }
  }
}
</style>