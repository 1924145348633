<template>
  <div class="sbs-requests-statistics-cbru">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend
);

import bus from "@/plugins/bus";
import { shallowRef } from "vue";

export default {
  name: "sbs-requests-statistics-count-by-responsible-users",
  components: {},
  data() {
    return {
      chart: false,
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {},
  methods: {
    draw() {
      let ctx = this.$refs.canvas;

      this.chart = shallowRef(
        new Chart(ctx, {
          plugins: [ChartDataLabels],
          type: "bar",
          data: this.data(),
          options: this.options(),
        })
      );
    },

    refresh() {
      this.chart.options = this.options();
      this.chart.update();
    },

    data() {
      let itemsSorted = this.items.map((i) => i);

      itemsSorted.sort((a, b) => parseInt(b.cnt) - parseInt(a.cnt));

      //ограничим кол-во выводимых данных
      let max = 10;
      if (itemsSorted.length > max) {
        let other = itemsSorted.slice(max);
        itemsSorted = itemsSorted.slice(0, max);

        let cnt = 0;
        other.forEach((item) => {
          cnt += parseFloat(item.cnt);
        });

        itemsSorted.push({ responsible_user_last_name: "Другие", cnt: cnt });
      }

      let labels = itemsSorted.map(
        (item) =>
          item.responsible_user_last_name +
          (item.responsible_user_name
            ? " " + item.responsible_user_name.charAt(0) + "."
            : "")
      );
      let data = itemsSorted.map((item) => parseInt(item.cnt));

      return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: window
              .getComputedStyle(document.documentElement)
              .getPropertyValue("--color-color1"),
          },
        ],
      };
    },

    /**
     * Опции графика
     */
    options() {
      return {
        indexAxis: "y",
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },
        aspectRatio: 1.1,

        scales: {
          x: {
            ticks: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-text-label"),
            },
            grid: {
              borderColor: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-text-label"),
              display: false,
            },
          },
          y: {
            ticks: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-text-label"),
            },
            grid: {
              borderColor: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-text-label"),
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-control-background"),
            },
            beginAtZero: true,
          },
        },
      };
    },
  },
  mounted() {
    this.draw();

    //подписываемся на событие завершения смены темы
    bus.on("SBS_APP_E_THEME_CHANGED", this.refresh);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_APP_E_THEME_CHANGED", this.refresh);
  },
};
</script>


<style lang="scss">
.sbs-requests-statistics-cbru {
  margin-bottom: 30px;
}
</style>