<template>
  <div class="sbs-equipment-item" @click="onItemClick(item)">
    <div class="name" v-html="item.name"></div>
    <div class="status" :class="{ active: item.active == 'Y' }">
      {{ item.active == "Y" ? "Активно" : "Не активно" }}
    </div>
    <div class="department">{{ item.department_name }}</div>
    <div class="description" v-if="item.description.trim() != ''" v-html="item.description"></div>
    <div class="prop">
      <span class="label">Тип:</span>
      <span class="valuie">{{ item.type_name }}</span>
    </div>
    <div class="prop">
      <span class="label">Заявок:</span>
      <span class="valuie">{{ item.request_cnt }}</span>
    </div>
    <div class="support">
      <div v-if="item.have_qr_code" class="option">
        <span class="fi fi-qr-scanner"></span>
      </div>
      <div v-if="item.have_nfc_id" class="option">
        <span class="fi fi-nfc"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sbs-equipment-item",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods: {
    /**
     * Переход к оборудованию
     */
    onItemClick(item) {
      this.$router.push({
        name: "equipment-view",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss">
.sbs-equipment-item {
  position: relative;
  padding: 10px;
  border-radius: 8px;
  background-color: var(--color-block-background);
  margin-bottom: 20px;

  .name {
    padding-right: 120px;
    font-size: 18px;
    color: var(--color-block-title);
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .status {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 6px 5px;
    min-width: 90px;
    text-align: center;
    color: #1e1f22;
    border-radius: 4px;
    line-height: 1;
    background-color: var(--color-not-active);

    &.active {
      background-color: var(--color-active);
    }
  }

  .department {
    color: var(--color-block-label);
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .description {
    margin-bottom: 10px;
  }

  .prop {
    margin-top: 0px;
    padding-right: 70px;

    .label {
      color: var(--color-block-label);
      margin-right: 10px;
      min-width: 51px;
      display: inline-block;
    }

    .value {
      color: var(--color-block-value);
    }
  }

  .support {
    position: absolute;
    bottom: 10px;
    right: 10px;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .option {
      float: left;
      margin-left: 5px;
      color: var(--color-block-label);
    }
  }
}
</style>