<template>
  <sbs-view :class="'sbs-v-home'" :refreshDisabled="true" :isMenuHidden="true" ref="view">
    <template v-slot:content>

      <div class="logo-wrap">
        <sbs-logo />
      </div>

      <div class='welcome'>
        {{ $t('home.welcome') }},<br />
        {{ connection.profile ? connection.profile.name : user.name }}!
      </div>

      <div class="row services">
        <div class="col-6 col-sm-4 col-md-3" v-for="service in availableServices" :key="service.id">

          <div class="service block" @click="onServiceClick(service.id)">

            <div class="inner">
              {{ service.name }}
            </div>

          </div>
        </div>
      </div>

    </template>
  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsLogo from "@/components/logo";

import enums from "@/plugins/enums"

import { mapGetters } from "vuex";

export default {
  name: "sbs-v-home",
  components: {
    sbsView,
    sbsLogo
  },
  data() {
    return {
      reload: 0,
      hideMenu: false,

      services: enums.services
    };
  },

  computed: {
    ...mapGetters({
      userServices: "getServices",
      user: "getUserProfile",
      connection: "getConnection",
    }),

    availableServices() {

      let services = this.services.filter(s => this.userServices.includes(s.id))
      return services;
    }
  },
  methods: {

    onServiceClick(id) {
      this.$store.commit("setService", id);

      if (id == "REQUESTS") {
        this.$router.push({ name: "requests-summary" });
      } else if (id == "EDUCATION") {
        this.$router.push({ name: "education-posts" });
      }
    }

  },


  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

};
</script>


<style lang="scss">
.sbs-v-home {

  .logo-wrap {
    width: 165px;
    margin: 50px auto 50px;
  }

  .welcome {
    font-size: 22px;
    margin-bottom: 50px;
    text-align: center;

  }

  .services {
    margin: 0px -5px;
    justify-content: center;
    cursor: pointer;

    >div {
      padding: 0px 5px;
    }

    .service {
      position: relative;
      text-align: center;
      min-height: 86px;

      .inner {
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        transform: translateY(-50%);
        padding: 0px 15px;
      }
    }
  }
}
</style>
