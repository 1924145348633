<template>
  <div class="sbs-user-view">
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>

    <template v-if="!loading">
      <!-- Не найдена-->
      <div v-if="user == null">Пользователь не найден.</div>
      <!-- Иначе -->
      <div v-else>
        <h1>{{ user.last_name }} {{ user.name }}</h1>
        <div class="about">
          <div class="photo">
            <div v-if="user.photo" class="picture" :style="{
              backgroundImage: 'url(' + user.photo.preview + ')',
            }" @click="openPhoto"></div>
            <span v-else class="icon fi fi-user"></span>
          </div>
          <div v-if="user.last_name" class="last-name">
            {{ user.last_name }}
          </div>
          <div v-if="user.name" class="name">{{ user.name }}</div>
          <div v-if="user.work_position" class="position">
            {{ user.work_position }}
          </div>

          <div v-if="user.department_name" class="department">
            {{ user.department_name }}
          </div>
        </div>

        <div class="contacts">
          <div class="prop" v-if="user.work_phone">
            <div class="name">Рабочий телефон</div>
            <div class="value">
              <a :href="'tel:' + user.work_phone">{{ user.work_phone }}</a>
            </div>
          </div>
          <div class="prop" v-if="user.personal_mobile">
            <div class="name">Мобильный телефон</div>
            <div class="value">
              <a :href="'tel:' + user.personal_mobile">{{
                user.personal_mobile
              }}</a>
            </div>
          </div>
          <div class="prop" v-if="user.email">
            <div class="name">E-Mail</div>
            <div class="value">
              <a :href="'mailto:' + user.email">{{ user.email }}</a>
            </div>
          </div>
        </div>
      </div>

      <sbs-requests-statuses :items="requests_created" :prefilter="prefilterOwn" :title="'Собственные заявки'"
        titleClass="h2" />

      <sbs-requests-statuses :items="requests_reponsible" :prefilter="prefilterResponsible" :title="'Назначенные заявки'"
        titleClass="h2" />
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsRequestsStatuses from "@/components/requests/statuses.vue";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import utils from "@/plugins/utils";

export default {
  name: "sbs-department-view",
  components: {
    sbsLoaderInline,
    sbsRequestsStatuses,
  },
  data() {
    return {
      loading: true,
      user: null,
      requests_created: [],
      requests_reponsible: [],
    };
  },
  computed: {
    gallery() {
      let images = [];
      if (this.user.photo) {
        images.push({
          src: this.user.photo.full
            ? this.user.photo.full
            : this.user.photo.src,
          type: this.user.photo.image ? "image" : "video",
          width: this.user.photo.width,
          height: this.user.photo.height,
          thumb: this.user.photo.preview
            ? this.user.photo.preview
            : this.user.photo.src,
          id: this.user.photo.id,
        });
      }

      return images;
    },

    prefilterOwn() {
      if (this.user == null) {
        return {};
      }
      return {
        createdBy: [
          {
            id: this.user.id,
            name: this.user.name,
            last_name: this.user.last_name,
          },
        ],
      };
    },
    prefilterResponsible() {
      if (this.user == null) {
        return {};
      }
      return {
        responsibleUser: [
          {
            id: this.user.id,
            name: this.user.name,
            last_name: this.user.last_name,
          },
        ],
      };
    },
  },

  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  watch: {
    id: function () {
      this.loadData();
    },
    reload: function () {
      this.loadData();
    },
  },

  methods: {
    /**
     * Подготовка запросов
     */
    getBatchRequests() {
      return {
        user: {
          ENTITY: "users",
          METHOD: "get",
          PARAMS: {
            id: this.id,
          },
        },
        requests_created: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: ["status_id", "cnt"],
            group: ["status_id"],
            order: { "status.sort": "asc" },
            filter: {
              created_by: this.id,
            },
          },
        },
        requests_reponsible: {
          ENTITY: "request",
          METHOD: "statistics",
          PARAMS: {
            select: ["status_id", "cnt"],
            group: ["status_id"],
            order: { "status.sort": "asc" },
            filter: {
              responsible_user_id: this.id,
            },
          },
        },
      };
    },

    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;
      this.user = null;

      //запрашиваем данные
      rest
        .batch(this.getBatchRequests())
        .then((arResults) => {
          //обработка результатов
          this.processResponse(arResults);
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Обработка результата
     */
    processResponse(results) {
      for (var key in results) {
        let data = results[key];

        if (data.success) {
          if (key == "user") {
            this.user = data.user;
          } else if (key == "requests_created") {
            this.requests_created = data.items;
          } else if (key == "requests_reponsible") {
            this.requests_reponsible = data.items;
          }
        } else {
          //показываем сообщение об ошибке
          bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          return;
        }
      }
    },

    onReload() {
      this.loadData();
    },

    /**
     * Открыть фото на просмотр
     */
    openPhoto() {
      Fancybox.show(this.gallery, {
        startIndex: 0,
        infinite: false,
        Toolbar: {
          display: [
            { id: "prev", position: "center" },
            { id: "counter", position: "center" },
            { id: "next", position: "center" },
            "zoom",
            "slideshow",
            "thumbs",
            "close",
          ],
        },
        Hash: false,
        on: {
          ready: (fancybox) => {
            utils.fancyBoxOnReady(fancybox);
          },
          closing: () => {
            utils.fancyBoxOnClose();
          },
        },
      });
    },
  },

  created() {
    //загружаем данные
    this.loadData();
  },
};
</script>

<style lang="scss">
.sbs-user-view {
  .about {
    position: relative;
    padding-left: 170px;
    min-height: 155px;
    margin-bottom: 40px;

    .photo {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 155px;
      height: 155px;
      border: 1px solid var(--color-block-label);

      .picture {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-size: cover;
      }

      .icon {
        font-size: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--color-icon-common);
      }
    }

    .last-name,
    .name {
      font-size: 16px;
      color: var(--color-text-label);
      font-weight: 600;
    }

    .position {
      color: var(--color-block-label);
    }

    .department {
      margin-top: 25px;
      color: var(--color-block-label);
    }
  }

  .contacts {
    margin-bottom: 30px;

    .prop {
      margin-bottom: 10px;
      color: var(--color-block-label);

      a,
      a:hover,
      a:active {
        color: var(--color-block-label);
        text-decoration: none;
        outline: none;
      }

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      .name {
        float: left;
      }

      .value {
        float: right;
      }
    }
  }
}
</style>