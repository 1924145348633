<template>
  <div class="sbs-requests-statistics-cbcd">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  BarElement,
  BarController,
  PieController,
  ArcElement,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(
  BarElement,
  ArcElement,
  BarController,
  PieController,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Legend
);

import bus from "@/plugins/bus";
import { shallowRef } from "vue";

export default {
  name: "sbs-requests-statistics-closed-by-created-departments",
  components: {},
  data() {
    return {
      chart: false,
      colors: [
        "#F1B911",
        "#2DBE85",
        "#D0FF67",
        "#5992F1",
        "#28A3CA",
        "#FDF6C0",
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    stacked() {
      return this.items.filter((i) => i.status_id > 0).length > 0;
    },
  },
  methods: {
    draw() {
      let ctx = this.$refs.canvas;

      this.chart = shallowRef(
        new Chart(ctx, {
          plugins: [ChartDataLabels],
          type: "doughnut",
          data: this.data(),
          options: this.options(),
        })
      );
    },

    refresh() {
      this.chart.options = this.options();
      this.chart.update();
    },

    data() {
      let itemsSorted = this.items.map((i) => i);

      itemsSorted.sort((a, b) => parseFloat(b.cnt) - parseFloat(a.cnt));

      //ограничим кол-во выводимых данных
      let max = 5;
      if (itemsSorted.length > max) {
        let other = itemsSorted.slice(max);
        itemsSorted = itemsSorted.slice(0, max);

        let cnt = 0;
        other.forEach((item) => {
          cnt += parseFloat(item.cnt);
        });

        itemsSorted.push({ created_department_name: "Другие", cnt: cnt });
      }

      let labels = itemsSorted.map((item) => item.created_department_name);

      let data = itemsSorted.map((item) => parseInt(item.cnt));

      return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: data.map((item, index) => this.colors[index]),
          },
        ],
      };
    },

    /**
     * Опции графика
     */
    options() {
      return {
        indexAxis: "y",
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            align: "start",
            labels: {
              color: window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--color-text-label"),
            },
          },

          title: {
            display: false,
          },
        },
      };
    },
  },
  mounted() {
    this.draw();

    //подписываемся на событие завершения смены темы
    bus.on("SBS_APP_E_THEME_CHANGED", this.refresh);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_APP_E_THEME_CHANGED", this.refresh);
  },
};
</script>


<style lang="scss">
.sbs-requests-statistics-cbcd {
  margin-bottom: 30px;
}
</style>