<template>
  <sbs-view :class="'sbs-v-request-view'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:right>
          <!-- Действия в шапке-->
          <template v-if="!loading">
            <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="openActions">
              <span class="icon fi fi-actions"></span>
            </div>
          </template>
        </template>
      </sbs-header>
    </template>
    <template v-slot:content>


      <template v-if="loading">
        <div class="text-center">
          <sbs-loader-inline />
        </div>
      </template>
      <template v-else>
        <!-- Завка не найдена-->
        <div v-if="request == null">Заявка не найдена</div>
        <!-- Заявка -->
        <div v-else>
          <div class="h1">Заявка # {{ request.number }}</div>
          <sbs-request-view :request="request" :checklist="checklist" :permissions="permissions" :history="history"
            :needNfcStart="needNfcStart" :lastViewer="lastViewer" :fields="fields" ref="view" />

          <sbs-request-view-actions v-model:show="showActions" :permissions="permissions" :needNfcStart="needNfcStart"
            :equipmentId="request.equipment_id" :id="request.id" :reportRequired="request.report_required"
            :activeTab="tab" :checklist="checklist" :subscribed="subscribed" />
        </div>
      </template>
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsRequestView from "@/components/requests/view.vue";
import sbsRequestViewActions from "@/components/requests/view.actions.vue";

import bus from "@/plugins/bus";
import store from "@/store";
import rest from "@/plugins/rest";

export default {
  name: "sbs-v-request-view",
  components: {
    sbsView,
    sbsHeader,
    sbsRequestView,
    sbsLoaderInline,
    sbsRequestViewActions,
  },
  data() {
    return {
      tab: this.activeTab,

      loading: true,
      request: null,
      checklist: [],
      permissions: [],
      history: [],
      needNfcStart: false,
      lastViewer: false,
      fields: [],
      subscribed: false,

      showActions: false,
      hideMenu: false
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
    activeTab: {
      type: String,
      default() {
        return "about";
      },
    },
  },
  watch: {
    id: function () {
      this.tab = "about";
      this.loadData();
    },
    activeTab: function (newVal) {
      if (newVal) {
        this.tab = newVal;
      }
    },
  },

  methods: {
    /**
     * Подготовка запросов
     */
    getBatchRequests() {
      return {
        request: {
          ENTITY: "request",
          METHOD: "get",
          PARAMS: {
            id: this.id,
          },
        },
        history: {
          ENTITY: "request",
          METHOD: "history.list",
          PARAMS: {
            request_id: this.id,
          },
        },
        last_viewer: {
          ENTITY: "request",
          METHOD: "viewers.list",
          PARAMS: {
            request_id: this.id,
            paging: {
              size: 1,
            },
          },
        },
      };
    },

    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;
      this.request = null;

      //запрашиваем данные
      rest
        .batch(this.getBatchRequests())
        .then((arResults) => {
          //обработка результатов
          this.processResponse(arResults);
        })
        .catch(() => {
          //в тихом режиме не обрабатваем ошибку
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Обработка результата
     */
    processResponse(results) {
      for (var key in results) {
        let data = results[key];

        if (data.success) {
          //запрос на получение заявки
          if (key === "request") {
            this.request = data.request;
            this.permissions = data.permissions;
            this.checklist = data.checklist;
            this.needNfcStart = data.need_start_by_nfc;
            this.fields = data.fields;
            this.subscribed = data.subscribed;
          }
          //запрос на получение истории
          else if (key === "history") {
            this.history = data.items;
          }
          //запрос на получение последнего просмотревшего
          else if (key === "last_viewer") {
            if (data.items.length > 0) {
              this.lastViewer = data.items[0];
            }
          }
        } else {
          //показываем сообщение об ошибке
          bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          return;
        }
      }
    },

    /**
     * Обработка события сохранения заявки
     */
    onRequestSave(id) {
      if (this.id == id) {
        this.loadData();
      }
    },

    /**
     * Показать действия
     */
    openActions() {
      this.showActions = true;
    },

    onReload() {
      this.loadData();
      this.showActions = false;
    },

    refresh() {
      this.loadData();
      this.showActions = false;
    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
    //загружаем данные
    this.loadData();
  },

  mounted() {
    //подписваемся на событие сохранения заявки
    bus.on("SBS_REQUEST_FORM_E_SAVE", this.onRequestSave);
    //подписваемся на событие необходимости перезагрузки просмотра заявки
    bus.on("SBS_REQUEST_VIEW_E_RELOAD", this.onReload);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_REQUEST_FORM_E_SAVE", this.onRequestSave);
    bus.off("SBS_REQUEST_VIEW_E_RELOAD", this.onReload);
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    //вызываем аналог события у дочернего компонента (чтобы запомнить прокрутку в чате)
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Вызывается перед переходом в этот экран
   */
  beforeRouteEnter(to, from, next) {
    let isGoBack = store.getters.getIsNowGoBack;

    next((vm) => {
      //елси НЕ возвращаемся на этот экран
      if (!isGoBack) {
        //то перезагрузим его
        vm.refresh();
      } else if (vm.$refs.view) {
        //вызываем аналог события у дочернего компонента (чтобы восстановить прокрутку в чате)
        vm.$refs.view.onBeforeRouteEnter();
      }
    });
  },
};
</script>

<style  lang="scss"></style>