<template>
    <div class="sbs-edu-post-test">
        <h1> {{ item.name }}
            <template v-if="step - 1 < questions.length">{{ step }} / {{ questions.length }}</template>
        </h1>

        <div v-if="question" class="question">
            <div class="name title">{{ question.name }}</div>

            <div class="answers " :class="'view-' + question.view">

                <!--Вид - текстовый -->
                <template v-if="question.view == 'text'">
                    <template v-for="answer in question.answers" :key="answer.id">
                        <div class="answer block"
                            :class="{ selected: this.isSelected(answer.id), fail: freeze && isSelected(answer.id) && answer.checked != 'Y', success: freeze && isSelected(answer.id) && answer.checked == 'Y' }"
                            @click="onAnswerClick(answer.id)">{{ answer.value }}</div>
                    </template>
                </template>

                <!--Вид - визуальный -->
                <template v-if="question.view == 'visual'">

                    <template v-for="answer in question.answers" :key="answer.id">

                        <div class="answer" @click="onAnswerClick(answer.id)"
                            :class="{ fail: freeze && isSelected(answer.id) && answer.checked != 'Y', success: freeze && isSelected(answer.id) && answer.checked == 'Y' }">
                            <div class="check" v-if="this.result[this.question.id].includes(answer.id)">
                                <span class="ic fi fi-save-bold"></span>
                            </div>

                            <div class="picture">
                                <div class="image preloaded" v-if="answer.image"
                                    v-lazy:background-image="answer.image.preview">
                                    <div class="preload" v-if="answer.image.preload"
                                        :style="{ backgroundImage: 'url(\'' + answer.image.preload + '\')' }">
                                    </div>

                                    <div class="loader">
                                        <sbs-loader-inline />
                                    </div>
                                </div>
                            </div>
                            <div class="value" v-if="answer.value" v-html="answer.value"></div>
                        </div>

                    </template>

                </template>

            </div>

            <sbs-teleport-wrapper :to="'#' + this.footerId">
                <div class="sbs-edu-post-test-btns">
                    <div class="container">
                        <div class="page-padding">
                            <div class="row">
                                <div class="col-7">

                                </div>
                                <div class="col-5">
                                    <div class="btn btn-block btn1" @click="onNextClick">
                                        {{ $t('common.next') }}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </sbs-teleport-wrapper>
        </div>


        <div v-else-if="step > questions.length" class="result">

            <div class="title">
                {{ $t('education.post.test.result') }}
                -
                <span class="success" v-if="success">
                    {{ $t('education.post.test.success') }}
                </span>
                <span class="fail" v-else>
                    {{ $t('education.post.test.fail') }}
                </span>
            </div>

            <div class="stat">
                <span :class="success ? 'success' : 'fail'">{{ correctQuestions }}</span>
                {{ $t('education.post.test.from') }}
                {{ questions.length }}
            </div>
            <div class="correct">
                {{ $t('education.post.test.correct_answers') }}
            </div>

            <sbs-teleport-wrapper :to="'#' + this.footerId">
                <div class="sbs-edu-post-test-btns">
                    <div class="container">
                        <div class="page-padding">
                            <template v-if="!success">
                                <div class="btn btn-block btn1" @click="reset">
                                    {{ $t('education.post.test.repass') }}
                                </div>
                                <br />
                            </template>

                            <div class="btn btn-block btn2" @click="complete">
                                {{ $t('education.post.test.complete') }}
                            </div>

                        </div>
                    </div>
                </div>
            </sbs-teleport-wrapper>

        </div>
    </div>
</template>

<script>
import sbsTeleportWrapper from "@/components/teleport-wrapper.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "sbs-education-post-test",
    components: {
        sbsTeleportWrapper,
        sbsLoaderInline
    },
    emits: ["complete"],
    data() {
        return {
            step: 1,
            freeze: false,
            result: {

            }
        };
    },

    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        trainingId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        footerId: {
            type: String,
            default() {
                return ""
            }
        }
    },

    computed: {

        questions() {
            if (!this.item.data || !this.item.data.questions) {
                return [];
            }

            return this.item.data.questions;
        },

        question() {
            if (this.step - 1 < this.questions.length) {
                return this.questions[this.step - 1];
            }

            return false;
        },

        /**
         * Кол-во вопросов, пройденных успешно
         */
        correctQuestions() {
            let count = 0;

            //для каждого вопроса
            this.questions.forEach(question => {

                //получим список ИД правильных вариантов
                let correctIds = question.answers.filter(a => a.checked == 'Y').map(a => a.id);

                //если кол-во правильных вариантов не сопадает с кол-вом выбранных вариантов
                if (this.result[question.id].length != correctIds.length) {
                    return;
                }

                //проверим выбраны ли нужные варианты
                let same = true;
                correctIds.forEach(id => {
                    if (!this.result[question.id].includes(id)) {
                        same = false;
                    }
                });
                if (same) {
                    count++;
                }
            });

            return count;
        },

        success() {
            return this.correctQuestions == this.questions.length;
        }
    },
    watch: {
        step: function () {
            //инициализируем данные результата для шага
            this.initStep();
            //если последний шаг
            if (this.step > this.questions.length) {
                //сохраняем результат
                this.save();
            }
        },
    },

    methods: {

        /**
         * Инициализирует в результате место под ответ на вопрос
         */
        initStep() {
            if (!this.question) {
                return;
            }

            if (!this.result[this.question.id]) {
                this.result[this.question.id] = [];
            }
        },

        /**
         * клик по ответу
         */
        onAnswerClick(id) {

            //если выбор вариантов зафиксирован
            if (this.freeze) {
                return;
            }

            //если ответ уже был выбран
            if (this.isSelected(id)) {
                //то удалим его
                var index = this.result[this.question.id].indexOf(id);
                if (index !== -1) {
                    this.result[this.question.id].splice(index, 1);
                }
            } else {
                //иначе добавим его
                this.result[this.question.id].push(id);
            }


        },

        /**
         * Переход к следующему вопросу
         */
        onNextClick() {
            if (this.result[this.question.id].length == 0) {
                bus.emit("SBS_MESSAGE_E_SHOW", { message: this.$t('education.post.test.answer_not_select') });
                return;
            }

            //если выбор вариантов ещё не зафиксирован
            if (!this.freeze) {
                //фиксируем, чтобы показать ошибки
                this.freeze = true;
                return;
            }

            this.step++;
            this.freeze = false;

        },

        /**
         * Проверяет выбран ли ответ
         */
        isSelected(id) {
            return this.result[this.question.id].includes(id);
        },

        /**
         * Пересдать
         */
        reset() {
            this.result = {},
                this.step = 1;
        },

        /**
         * Завершить
         */
        complete() {

            if (this.trainingId > 0 && this.success) {

                this.$emit("complete");

            } else {

                window.history.length > 1
                    ? this.$router.go(-1)
                    : this.$router.push("/");
            }


        },

        /**
         * Сохранение результата
         */
        save() {

            let fields = {
                id: this.item.id,
                training_id: this.trainingId,
                status: this.success ? "S" : "F",
                data: this.result
            };
            //запрашиваем данные
            rest
                .call("education.post.result", {
                    method: "post",
                    data: fields,
                })
                .then((data) => {
                    if (!data.success) {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    } else {
                        //генерим событие успешного сохранения результата
                        bus.emit("SBS_EDU_POST_RESULT_E_SAVE", fields);
                    }
                });
        }

    },

    created() {
        this.initStep();
    }
};
</script>

<style lang="scss">
.sbs-edu-post-test {

    .title {
        color: var(--color-text-primary);
        text-transform: none;
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .question {


        .answers {
            &.view-text {

                .answer {
                    cursor: pointer;

                    &.selected {
                        background-color: var(--color-btn1-background);
                        color: var(--color-btn1-text);

                        &.fail {
                            color: var(--color-message-error-text);
                            background-color: var(--color-message-error-background);
                        }

                        &.success {
                            background-color: var(--color-success);
                        }
                    }
                }
            }

            &.view-visual {
                display: grid;
                grid-template-columns: 0.5fr 0.5fr;
                gap: 15px;

                @media (min-width: 768px) {
                    grid-template-columns: 0.33fr 0.33fr 0.33fr;
                }

                .answer {
                    position: relative;
                    cursor: pointer;

                    .picture {
                        position: relative;
                        height: 0px;
                        padding-top: 100%;
                        background-color: var(--color-block-background);
                        border-radius: 8px;
                        overflow: hidden;

                        .image {
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            bottom: 0px;
                            left: 0px;
                            background-position: center center;
                            background-size: contain;
                            background-repeat: no-repeat;
                        }
                    }

                    .value {
                        margin-top: 5px;
                        text-align: center;
                    }

                    .check {
                        position: absolute;
                        z-index: 5;
                        top: 10px;
                        left: 10px;
                        color: var(--color-color1);

                        border-radius: 50%;
                        background-color: var(--color-btn1-background);
                        width: 24px;
                        height: 24px;

                        .ic {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: var(--color-btn1-text);
                        }
                    }

                    &.fail {
                        .check {
                            color: var(--color-message-error-text);
                            background-color: var(--color-message-error-background);
                        }
                    }

                    &.success {
                        .check {
                            background-color: var(--color-success);
                        }
                    }
                }
            }
        }


    }

    .result {
        text-align: center;

        .success {
            color: var(--color-success);
        }

        .fail {
            color: var(--color-error);
        }

        .stat {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.sbs-edu-post-test-btns {
    padding: 15px 0px;

}
</style>