<template>
    <div class="sbs-rt-ff">
        <sbs-control-input v-model="fields.name" type="text" label="Название" :required="true"
            :noLabelAnimation="setFieldsFilled">
            <template v-if="isNativePlatform" v-slot:icon>
                <div class="icon" @click="speechName">
                    <span class="fi fi-mic"></span>
                </div>
            </template>
        </sbs-control-input>

        <sbs-control-textarea v-model="fields.description" label="Описание" :required="false" :rows="5"
            :noLabelAnimation="setFieldsFilled">
            <template v-if="isNativePlatform" v-slot:icon>
                <div class="icon" @click="speechDescription">
                    <span class="fi fi-mic"></span>
                </div>
            </template>
        </sbs-control-textarea>

        <div class="equipment-wrap">
            <span class="qr fi fi-qr-scanner" @click="onQrClick"></span>
            <span class="nfc fi fi-nfc" @click="onNfcClick"></span>

            <sbs-control-select v-model="fields.equipment_id" v-model:modelName="info.equipment_name" label="Оборудование"
                :required="false" name="equipment_id" method="equipment.list" :noLabelAnimation="setFieldsFilled" />
        </div>

        <sbs-control-select v-model="fields.responsible_department_id" v-model:modelName="info.responsible_department_name"
            label="Подразделение - исполнитель" :required="true" name="responsible_department_id" method="department.list"
            :params="{
                filter: [
                    {
                        LOGIC: 'OR',
                        active: 'Y',
                        id: this.fields.responsible_department_id,
                    },
                ],
                forResponsible: 'Y',
            }" :multiple="false" :noLabelAnimation="setFieldsFilled" />

        <sbs-control-select v-model="fields.type_id" v-model:modelName="info.type_name" label="Тип заявки" :required="true"
            name="type_id" method="dictionary.request.type.list" :noLabelAnimation="setFieldsFilled" :params="{
                filter: [
                    {
                        LOGIC: 'OR',
                        ACTIVE: 'Y',
                        ID: this.fields.type_id,
                    },
                ],
                forRequestCreate: 'Y',
            }" />

        <sbs-control-select v-model="fields.priority_id" v-model:modelName="info.priority_name" label="Приоритет"
            :required="true" name="type_id" method="dictionary.request.priorities.list"
            :noLabelAnimation="setFieldsFilled" />

        <sbs-control-checkbox v-model="fields.report_required" value="Y" label="Необходим отчёт при завершении" />

        <div class="collapsable" :class="{ expanded: timePlannigExpanded }">
            <div class="clickable" @click="onTimePlanningClick">
                <h2>
                    <span class="label">
                        Планирование сроков
                    </span>
                </h2>
                <div class="collapser">
                    <span class="fi fi-arrow-down"></span>
                </div>
            </div>

            <div class="collapsable-content">
                <sbs-control-time-duration v-model="fields.start_date_plan_after" label="Начать заявку через" />
                <sbs-control-time-duration v-model="fields.end_date_plan_after" label="Завершить заявку через" />
                <sbs-control-time v-model="fields.duration_plan" label="Длительность" />
            </div>

        </div>




    </div>
</template>

<script>
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlTextarea from "@/components/controls/textarea.vue";
import sbsControlSelect from "@/components/controls/select.vue";
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import sbsControlTimeDuration from "@/components/controls/time.duration.vue";
import sbsControlTime from "@/components/controls/time.vue";

import bus from "@/plugins/bus";
import { mapGetters } from "vuex";

export default {
    name: "sbs-request-template-form-fields",
    components: {
        sbsControlInput,
        sbsControlTextarea,
        sbsControlSelect,
        sbsControlCheckbox,
        sbsControlTimeDuration,
        sbsControlTime
    },
    emits: ["update:modelValue", "update:modelNames"],
    data() {
        return {
            fields: this.modelValue,
            info: this.modelNames,
            setFieldsFilled: false,
            timePlannigExpanded: false
        };
    },
    props: {
        modelValue: {
            type: Object,
            default() {
                return {};
            },
        },
        modelNames: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        ...mapGetters({
            isNativePlatform: "isNativePlatform",
        }),
    },
    watch: {
        modelValue: function () {
            this.fields = this.modelValue;
        },
        fields: {
            handler() {
                this.$emit("update:modelValue", this.fields);
            },
            deep: true,
        },
        modelNames: function () {
            this.info = this.modelNames;
        },
        info: {
            handler() {
                this.$emit("update:modelNames", this.info);
            },
            deep: true,
        },


    },

    methods: {

        /**
         * Распознование речи для навания
         */
        speechName() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.fields.name = text;
                },
            });
        },

        /**
         * Распознование речи для описания
         */
        speechDescription() {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.fields.description = text;
                },
            });
        },

        /**
         * Клик по иконке NFC
         */
        onNfcClick() {
            let vm = this;
            bus.emit("SBS_NFC_SCANNER_E_OPEN", {
                resolve: function (item) {
                    vm.fields.equipment_id = item.id;
                    vm.info.equipment_name = item.name;
                },
            });
        },

        /**
         * Клик по иконке QR
         */
        onQrClick() {
            let vm = this;
            bus.emit("SBS_QR_SCANNER_E_OPEN", {
                resolve: function (item) {
                    vm.fields.equipment_id = item.id;
                    vm.info.equipment_name = item.name;
                },
            });
        },

        /**
         * Клик по раскрывашке "Планирование времени"
         */
        onTimePlanningClick() {
            this.timePlannigExpanded = !this.timePlannigExpanded;
        }
    }

}

</script>

<style lang="scss">
.sbs-rt-ff {



    .equipment-wrap {
        position: relative;

        .nfc {
            position: absolute;
            top: -32px;
            right: 0px;
            font-size: 20px;
            padding: 5px;
        }

        .qr {
            position: absolute;
            top: -32px;
            right: 30px;
            font-size: 20px;
            padding: 5px;
        }
    }

    .collapsable {
        margin-bottom: 25px;

        .clickable {
            position: relative;
            padding-right: 50px;

            .label {
                border-bottom: 1px dashed currentColor;
            }

            .collapser {
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
                color: var(--color-color1);
                line-height: 1;
                padding: 10px;
                font-size: 14px;
                transition: transform 0.3s ease-in-out;
            }
        }

        .collapsable-content {
            overflow: hidden;
            max-height: 0px;
            padding-top: 0px;
            transition: max-height 0.3s ease-in-out, padding-top 0.3s ease-in-out;
        }

        &.expanded {

            .clickable {
                .collapser {
                    transform: translateY(-50%) rotate(180deg);
                }
            }


            .collapsable-content {
                max-height: 500px;
                padding-top: 20px;
            }
        }
    }


}
</style>