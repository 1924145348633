<template>
  <div class="sbs-header">
    <div class="container h-100">
      <div class="page-padding h-100">
        <div class="grid h-100">
          <div>

            <div v-if="canShowBackBtn" @click="onBtnBackClick" class="btn-back">
              <span class="icon fi fi-arrow-left"></span>
              <template v-if="showBackBtnText">Назад</template>

            </div>

          </div>
          <div class="col-center">
            <slot name="center">
            </slot>
          </div>

          <div class="col-right">
            <slot name="right"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sbs-header",
  props: {
    showBackBtn: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showBackBtnText: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  computed: {
    canShowBackBtn() {
      return window.history.length > 1 && this.showBackBtn;
    },
  },
  methods: {
    onBtnBackClick() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push("requests");
    },
  },
};
</script>

<style lang="scss">
.sbs-header {
  height: 55px;
  display: block;

  background-color: var(--color-background);

  line-height: 1;
  z-index: 100;

  .h-100 {
    height: 100%;
    align-items: center;
  }

  .grid {
    display: grid;
    grid-template-columns: auto 1fr auto;
    margin-right: -10px;
    margin-left: -10px;
    column-gap: 15px;
  }

  .col-center {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-back {
    display: inline-block;
    color: var(--color-text-label);
    font-size: 16px;

    padding: 5px 0px 5px 35px;
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-size: 20px;
      color: var(--color-icon-active);
    }
  }

  .col-right {
    white-space: nowrap;


    .action {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        color: var(--color-icon-active);
      }

      &.on {
        background-color: var(--color-btn1-background);

        .icon {
          color: var(--color-btn1-text);
        }
      }
    }
  }
}
</style>