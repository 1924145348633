<template>
  <div :class="[form ? 'form-group' : '']">
    <div class="sbs-checkbox" :class="{ checked: checked, nolabel: label == '' }" @click.stop="true">
      <label>
        <div class="icon fi fi-checkbox-on icon-on"></div>
        <div class="icon fi fi-checkbox-off icon-off"></div>

        <input type="checkbox" :class="required ? 'required' : ''" :name="name" :value="value" v-model="checked"
          @change="onChange" />

        <template v-if="label != ''">
          <div class="label">
            {{ label }}
          </div>
        </template>
        <template v-else> &nbsp; </template>
      </label>
    </div>
  </div>
</template>

<script>
import { toRaw } from "vue";

export default {
  name: "sbs-control-checkbox",
  data() {
    return {
      checked: !this.multiple
        ? this.modelValue == this.value
        : this.values.includes(this.value),
    };
  },
  emits: ["update:modelValue", "update:values", "change"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
    value: {
      type: [String, Number, Boolean],
      default() {
        return "";
      },
    },
    defaultValue: {
      type: [String, Number, Boolean],
      default() {
        return "";
      },
    },
    modelValue: {
      type: [String, Number, Boolean],
      default() {
        return "";
      },
    },
    values: {
      type: Array,
      default() {
        return [];
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    form: {
      type: Boolean,
      default() {
        return true;
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    modelValue: function (newVal) {
      this.checked = newVal == this.value;
    },
    values: function (newVal) {
      this.checked = newVal.includes(this.value);
    },
    checked: function () {

      if (!this.multiple) {
        this.$emit("update:modelValue", this.checked ? this.value : this.defaultValue);
      } else {
        //берём реальный массив текущих значений
        let values = toRaw(this.values);
        //если данный вариант выбран
        if (this.checked) {
          //если значения варианта ещё нет в списке
          if (!values.includes(this.value)) {
            //доабвим его
            values.push(this.value);
            //создаём новый массив, чтобы вочер смог отследить
            values = values.filter(() => true);
          }
        }
        //иначе вариант не выбран
        else {
          //если значение варианта в списке
          if (values.includes(this.value)) {
            //то удалим его
            values = values.filter((val) => val !== this.value);
          }
        }
        this.$emit("update:values", values);
      }
    },
  },
  methods: {
    onChange() {
      this.$emit("change", this.value);
    }
  },
};
</script>

<style lang="scss">
.sbs-checkbox {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin: 5px 0px;

  &.nolabel {
    width: 20px;
    height: 20px;
    padding-left: 0px;
  }

  input {
    display: none;
  }

  label {
    margin-bottom: 0px;
    cursor: pointer;
    font-weight: normal;
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: auto !important;
    padding: 0px !important;

    font-size: 20px;

    -webkit-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .icon-on {
    color: var(--color-radio-on);
  }

  .icon-off {
    color: var(--color-radio-off);
  }

  .icon-on {
    opacity: 0;
  }

  &.checked .icon-on {
    opacity: 1;
  }

  &.checked .icon-off {
    opacity: 0;
  }
}
</style>