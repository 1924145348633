<template>
  <div class="form-group form-group-label" :class="[
    focused ? 'focused' : '',
    value ? 'filled' : '',
    noLabelAnimation && value ? 'no-label-animation' : '',
    !!this.$slots.icon ? 'form-group-icon' : '',
  ]">
    <template v-if="label != ''">
      <div class="floating-label" v-on:click="onLabelClick">
        {{ label }}
        <span v-if="required && needShowRequired">*</span>
      </div>
    </template>
    <input class="form-control" :type="type" :name="name" :class="cssClasses" v-model="value" v-maska:[mask]
      @maska="onMaska" :readonly="readonly" @input="handleInput" @focus="onFocus" @blur="onBlur" ref="input"
      autocomplete="off" :placeholder="placeholder" @change="onChange" :maxlength="maxlength" />

    <slot name="icon"></slot>

    <img v-if="captcha" :src="captcha" class="captcha" />

    <div v-if="description" v-html="description" class="description"></div>

    <slot name="bottom"></slot>
  </div>
</template>

<script>

import { vMaska } from "maska"
import { mapGetters } from "vuex";

export default {
  name: "sbs-control-input",
  directives: { maska: vMaska },
  data() {
    return {
      value: this.modelValue,
      focused: false,
      maskCompleted: false,
    };
  },
  emits: ["update:modelValue", "change"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },
    type: {
      type: String,
      default() {
        return "text";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
    modelValue: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    captcha: {
      type: String,
      default() {
        return "";
      },
    },
    mask: {
      type: [Object, Boolean],
      default() {
        return null;
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showRequired: {
      type: Boolean,
      default() {
        return true;
      },
    },
    description: {
      type: String,
      default() {
        return "";
      },
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    placeholder: {
      type: String,
      default() {
        return "";
      },
    },
    classes: {
      type: Array,
      default() {
        return [];
      },
    },
    noLabelAnimation: {
      type: Boolean,
      default() {
        return false;
      },
    },
    maxlength: {
      type: [String, Number],
      default() {
        return "";
      },
    }
  },
  computed: {
    ...mapGetters({
      isNative: "isNativePlatform",
    }),
    needShowRequired() {
      return this.required && this.showRequired;
    },

    cssClasses() {
      //копирем массив, чтобы не влияеть на св-во
      let classes = this.classes.slice();
      if (this.required) {
        classes.push("required");
      }

      if (this.mask) {
        classes.push("mask");

        if (this.maskCompleted) {
          classes.push("completed");
        }
      }

      return classes;

    }

  },
  watch: {
    modelValue: function (newVal) {
      if (newVal !== this.value) {
        this.value = newVal;
      }
    },
  },
  methods: {
    handleInput() {
      this.$emit("update:modelValue", this.value);
    },
    onFocus() {
      this.focused = true;
      if (this.isNative) {
        setTimeout(() => {
          this.$refs.input.scrollIntoView(false);
        }, 10);
      }

    },
    onBlur() {
      this.focused = false;
    },
    onLabelClick() {
      this.focused = true;
      this.$refs.input.focus();

    },
    onChange() {
      this.$emit("change", this.value);
    },
    onMaska(event) {
      this.maskCompleted = event.detail.completed;
    }
  },
};
</script>

<style lang="scss"></style>
