<template>
  <div class="sbs-rre">

    <sbs-control-photos v-model="photos" label="Фото результата" :class="{ 'form-group-error': photoError }"
      :required="true" />

    <sbs-control-textarea v-model="comment" label="Комментарий" :required="true" :rows="5">
      <template v-if="isNativePlatform" v-slot:icon>
        <div class="icon" @click="speechComment">
          <span class="fi fi-mic"></span>
        </div>
      </template>
    </sbs-control-textarea>

    <sbs-teleport-wrapper to="#sbs-rrevb">
      <div class="sbs-rre-btns">
        <div class="container">
          <div class="page-padding">
            <div class="btn btn-block btn1" :class="saving ? 'wait' : ''" v-ripple.300 @click="save">
              Сохранить и завершить
              <div v-if="saving" class="btn-loader"></div>
            </div>
          </div>
        </div>
      </div>
    </sbs-teleport-wrapper>
  </div>
</template>

<script>
import sbsControlPhotos from "@/components/controls/photos.vue";
import sbsControlTextarea from "@/components/controls/textarea.vue";
import sbsTeleportWrapper from "@/components/teleport-wrapper.vue";

import validator from "@/plugins/validator";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import { mapGetters } from "vuex";

export default {
  name: "sbs-request-report-exec",
  components: {
    sbsControlPhotos,
    sbsControlTextarea,
    sbsTeleportWrapper,
  },
  data() {
    return {
      photos: [],
      photoError: false,
      comment: "",
      saving: false,
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters({
      isNativePlatform: "isNativePlatform",
    }),
  },

  watch: {
    id: function () {
      this.clear();
    },
    reload: function () {
      this.clear();
    },
    photos: {
      handler() {
        if (this.photos.length > 0) {
          this.photoError = false;
        }
      },
      deep: true,
    },
  },

  methods: {
    /**
     * Очищает форму
     */
    clear() {
      this.photos = [];
      this.comment = "";
    },

    /**
     * Сохранение
     */
    save() {
      if (!this.check()) {
        return;
      }

      this.complete();
    },

    /**
     * Проверки
     */
    check() {
      if (this.photos.length == 0) {
        this.photoError = true;
      }

      //проверка введённых данных
      if (!validator.check({ container: this.$el }) || this.photoError) {
        return false;
      }

      return true;
    },

    /**
     * Завершить
     */
    complete() {
      this.saving = true;

      let photoData = this.photos.map((p) => p.data);

      //выполняем запрос
      rest
        .call("request.complete", {
          method: "post",
          data: {
            id: this.id,
            photos: photoData,
            comment: this.comment,
          },
        })
        .then((data) => {
          if (data.success) {
            bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
            bus.emit("SBS_REQUEST_E_UPDATED", this.id);
            //переход обратно
            this.$router.go(-1);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },

    /**
     * Распознование речи для описания
     */
    speechComment() {
      bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
        resolve: (text) => {
          this.comment = text;
        },
      });
    },
  },
};
</script>


<style lang="scss">
.sbs-rre-btns {
  padding: 15px 0px;
  z-index: 1000;
  background-color: var(--color-background);
}
</style>