<template>
    <sbs-view :class="'sbs-v-edu-training-view'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
        <template v-slot:header>
            <sbs-header />
        </template>

        <template v-slot:content>

            <template v-if="showLoading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>

            <template v-else>

                <template v-if="!training">
                    <div>{{ $t("education.training.not_found") }}</div>
                </template>

                <template v-else>

                    <div class="picture-wrap" v-if="training.picture">
                        <div class="picture preloaded" v-lazy:background-image="training.picture.preview"
                            @click="openPhoto(training.picture.id)">

                            <div class="preload" v-if="training.picture.preload"
                                :style="{ backgroundImage: 'url(\'' + training.picture.preload + '\')' }">
                            </div>

                            <div class="loader">
                                <sbs-loader-inline />
                            </div>
                        </div>
                    </div>

                    <h1 v-html="training.name"></h1>

                    <div class="description" v-html="training.description"></div>
                </template>

            </template>

        </template>

        <template v-slot:footer v-if="training">

            <div class="btn-wrap">

                <div class="container">
                    <div class="btn btn1 b btn-block" :class="saving ? 'wait' : ''" v-ripple.300 @click="start">
                        {{ $t("education.training.start") }}
                        <div v-if="saving" class="btn-loader"></div>
                    </div>
                </div>
            </div>

        </template>
    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import utils from "@/plugins/utils";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
    name: "sbs-v-education-training-view",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline
    },
    data() {
        return {
            hideMenu: false,
            loading: true,
            showLoading: true,
            training: null,
            saving: false
        };
    },
    props: {
        id: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    computed: {

        gallery() {
            let images = [];

            if (this.training && this.training.picture) {
                images.push({
                    src: this.training.picture.full ? this.training.picture.full : this.training.picture.src,
                    type: "image",
                    width: this.training.picture.width,
                    height: this.training.picture.height,
                    thumb: this.training.picture.preview ? this.training.picture.preview : this.training.picture.src,
                    caption: this.training.name,
                    id: this.training.picture.id,
                });
            }

            return images;
        },

    },
    watch: {
        id: function () {
            this.loadData();
        }
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            //покажем экран загрузки
            this.showLoading = resolve ? false : true;
            this.loading = true

            //запрашиваем данные
            rest
                .call("education.training.get", {
                    method: "post",
                    data: {
                        id: this.id,
                        resize_options: {
                            PREVIEW: { WIDTH: 800, HEIGHT: 600 },
                            PRELOAD: "Y"
                        },
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.training = data.training;
                        this.hit();
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    if (resolve) {
                        resolve();
                    }
                    //скроем экран загрузки
                    this.loading = false;
                    this.showLoading = false;
                });
        },

        /**
         * Перезагрузка данных
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Открыть фото на просмотр
         */
        openPhoto(id) {
            let start = this.gallery.findIndex((i) => i.id == id);

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 0, //иначе видео mp4 слева/справа начинает сразу воспроизводиться
                infinite: false,
                Toolbar: {
                    display: [
                        { id: "prev", position: "center" },
                        { id: "counter", position: "center" },
                        { id: "next", position: "center" },
                        "zoom",
                        "slideshow",
                        "thumbs",
                        "close",
                    ],
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        utils.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        utils.fancyBoxOnClose();
                    },
                },
            });
        },

        /**
         * Фиксация просмотра
         */
        hit() {
            rest
                .call("education.training.hit", {
                    method: "post",
                    data: {
                        id: this.training.id
                    },
                });

        },

        /**
         * Перейти к постам тренировки
         */
        async start() {

            if (this.saving) {
                return;
            }
            this.saving = true;

            //фиксируем факт начала тренировки
            let data = await rest.call("education.training.start", {
                method: "post",
                data: {
                    id: this.training.id
                },
            });
            this.saving = false;

            if (!data.success) {
                //показываем сообщение об ошибке
                bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                return;
            }

            bus.emit("SBS_EDU_TRAINING_RESULT_E_SAVE", { trainingId: this.training.id });

            this.$router.replace({
                name: "education-training-posts",
                params: { id: this.training.id },
            });

        }

    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.loadData();
    },
}
</script>

<style lang="scss">
.sbs-v-edu-training-view {

    h1 {
        color: var(--color-text-primary);
        text-transform: none;
        font-size: 21px;
        font-weight: 500;
    }

    @media (max-width: 767px) {
        .picture-wrap {
            margin: 0px -20px;
        }

    }

    .picture {
        height: 0px;
        padding-top: 60%;
        background-size: cover;
        background-position: center center;
        margin-bottom: 10px;
        background-color: var(--color-block-background);
    }

    .btn-wrap {
        padding: 15px 0px;
    }
}
</style>