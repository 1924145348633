<template>
  <sbs-view :class="['sbs-v-qr-scanner', scanerShown ? 'scan' : '']" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header />
    </template>
    <template v-slot:content>
      <div v-if="scanerShown" class="qrscanner">
        <div class="qrscanner-area"></div>
        <div class="buttons">
          <div class="btn-light" @click="lightSwitch">
            <span class="icon fi fi-light"></span>
          </div>
          <div class="btn-camera" @click="cameraSwitch">
            <span class="icon fi fi-change-camera"></span>
          </div>
        </div>
      </div>

      <div v-if="!scanerShown" class="container">
        <div class="page-padding">
          <template v-if="loading">
            <div class="error" v-if="error != ''">
              {{ error }}
            </div>
            <div class="text-center">
              <sbs-loader-inline />
            </div>
          </template>
        </div>
      </div>
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";

export default {
  name: "sbs-v-qr-scanner",
  components: {
    sbsView,
    sbsHeader,
    sbsLoaderInline,
  },
  data() {
    return {
      scanerShown: false,
      loading: false,
      resolve: null,
      mode: "EQUIPMENT",
      hideMenu: false
    };
  },

  methods: {
    /**
     * Обоаботка готовности сканера
     */
    onReady(params) {
      this.scanerShown = true;
      this.resolve = params.resolve;

      //режим
      if (params.mode) {
        this.mode = params.mode;
      } else {
        this.mode = "EQUIPMENT"
      }

    },

    /**
     * Обработка результата сканирования
     */
    onScan(text) {
      //выключим сканер
      bus.emit("SBS_QR_SCANNER_E_CLOSE");
      this.scanerShown = false;

      if (this.mode == "EQUIPMENT") {
        this.searchEquipment(text);
      } else if (this.mode == "SIMPLE") {
        this.resolve(text);
        this.$router.go(-1);
      }

    },

    /**
     * Включение/выключение фонарика
     */
    lightSwitch() {
      bus.emit("SBS_QR_SCANNER_E_SWITCH_LIGHT");
    },

    /**
     * Переключение камеры
     */
    cameraSwitch() {
      bus.emit("SBS_QR_SCANNER_E_SWITCH_CAMERA");
    },

    searchEquipment(text) {
      this.loading = true;

      //запрашиваем данные
      rest
        .call("equipment.list", {
          method: "post",
          data: {
            filter: {
              PROPERTY_QR_CODE: text,
            },
          },
        })
        .then((data) => {
          if (data.success) {
            if (data.items.length > 0) {
              let item = data.items[0];
              this.resolve(item);
            } else {
              bus.emit("SBS_MESSAGE_E_SHOW", {
                message: "По данному QR оборудование не найдено",
              });
            }
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }

          this.$router.go(-1);
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  mounted() {
    //подписваемся на событие сканирования
    bus.on("SBS_QR_SCANNER_E_SCAN", this.onScan);

    //подписваемся на событие сканирования
    bus.on("SBS_QR_SCANNER_E_READY", this.onReady);
  },

  beforeRouteLeave() {
    if (this.scanerShown) {
      //выключим сканер
      bus.emit("SBS_QR_SCANNER_E_CLOSE");
      this.scanerShown = false;
    }
  },
};
</script>

<style lang="scss">
.sbs-v-qr-scanner {
  &.scan {
    background: transparent;
  }

  .qrscanner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;

    .qrscanner-area {
      position: relative;
      top: 50%;
      width: 50%;
      margin: 0px auto;
      transform: translateY(-50%);

      background: transparent url("../assets/images/scanner.svg") no-repeat no-repeat center;
      background-size: cover;

      &::before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }

    .buttons {
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;

      div {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        margin: 0px 40px;
        background-color: var(--color-block-background);

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 20px;
          color: var(--color-block-text);
        }
      }
    }
  }

  .error {
    font-size: 16px;
    color: var(--color-error);
    text-align: center;
    margin-bottom: 30px;
  }
}
</style>