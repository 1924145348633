import bus from '@/plugins/bus'
import rest from "@/plugins/rest";

import { CapacitorBarcodeScanner,CapacitorBarcodeScannerTypeHint } from '@capacitor/barcode-scanner';

export default {

    arParams: {},

    init() {
        //подписываемся на событие вызова сканера
        bus.on('SBS_QR_SCANNER_E_OPEN', (params) => {
            this.open(params);
        });

    },

    /**
     * Открывает экран QR сканера
     */
    async open(params) {
        this.arParams = params;


        CapacitorBarcodeScanner.scanBarcode({
            options: {
                hint: CapacitorBarcodeScannerTypeHint.ALL
            }
        }).then((result) => {

            let mode = this.arParams.mode ? this.arParams.mode : "EQUIPMENT";

            if(mode == "EQUIPMENT") {
                this.searchEquipment(result.ScanResult);
            } else {
                this.arParams.resolve(result.ScanResult);
            }

        });
    },

    searchEquipment(text) {

      //запрашиваем данные
      rest
        .call("equipment.list", {
          method: "post",
          data: {
            filter: {
              PROPERTY_QR_CODE: text,
            },
          },
        })
        .then((data) => {
          if (data.success) {
            if (data.items.length > 0) {
              let item = data.items[0];
              this.arParams.resolve(item);
            } else {
              bus.emit("SBS_MESSAGE_E_SHOW", {
                message: "По данному QR оборудование не найдено",
              });
            }
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }

        });
    },
}