<template>
  <div class="sbs-confirm" :class="{ show: this.visible }">
    <div class="inner">
      <div class="container">
        <div class="message" v-html="message"></div>

        <div class="btns-wrap">
          <div
            class="btn btn-block btn1"
            @click="onResolve()"
            v-html="resolveText"
          ></div>
          <div
            class="btn btn-block btn2"
            @click="onReject()"
            v-html="rejectText"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/plugins/bus";

export default {
  name: "sbs-confirm",
  data() {
    return {
      message: "",
      resolveText: "",
      rejectText: "",
      visible: false,
      reverse: false,
    };
  },
  methods: {
    show(params) {
      this.message = params.message;
      this.resolveText = params.resolveText;
      this.rejectText = params.rejectText;
      this.resolve = params.resolve;
      this.visible = true;
      this.reverse = params.reverse === true ? true : false;
    },

    hide() {
      this.visible = false;
    },

    onResolve() {
      this.hide();
      this.resolve(this.reverse ? false : true);
    },
    onReject() {
      this.hide();
      this.resolve(this.reverse ? true : false);
    },
  },
  mounted() {
    //подписваемся на событие показа сообщения
    bus.on("SBS_CONFIRM_E_SHOW", this.show);
  },
  unmounted() {
    //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
    bus.off("SBS_CONFIRM_E_SHOW", this.show);
  },
};
</script>

<style lang="scss">
.sbs-confirm {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2000;

  display: none;

  backdrop-filter: blur(15px);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    background: var(--color-background);
    opacity: 0.85;
  }

  .inner {
    position: absolute;
    top: 50%;
    right: 0px;
    left: 0px;
    z-index: 2;
    padding: 0px 10px;

    transform: translateY(-50%);
  }

  &.show {
    display: block;
  }

  .message {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
  }

  .btns-wrap {
    margin-top: 30px;
  }

  .btn {
    margin-bottom: 20px;
  }
}
</style>