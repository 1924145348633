<template>
  <div class="sbs-request-view-checklist">
    <div class="progress">
      <div class="value" :class="{ completed: percent == 100 }">
        {{ percent }}%
      </div>
      <div class="bg" :style="{ width: percent + '%' }"></div>
    </div>

    <div v-for="item in items" :key="item.id" class="item" :class="{ expanded: !!item.expanded }">
      <div class="top" @click="expand(item)">
        <div class="status">
          <span v-if="item.status == 'N'" class="fi fi-checkbox-off"></span>
          <span v-if="item.status == 'F'" class="fi fi-checkbox-on"></span>
          <span v-if="item.status == 'C'" class="fi fi-close"></span>
        </div>
        <div class="name">
          {{ item.name }}
        </div>
        <div class="collapser">
          <span class="fi fi-arrow-down"></span>
        </div>
      </div>
      <div class="info">
        <div v-if="item.type == 'NUMBER'" class="prop">
          <div class="label">Значение</div>
          <div class="value">{{ item.value }}</div>
        </div>
        <div v-if="item.type == 'TEXT'" class="prop">
          <div class="label">Комментарий</div>
          <div class="value">{{ item.value }}</div>
        </div>
        <div v-if="item.type == 'PHOTO'" class="prop">
          <div class="label">Фото</div>
          <sbs-photo-swiper :photos="item.value" />
        </div>
        <div v-if="item.type == 'CHECKBOX'" class="prop">
          <div class="value" v-if="item.value == 'Y'">Да</div>
          <div class="value" v-else-if="item.value == 'N'">Нет</div>
        </div>
      </div>
    </div>

    <div v-if="permissions.includes('checklist')" class="btn btn1 btn-block btn-start" @click="execute">
      Начать выполнение
    </div>
  </div>
</template>

<script>
import sbsPhotoSwiper from "@/components/photo.swiper.vue";

export default {
  name: "sbs-request-view-checklist",
  components: {
    sbsPhotoSwiper,
  },
  props: {
    id: {
      type: [Number, String],
      default() {
        return 0;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    permissions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    percent() {
      let length = this.items.length;
      if (length == 0) {
        return 0;
      }
      return Math.round(
        (this.items.filter((i) => i.status != "N").length / length) * 100
      );
    },
  },
  methods: {
    execute() {
      this.$router.push({
        name: "request-checklist-execute",
        params: { id: this.id },
      });
    },

    /**
     * Раскрывает/закрывает пункт
     */
    expand(item) {
      item.expanded = !item.expanded;
    },
  },
};
</script>

<style lang="scss">
.sbs-request-view-checklist {
  .progress {
    position: relative;
    height: 20px;
    border-radius: 4px;
    background-color: var(--color-block-background);
    margin-bottom: 25px;

    .value {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 900;
      color: var(--color-background);
      font-size: 18px;
      -webkit-text-stroke: 1px var(--color-text-primary);
      z-index: 2;
    }

    .bg {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      border-radius: 4px;
      background: transparent url("../../assets/images/progress.svg") repeat-x center;
      z-index: 1;
    }
  }

  .item {
    margin-bottom: 20px;

    .top {
      position: relative;
      padding-left: 40px;

      .status {
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        color: var(--color-text-label);
        line-height: 1;
      }

      .name {
        color: var(--color-text-label);
        font-size: 16px;
        padding-right: 50px;
      }

      .collapser {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        color: var(--color-color1);
        line-height: 1;
        padding: 10px;
        font-size: 20px;
        transition: transform 0.3s ease-in-out;
      }
    }

    .info {
      overflow: hidden;
      max-height: 0px;
      padding-top: 0px;
      transition: max-height 0.3s ease-in-out, padding-top 0.3s ease-in-out;

      .prop {
        margin-bottom: 20px;

        .label {
          font-size: 16px;
          color: var(--color-text-label);
          margin-bottom: 10px;
        }
      }
    }

    &.expanded {
      .collapser {
        transform: translateY(-50%) rotate(180deg);
      }

      .info {
        max-height: 500px;
        padding-top: 20px;
      }
    }
  }

  .btn-start {
    margin-top: 40px;
  }
}
</style>