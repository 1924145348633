<template>
  <sbs-view :class="'sbs-v-user-view'" :isMenuHidden="hideMenu" @refresh="refresh">
    <template v-slot:header>
      <sbs-header />
    </template>
    <template v-slot:content>
      <sbs-user-view :id="id" :reload="reload" />
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsUserView from "@/components/users/view.vue";

export default {
  name: "sbs-v-user-view",
  components: {
    sbsView,
    sbsHeader,
    sbsUserView,
  },
  data() {
    return {
      reload: 0,
      hideMenu: false
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  methods: {
    refresh(resolve) {
      this.reload++;
      setTimeout(() => {
        resolve();
      }, 300);
    },
  },
  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },
};
</script>