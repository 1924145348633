import {HmsLocalNotification} from '@hmscore/ionic-native-hms-push'

import bus from '@/plugins/bus'

const instance = {

    init() {
        //подписываемся на событие необходимости показа локального уведомления
        bus.on('SBS_NOTIFICATION_E_SHOW', async (data) => {

            let arNotification = {
                title: data.title,
                message: data.body,
                smallIcon: "fcm_push_icon",
                data: data,
            };
            if(data.image) {
                arNotification.largeIconUrl = data.image;
            }

            HmsLocalNotification.localNotification(arNotification);
        });

        //клик по локальному уведомлению
        window.HmsPushEvent.onLocalNotificationAction((result) => {
            console.log(JSON.stringify(result));
        });
    }

}

export default instance;