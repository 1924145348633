<template>
  <sbs-view :class="'sbs-v-request-checklist-exec'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header>
        <template v-slot:right>
          <div class="action sbs-rce-ic" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="close">
            <span class="icon fi fi-close"></span>
          </div>
        </template>
      </sbs-header>
    </template>
    <template v-slot:content>
      <sbs-request-check-list-execute :id="id" :reload="reload" v-model:step="step" v-model:skipBack="skipBack" />
    </template>
    <template v-slot:footer>
      <div id="sbs-rcevb"></div>
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsRequestCheckListExecute from "@/components/requests/checklist.exec.vue";

export default {
  name: "sbs-v-request-checklist-exec",
  components: {
    sbsView,
    sbsHeader,
    sbsRequestCheckListExecute,
  },
  data() {
    return {
      reload: 0,
      step: 1,
      skipBack: true,
      hideMenu: false
    };
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
  },

  methods: {
    /**
     * Закрывает экран
     */
    close() {
      this.closing = true;
      //переход обратно
      this.$router.go(-1);
    },
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },

  /**
   * Вызывается перед переходом в этот экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //Если возврат с экрана сканера QR или NFC
      if (from.meta.skipConfirm) {
        return;
      }
      vm.skipBack = true;
      vm.closing = false;
      vm.step = 1;
      vm.reload++;
    });
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave(to) {
    //если переход на экран сканеров, камеры ИЛИ если не заблокирован переход назад
    if (to.meta.skipConfirm || !this.skipBack) {
      return true;
    }

    //если шаг 1 или закрытие
    if (this.step == 1 || this.closing) {
      return true;
    }

    //иначе делаем шаг назад
    this.step--;
    return false;
  },
};
</script>

<style  lang="scss">
.sbs-rce-ic {
  .icon {
    font-size: 14px !important;
  }
}
</style>
