import router from '@/router'
import bus from '@/plugins/bus'
import { BarcodeScanner, CameraDirection } from '@capacitor-community/barcode-scanner';

export default {
    cameraDirection: CameraDirection.BACK,
    arParams: {},

    init() {
        //подписываемся на событие вызова сканера
        bus.on('SBS_QR_SCANNER_E_OPEN', (params) => {
            this.open(params);
        });

        //подписываемся на событие включение фонарика
        bus.on('SBS_QR_SCANNER_E_SWITCH_LIGHT', () => {
            this.switchLight();
        });

        //подписываемся на событие смены камеры
        bus.on('SBS_QR_SCANNER_E_SWITCH_CAMERA', () => {
            this.switchCamera();
        });

        //подписываемся на событие закрытие сканера
        bus.on('SBS_QR_SCANNER_E_CLOSE', () => {
            this.close();
        });
    },

    /**
     * Открывает экран QR сканера
     */
    async open(params) {
        this.arParams = params;

        const status = await BarcodeScanner.checkPermission({ force: true });

        if (status.granted) {
            router.push({ name: "scanner-qr" });
            this.showScanner();
        } else {
            this.permitQrScanner();
        }
    },

    /**
    * Спрашивает не открыть ли экран настроект разрешений прилоржения
    */
    async permitQrScanner() {
        await new Promise((resolve) => {
            bus.emit("SBS_CONFIRM_E_SHOW", {
                message: "У приложения нет разрешения на использование камеры. Перейти в настройки разрешений приложения?",
                resolveText: "Да",
                rejectText: "Нет",
                resolve: function (value) {
                    BarcodeScanner.openAppSettings();
                    resolve(value);
                },
                reverse: false,
            });
        });
    },

    /**
     * Показывает сканер 
     */
    showScanner() {

        BarcodeScanner.prepare().then(() => {
            bus.emit("SBS_QR_SCANNER_E_READY", this.arParams);
        });

        BarcodeScanner.hideBackground();
        document.querySelector('body').classList.add('scanner-active');

        BarcodeScanner.startScan({
            cameraDirection: this.cameraDirection
        }).then((result) => {
            bus.emit("SBS_QR_SCANNER_E_SCAN", result.content);
        });

    },

    /**
     * Включает/отключает фонарик
     */
    switchLight() {
        BarcodeScanner.toggleTorch();
    },

    /**
     * Закрыает сканер
     */
    close() {
        BarcodeScanner.showBackground();
        document.querySelector('body').classList.remove('scanner-active');
        BarcodeScanner.stopScan();
    },

    /**
     * Переключение камеры
     */
    switchCamera() {

        this.cameraDirection = this.cameraDirection == CameraDirection.BACK ? CameraDirection.FRONT : CameraDirection.BACK;

        BarcodeScanner.startScan({
            cameraDirection: this.cameraDirection
        }).then((result) => {
            bus.emit("SBS_QR_SCANNER_E_SCAN", result.content);
        });
    }

}