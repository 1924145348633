<template>
  <div class="form-group form-group-label" :class="[
    focused ? 'focused' : '',
    value ? 'filled' : '',
    noLabelAnimation && value ? 'no-label-animation' : '',
    !!this.$slots.icon ? 'form-group-icon' : '',
    error ? 'form-group-error' : ''
  ]">
    <div class="floating-label" v-on:click="onLabelClick">
      {{ label }}
      <span v-if="required">*</span>
    </div>
    <textarea class="form-control" :name="name" :class="required ? 'required' : ''" v-model="value" @input="handleInput"
      @focus="onFocus" @blur="onBlur" :rows="rows" ref="input" autocomplete="off" />
    <slot name="icon"></slot>
  </div>
</template>

<script>
export default {
  name: "sbs-control-textarea",
  data() {
    return {
      value: this.modelValue,
      focused: false,
    };
  },
  emits: ["update:modelValue", "update:valid", "update:error"],
  props: {
    label: {
      type: String,
      default() {
        return "Метка";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
    modelValue: {
      type: String,
      default() {
        return "";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    rows: {
      type: [Number, String],
      default() {
        return 3;
      },
    },
    noLabelAnimation: {
      type: Boolean,
      default() {
        return false;
      },
    },
    error: {
      type: Boolean,
      default() {
        return false;
      },
    },
    valid: {
      type: Boolean,
      default() {
        return true
      }
    },
  },
  watch: {
    modelValue: function (newVal) {
      if (newVal !== this.value) {
        this.value = newVal;
      }
    },
    value: function () {
      this.checkValid();
    },
    valid: function () {
      if (this.valid) {
        this.$emit("update:error", false);
      }
    }
  },
  methods: {
    handleInput() {
      this.$emit("update:modelValue", this.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    onLabelClick() {
      this.focused = true;
      this.$refs.input.focus();
    },

    /**
     * Проверяет валидно ли значение
     */
    checkValid() {
      if (this.required && !(this.value)) {
        this.$emit("update:valid", false);
        return;
      }

      this.$emit("update:valid", true);
    },
  },

  mounted() {
    this.checkValid();
  },
};
</script>

<style lang="scss"></style>
