<template>
    <div class="sbs-request-item" :class="{ expanded: expanded }" @click="onItemClick">

        <div class="idq">
            <div class="id">#{{ item.number }}</div>
            <div v-if="item.equipment_id > 0" class="equipment">
                {{
        item.equipment_short_name
            ? item.equipment_short_name
            : item.equipment_name
    }}
            </div>
        </div>
        <div class="status" :style="{ backgroundColor: item.status_color }">
            {{ item.status_name }}
        </div>


        <div class="unread" v-if="unreadCnt > 0">
            <div class="cnt" v-html="unreadCnt <= 99 ? unreadCnt : '99+'"></div>
        </div>


        <div class="name" :class="{ with_report_required: item.report_required == 'Y' }">
            {{ item.name }}
        </div>

        <div class="counters">
            <div class="rating" v-if="item.rating > 0">
                <span class="fi fi-star-filled icon"></span>
                {{ item.rating }}
            </div>
            <div class="views">
                <span class="fi fi-eye icon"></span>
                {{ item.viewers_count > 0 ? item.viewers_count : "0" }}
            </div>
            <div class="priority" :style="{ color: item.priority_color }">
                <span class="fi fi-fire"></span>
            </div>
        </div>

        <!-- Раскрывашка до информации -->
        <div class="info">
            <div class="prop report_required" v-if="item.report_required == 'Y'">
                <div class="label">Необходим отчёт при завершении</div>
            </div>
            <div class="prop" v-if="item.equipment_id > 0">
                <div class="label">Оборудование:</div>
                <div class="value">{{ item.equipment_name }}</div>
            </div>
            <div class="prop">
                <div class="label">Подразделение - исполнитель:</div>
                <div class="value">{{ item.responsible_department_name }}</div>
            </div>
            <div class="prop" v-if="item.responsible_user_id > 0">
                <div class="label">Исполнитель:</div>
                <div class="value">
                    {{ item.responsible_user_name }}
                    {{ item.responsible_user_last_name }}
                </div>
            </div>
            <div class="prop" v-if="item.created_by > 0">
                <div class="label">Автор:</div>
                <div class="value">
                    {{ item.created_by_user_name }}
                    {{ item.created_by_user_last_name }}
                </div>
            </div>
        </div>
        <div class="date" v-html="formatDate(item.date_create)"></div>


        <!-- Кнопки -->
        <div class="btn-wrap">
            <div class="row">

                <!-- Если исполнителя нет -->
                <template v-if="!item.responsible_user_id">

                    <!-- Взять и начать -->
                    <div class="col" v-if="item.permissions && item.permissions.includes('take')">
                        <div class="btn btn-block btn3 slim" :class="item.loading ? 'wait' : ''"
                            @click.stop="takeAndStart">
                            <div class="text-wrap">
                                <span class="icon fi fi-play-outline"></span>
                                Начать
                            </div>

                            <div v-if="item.loading" class="btn-loader"></div>
                        </div>
                    </div>

                </template>

                <!-- Если исполнитель задан  -->
                <template v-else>

                    <!-- Начать -->
                    <div class="col"
                        v-if="item.status_id != requestStatusFinished && item.permissions && item.permissions.includes('start')">
                        <div class="btn btn-block btn3 slim" :class="item.loading ? 'wait' : ''" @click.stop="start">
                            <div class="text-wrap">
                                <span class="icon fi fi-play-outline"></span>
                                Начать
                            </div>
                            <div v-if="item.loading" class="btn-loader"></div>
                        </div>
                    </div>

                    <!-- Завершить -->
                    <div class="col"
                        v-else-if="item.status_id == requestStatusStart && item.permissions && item.permissions.includes('complete')">
                        <div class="btn btn-block btn3 slim" :class="item.loading ? 'wait' : ''" @click.stop="complete">
                            <div class="text-wrap">
                                <span class="icon fi fi-stop-outline"></span>
                                Завершить
                            </div>
                            <div v-if="item.loading" class="btn-loader"></div>
                        </div>
                    </div>

                    <!-- Назначить себе -->
                    <div class="col"
                        v-else-if="item.status_id != requestStatusFinished && item.permissions && (item.permissions.includes('delegate') || item.permissions.includes('take'))">
                        <div class="btn btn-block btn3 slim" :class="item.loading ? 'wait' : ''"
                            @click.stop="takeAndStart">
                            <div class="text-wrap">
                                <span class="icon fi fi-add-outline"></span>
                                Назначить себе
                            </div>
                            <div v-if="item.loading" class="btn-loader"></div>
                        </div>
                    </div>

                </template>

                <!-- Перейти -->
                <div class="col">
                    <div class="btn btn-block btn1 slim" @click.stop="goToItem">
                        Перейти
                    </div>
                </div>
            </div>
        </div>

    </div>

    <sbs-popup v-model:show="displayNfc">
        Для начала работы необходимо отсканировать NFC метку оборудования
        <br /><br />
        <div class="btn btn-block btn1" @click="scanNfc">Сканировать NFC</div>
    </sbs-popup>

</template>

<script>
import sbsPopup from "@/components/popup.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";

import { mapGetters } from "vuex";

export default {
    name: "sbs-request-item",
    components: {
        sbsPopup,
    },

    data() {
        return {
            expanded: false,
            displayNfc: false,
            loading: false,
            action: ""
        };
    },

    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    computed: {
        ...mapGetters({
            clientSettings: "getClientSettings",
            requestUnread: "getRequestUnread",
        }),

        //ИД статуса "Завершено"
        requestStatusFinished() {
            return this.clientSettings
                ? this.clientSettings.request_status_finished
                : false;
        },
        //ИД статуса "В работе"
        requestStatusStart() {
            return this.clientSettings
                ? this.clientSettings.request_status_start
                : false;
        },

        unreadCnt() {
            let r = this.requestUnread.find(r => r.id == this.item.id);
            if (r) {
                return r.unread_messages_cnt
            }
            return 0;
        }
    },

    methods: {

        /**
         * Клик по заявке
         */
        onItemClick() {
            this.expanded = !this.expanded;
        },

        /**
         * Форматирует дату
         */
        formatDate(date) {
            return moment(date, "DD.MM.YYYY hh:mm:ss").format("DD MMMM YYYY HH:mm");
        },

        /**
         * Переход к заявке
         */
        goToItem() {
            this.$router.push({
                name: "request-view",
                params: { id: this.item.id, tab: "discussion" },
            });
        },

        /**
         * Взять себе и начать выполнение
         */
        takeAndStart(item) {

            if (!item.need_start_by_nfc) {
                this.takeAndStartImmediatly(item);
            } else {
                this.displayNfc = true;
                this.loading = false;
                this.action = "takeAndStart";
            }
        },

        /**
         * Начать выполнение сразу
         */
        takeAndStartImmediatly() {
            if (this.loading) {
                return;
            }

            let arRequests = {
                take: {
                    ENTITY: "request",
                    METHOD: "take",
                    PARAMS: {
                        id: this.item.id,
                    },
                },
            };
            if (this.item.status_id != this.requestStatusStart) {
                arRequests.start = {
                    ENTITY: "request",
                    METHOD: "start",
                    PARAMS: {
                        id: this.item.id,
                    },
                };
            }
            this.loading = true;
            rest
                .batch(arRequests)
                .then((results) => {
                    for (var key in results) {
                        let data = results[key];
                        if (!data.success) {
                            //показываем сообщение об ошибке
                            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                            return;
                        }
                    }
                    bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
                    bus.emit("SBS_REQUEST_E_UPDATED", this.item.id);
                }).finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Начать выполнение
         */
        start(item) {

            if (!item.need_start_by_nfc) {
                this.startImmediatly();
            } else {
                this.displayNfc = true;
                this.loading = false;
                this.action = "start";
            }
        },

        /**
         * Начать выполнение
         */
        startImmediatly() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            //выполняем запрос
            rest
                .call("request.start", {
                    method: "post",
                    data: {
                        id: this.item.id,
                    },
                })
                .then((data) => {
                    if (data.success) {
                        bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
                        bus.emit("SBS_REQUEST_E_UPDATED", this.item.id);
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                }).finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Завершить
         */
        complete() {
            //если есть чеклист и он не пройден
            if (this.item.have_checklist == "Y" && this.item.checklist_completed == 'N') {
                this.$router.push({
                    name: "request-checklist-execute",
                    params: { id: this.item.id },
                });
                return;
            }
            //если требуется отчёт по завершении
            else if (this.item.report_required == "Y") {
                this.$router.push({
                    name: "request-report-execute",
                    params: { id: this.item.id },
                });
                return;
            }
            if (this.loading) {
                return;
            }
            this.loading = true;

            //выполняем запрос
            rest
                .call("request.complete", {
                    method: "post",
                    data: {
                        id: this.item.id,
                    },
                })
                .then((data) => {
                    if (data.success) {
                        bus.emit("SBS_REQUEST_VIEW_E_RELOAD");
                        bus.emit("SBS_REQUEST_E_UPDATED", this.item.id);
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                }).finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Сканировать NFC метку
         */
        scanNfc() {
            let vm = this;
            //скрываем собщение о том, что нужно сканировать NFC
            this.displayNfc = false;

            //вызываем сканер NFC
            bus.emit("SBS_NFC_SCANNER_E_OPEN", {
                resolve: function (equipment) {

                    //проверям если ИД оборудования, который нашёл сканер совпадает с тем, что указан в заявке
                    if (equipment.id == vm.item.equipment_id) {
                        //если действие было "Взять и начать"
                        if (vm.action == "takeAndStart") {
                            vm.takeAndStartImmediatly();
                        }
                        //иначе дейсвтие было "Начать"
                        else {
                            vm.startImmediatly();
                        }
                    } else {
                        bus.emit("SBS_MESSAGE_E_SHOW", {
                            message:
                                "Метка не закрепленна за оборудованием по данной заявке.",
                        });
                    }
                },
            });
        },



    },
};
</script>

<style lang="scss">
.sbs-request-item {
    position: relative;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--color-block-background);
    margin-bottom: 20px;

    .idq {
        padding-right: 120px;
        min-height: 30px;

        .id {
            font-size: 14px;
            color: var(--color-block-title);
            line-height: 1;

        }

        .equipment {
            color: var(--color-block-text);
            font-size: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .status {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 6px 5px;
        min-width: 90px;
        text-align: center;
        color: #1e1f22;
        border-radius: 4px;
        line-height: 1;
    }

    .unread {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        background-color: var(--color-message-error-background);
        color: var(--color-message-error-text);
        border-radius: 50%;

        .cnt {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 12px;
            transform: translate(-50%, -50%);
        }
    }

    .name {
        color: var(--color-block-text);
        line-height: 1.14;
        margin-bottom: 5px;
        padding-right: 120px;
        transition: font-size 0.5s ease-in-out, margin-bottom 0.5s ease-in-out;
    }

    .counters {
        position: absolute;
        bottom: 10px;
        right: 10px;
        line-height: 1;
        transition: bottom 0.5s ease-in-out;
    }

    .priority {
        float: left;
        margin-left: 13px;
        line-height: 1;
    }

    .views,
    .rating {
        position: relative;
        float: left;
        margin-left: 13px;
        line-height: 1;

        color: var(--color-block-value);
        padding-left: 20px;
        line-height: 1;

        .icon {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
        }
    }

    .rating {
        .icon {
            color: var(--color-color1);
        }
    }

    .date {
        color: var(--color-block-value);
        font-size: 12px;
    }

    .info {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.5s ease-in-out;

        .prop {
            margin-bottom: 10px;

            .label {
                line-height: 1;
                color: var(--color-block-label);
                font-size: 16px;
            }

            .value {
                color: var(--color-block-value);
            }

            &.report_required {

                .label {
                    color: var(--color-color1);
                    font-size: 1em;
                }
            }


        }


    }

    .btn-wrap {
        margin-top: 0px;
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out;

        .row {
            margin: 0px -7px;

            >div {
                padding: 0px 7px;
            }
        }
    }

    &.expanded {
        .name {
            font-size: 16px;
            margin-bottom: 20px;

            &.with_report_required {
                margin-bottom: 3px;
            }
        }

        .info {
            max-height: 300px;
        }

        .counters {
            bottom: 60px;
        }

        .btn-wrap {
            margin-top: 10px;
            max-height: 50px;
        }
    }
}
</style>