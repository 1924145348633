<template>
  <div class="sbs-photo-swiper">
    <swiper :slidesPerView="'auto'" :loop="false" :spaceBetween="30" class="mySwiper">
      <swiper-slide v-for="photo in photos" :key="photo.id">
        <div class="photo" :style="{
          backgroundImage: photo.data
            ? 'url(data:image/jpeg;base64,' + photo.data + ')'
            : 'url(' + photo.preview + ')',
        }" @click="openPhoto(photo)"></div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>

import utils from "@/plugins/utils";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  name: "sbs-photo-swiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: false,
    };
  },
  props: {
    photos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    gallery() {
      let images = this.photos.map((item) => {
        return {
          src: item.full ? item.full : item.src,
          type: item.image ? "image" : "video",
          width: item.width,
          height: item.height,
          thumb: item.preview ? item.preview : item.src,
          caption: item.name,
          id: item.id,
        };
      });
      return images;
    },
  },
  methods: {
    /**
     * Открыть фото на просмотр
     */
    openPhoto(item) {
      let start = this.gallery.findIndex((i) => i.id == item.id);

      Fancybox.show(this.gallery, {
        startIndex: start,
        preload: 0, //иначе видео mp4 слева/справа начинает сразу воспроизводиться
        infinite: false,
        Toolbar: {
          display: [
            { id: "prev", position: "center" },
            { id: "counter", position: "center" },
            { id: "next", position: "center" },
            "zoom",
            "slideshow",
            "thumbs",
            "close",
          ],
        },
        Hash: false,
        on: {
          ready: (fancybox) => {
            utils.fancyBoxOnReady(fancybox);
          },
          closing: () => {
            utils.fancyBoxOnClose();
          },
        },
      });
    },
  },
};
</script>


<style lang="scss">
.sbs-photo-swiper {
  margin-bottom: 20px;

  .swiper-slide {
    width: 155px;
  }

  .photo {
    height: 0px;
    padding-top: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
</style>