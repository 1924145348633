import 'resize-observer-polyfill/dist/ResizeObserver.global'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "moment";
import "moment/locale/ru";
import Vue3MobileDetection from "vue3-mobile-detection";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Capacitor } from '@capacitor/core';
import { SafeArea } from 'capacitor-plugin-safe-area';
import VueLazyload from 'vue-lazyload'


import Ripple from './directives/ripple/ripple'
import PullToRefresh from './directives/pull-to-refresh'
import capacitor from './plugins/capacitor'
import errorListiner from './plugins/error.listiner'
import localStorage from '@/plugins/localStorage'
import ws from "@/plugins/websocket"
import updater from '@/plugins/updater'
import i18n from "./i18n"
import {AuthTokenExpiredError} from '@/plugins/errors.js'

import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bottom-sheet-vue3/css/style.css'
import './assets/fonts/roboto/style.css'
import './assets/fonts/icomoon/style.css'
import './assets/themes/dark.scss'
import './assets/themes/light.scss'
import './assets/styles/default.scss'
import './assets/styles/forms.scss'
import './assets/styles/typography.scss'
import './assets/styles/buttons.scss'
import './assets/styles/animation.scss'
import './assets/styles/block.scss'

window.addEventListener('error', function (e) {
    errorListiner.onError(e);
})

window.addEventListener("unhandledrejection", function (e) {
    errorListiner.onError(e);
});

//асинхронная инициализация, чтобы можно было получить параметры из приложения синхронно
//до старта приложения
let init = async () => {

    if (Capacitor.isNativePlatform()) {
        //переход на использование хранения настроек в Capacitor
        await localStorage.migrateToCapacitor();
        //загружаем данные о нативном приложении
        store.dispatch("loadAppInfo");
    }

    //восстанавливаем токен Push (нужно до получения настроект клиента)
    let pushToken = await localStorage.get("fcm_token");
    if (pushToken !== null) {
        store.commit("setPushToken", pushToken);
    }

    //восстанавливаем текущее подключение
    let key = await localStorage.get("current_connection");
    let arConnections = await localStorage.get("connections");
    if (
        key !== null &&
        arConnections !== null &&
        arConnections[key]
    ) {
        //сохраняем список подключений в текущем состоянии
        store.commit("setConnections", arConnections);
        //сохраням в текущем состоянии
        store.commit("setCurrentConnection", key);

        //загрузим настройки клиента (ожидаем, чтобы загрузился в обязательном порядке список доступных сервисов)
        try {
            await store.dispatch("loadClientSettings");
        } catch (e) {

            //если не ошибка устаревания токена
            if(!(e instanceof AuthTokenExpiredError)) {
                errorListiner.onError(e);
                alert(e.message);
                return;
            }
            
        }

        //после выполнения запроса loadClientSettings авторизация может сброситься
        if(store.getters.isAuthorized) {
            //загрузим профиль пользователя
            store.dispatch("loadUserData");
            //регистрируем подключение по вебсокету
            ws.register();
        }

        
    } else {
        //очищаем подключения
        store.commit("setConnections", {});
        store.commit("setCurrentConnection", 0);
    }

    //восстанавливаем тему
    let theme = await localStorage.get("theme");
    if (theme == null) {
        theme = "dark";
    }
    store.commit("setTheme", theme);

    //восстанавливаем текущий сервис
    let service = await localStorage.get("service");
    store.commit("setService", service);

    //если плагин безопасной зоны экрана доступен
    if(Capacitor.isPluginAvailable('SafeArea')) {
        //получаем безопасные границы
        let response = await SafeArea.getSafeAreaInsets();
        store.commit("setSafeArea", response.insets);
    }

    //получаем флаг принятия правил использования
    let termsOfUseAccepted = await localStorage.get("terms_of_use_accepted");
    if(termsOfUseAccepted == "Y") {
        store.commit("setTermsOfUseAccepted", true);
    }

    const app = createApp(App)

    //плагин ряби на кнопках
    Ripple.color = 'rgba(255, 255, 255, 0.35)';
    app.directive('ripple', Ripple);

    //плагин обновления экрана при оттягивании
    app.directive('pull-to-refresh', PullToRefresh);

    //плагин определения мобильного устройства
    app.use(Vue3MobileDetection);

    //отложенная загрузка картинок
    app.use(VueLazyload, {
        throttleWait: 115,
        attempt: 3,
        silent: false,
        preLoad:2,
        observer: true,
        observerOptions: {
            rootMargin: '150px',
            threshold: 0.01
        }
    })

    app.use(store).use(router).use(i18n);

    let mount = () => {
        app.mount('#app')
        //Подключение capacitor
        capacitor.init();
        //автообновление
        updater.init();
    };

    //если запуск идёт внутри МП
    if (window.location.hostname == "localhost") {
        //то ждём пока заставка html-ная отработает 
        setTimeout(function () {

          mount();
          let component = document.getElementsByClassName("sbs-splash-screen")[0];
          component.style.display = "none";

        }, 4000);
    } else {
        //иначе сразу стартуем
        mount();
    }

};

init();


// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);


//test