<template>
  <div class="sbs-equipment-search" ref="container">
    <div class="search-wrap">
      <sbs-control-input v-model="search" placeholder="Поиск" class="search">
        <template v-slot:icon>
          <div v-if="isNativePlatform && search == ''" class="icon" @click="speech">
            <span class="fi fi-mic"></span>
          </div>
          <div v-if="search != ''" class="icon" @click="clearSearch()">
            <span class="fi fi-close"></span>
          </div>
        </template>
      </sbs-control-input>
    </div>

    <!-- Загрузка -->
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>

    <template v-if="items.length > 0">
      <div class="items">
        <div class="item" v-for="item in items" :key="item.id" @click="onItemClick(item)" v-html="item.name">
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlInput from "@/components/controls/input.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import { mapGetters } from "vuex";

export default {
  name: "sbs-requests-search",
  components: {
    sbsLoaderInline,
    sbsControlInput,
  },
  data() {
    return {
      loading: false,
      search: "",
      items: [],
      pager: {
        size: 50,
        page: 1,
        cnt: 1,
      },
    };
  },
  props: {
    //отвечает за перезагрузку экрана
    reload: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters({
      isNativePlatform: "isNativePlatform",
    }),
  },
  watch: {
    search: function () {
      this.applySearch();
    },
  },

  methods: {
    /**
     * Очищает поиск
     */
    clearSearch() {
      this.search = "";
      this.items = [];
    },

    /**
     * Поиск
     */
    applySearch() {
      if (this.search.trim() == "") {
        this.items = [];
        return;
      }

      if (this.loading) {
        return;
      }

      //запрашиваем данные
      rest
        .call("equipment.search", {
          method: "post",
          data: {
            paging: this.pager,
            q: this.search,
          },
        })
        .then((data) => {
          if (data.success) {
            if (this.pager.page == 1) {
              this.items = data.items;
            } else {
              this.items = this.items.concat(data.items);
            }
            this.pager = data.pager;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Переход к оборудованию
     */
    onItemClick(item) {
      this.$router.replace({
        name: "equipment-view",
        params: { id: item.id },
      });
    },

    /**
     * Распознование речи для навания
     */
    speech() {
      bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
        resolve: (text) => {
          this.search = text;
        },
      });
    },
  },
};
</script>

<style lang="scss">
.sbs-equipment-search {
  .item {
    padding: 10px;
    border-radius: 8px;
    background-color: var(--color-block-background);
    margin-bottom: 20px;
  }
}
</style>