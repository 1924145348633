import { createI18n } from "vue-i18n";

import ru from "./locales/ru.json" 

export default createI18n({
  locale: process.env.VUE_APP_DEFAULT_LOCALE, 
  fallbackLocale: process.env.VUE_APP_DEFAULT_LOCALE,
  legacy: false,
  globalInjection: true,
  messages: {
    ru
  },
})