<template>
    <div class="sbs-rt-a">
        <sbs-popup v-model:show="display" :loading="loading">
            <div class="actions">

                <div class="action" @click="remove">
                    <span class="icon fi fi-close"></span>
                    <div class="text">Удалить шаблон</div>
                </div>

                <div class="action" @click="repeat">
                    <span class="icon fi fi-refresh"></span>
                    <div class="text">Создать заявку по шаблону</div>
                </div>

            </div>
        </sbs-popup>
    </div>
</template>

<script>
import sbsPopup from "@/components/popup.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";

export default {
    name: "sbs-request-template-actions",
    components: {
        sbsPopup,
    },
    emits: ["update:show", "update:checkLeave"],
    data() {
        return {
            display: this.show,
            loading: false
        };
    },
    props: {
        id: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        show: {
            type: Boolean,
            default() {
                return false;
            },
        },
        checkLeave: {
            type: Boolean,
            default() {
                return false;
            },
        }
    },
    watch: {
        show: function (newVal) {
            this.display = newVal;
        },
        display: function (newVal) {
            this.$emit("update:show", newVal);
        },
    },

    methods: {
        close() {
            this.$emit("update:show", false);
        },

        /**
         * Удаление
         */
        remove() {

            let vm = this;
            bus.emit("SBS_CONFIRM_E_SHOW", {
                message: "Вы действительно хотите удалить шаблон?",
                resolveText: "Удалить",
                rejectText: "Отменить",
                resolve: function (value) {
                    if (value) {
                        vm.removeImmediatly()
                    }
                },

            });

        },

        /**
         * Удаление заявки - запрос
         */
        removeImmediatly() {

            this.loading = true;

            //выполняем запрос
            rest
                .call("request.template.delete", {
                    method: "post",
                    data: {
                        id: this.id,
                    },
                })
                .then((data) => {
                    if (data.success) {

                        this.close();
                        bus.emit("SBS_REQUEST_TEMPLATE_FORM_E_DELETE", this.id);
                        this.$router.go(-1);

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Создание заявки по шаблону
         */
        repeat() {

            let vm = this;
            bus.emit("SBS_CONFIRM_E_SHOW", {
                message: "Вы действительно хотите создать заявку по данному шаблону?",
                resolveText: "Создать",
                rejectText: "Отменить",
                resolve: function (value) {
                    if (value) {
                        vm.repeatImmediatly()
                    }
                },

            });
        },

        /**
         * Создание заявки по шаблону
         */
        repeatImmediatly() {

            this.loading = true;

            //выполняем запрос
            rest
                .call("request.template.repeat", {
                    method: "post",
                    data: {
                        id: this.id,
                    },
                })
                .then((data) => {
                    if (data.success) {

                        //показываем сообщение об Успехе
                        bus.emit("SBS_MESSAGE_E_SHOW", {
                            message: "Задача по шаблону создана.",
                            type: "success",
                        });

                        this.close();
                        this.$emit("update:checkLeave", false);
                        this.$router.go(-1);


                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }


    },
};
</script>

<style lang="scss"></style>