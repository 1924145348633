<template>
  <div class="sbs-props">
    <div class="prop" v-for="prop in props" :key="prop.code">
      <div class="label">{{ prop.name }}</div>

      <template v-if="prop.type == 'string'">
        <div class="value">{{ prop.value }}</div>
      </template>
      <template
        v-else-if="
          prop.type == 'files' &&
          (prop.code == 'PHOTO' || prop.code == 'UF_PHOTO')
        "
      >
        <sbs-photo-swiper :photos="prop.value" />
      </template>
    </div>
  </div>
</template>

<script>
import sbsPhotoSwiper from "@/components/photo.swiper.vue";

export default {
  name: "sbs-props",
  components: {
    sbsPhotoSwiper,
  },
  props: {
    props: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
.sbs-props {
  margin-bottom: 20px;
  .prop {
    margin-bottom: 15px;
    .label {
      color: var(--color-block-label);
    }

    .value {
      font-size: 16px;
      color: var(--color-text-label);
    }
  }
}
</style>