<template>
    <div class="sbs-requests-summary">
        <template v-if="loading">
            <div class="text-center">
                <sbs-loader-inline />
            </div>
        </template>
        <template v-if="!loading">
            <div class="title-wrap">
                <div class="title h1">{{ $t("requests.summary.title") }} {{ selectedPeriod.short }}</div>
                <div class="options-btn" @click="openOptions">
                    <span class="fi fi-actions"></span>
                </div>
            </div>

            <sbs-requests-statuses :items="requestsStatuses" :showEmpty="true"
                :emptyMessage="$t('requests.summary.no_statistics')" :showTitle="false" />

            <sbs-department-rating :rating="statistics.myDepartmentRating ? statistics.myDepartmentRating.rating_avg : 0"
                :filter="filterForView" />


            <div class="row">
                <div class="col-sm-6">
                    <div class="h2">{{ $t("requests.summary.new_dynamics") }}</div>
                    <sbs-requests-statistics-new-dynamics :items="statistics.newByDates" />
                </div>
                <div class="col-sm-6">
                    <div class="h2">{{ $t("requests.summary.by_responsible_users") }}</div>
                    <sbs-requests-statistics-count-by-responsible-users :items="statistics.countByResponsibleUsers" />
                </div>
                <div class="col-sm-6">
                    <div class="h2">{{ $t("requests.summary.closetime_by_responsible_users") }}</div>
                    <sbs-requests-statistics-closetime-by-responsible-users
                        :items="statistics.closeTimeByResponsibleUsers" />
                </div>
                <div class="col-sm-6">
                    <div class="h2">{{ $t("requests.summary.closed_by_created_departments") }}</div>
                    <sbs-requests-statistics-closed-by-created-departments
                        :items="statistics.closedByCreatedDepartments" />
                </div>
                <div class="col-sm-6">
                    <div class="h2">{{ $t("requests.summary.closetime_by_dates") }}</div>
                    <sbs-requests-statistics-closetime-by-dates :items="statistics.closeTimeByDates" />
                </div>
                <div class="col-sm-6">
                    <div class="h2">{{ $t("requests.summary.worktime_by_responsible_users") }}</div>
                    <sbs-requests-statistics-worktime-by-responsible-users
                        :items="statistics.workTimeByResponsiblesUsers" />
                </div>
                <div class="col-sm-6">
                    <div class="h2"> {{ $t("requests.summary.worktime_by_dates") }}</div>
                    <sbs-requests-statistics-worktime-by-dates :items="statistics.workTimeByDates" />
                </div>
                <div class="col-sm-6">
                    <div class="h2">{{ $t("requests.summary.count_by_equipment") }}</div>
                    <sbs-requests-statistics-count-by-equipment :items="statistics.countByEquipment" />
                </div>
                <div class="col-sm-6">
                    <div class="h2">{{ $t("requests.summary.rating_by_responsible_users") }}</div>
                    <sbs-requests-statistics-rating-by-responsible-users :items="statistics.responsibleUsersRating" />
                </div>

            </div>

            <sbs-popup v-model:show="showOptions">
                <div class="options">
                    <div v-for="p in periods" :key="p.id" class="option">
                        <sbs-control-radio v-model="period" :value="p.id" :label="p.name" :form="false" />
                    </div>
                </div>
            </sbs-popup>
        </template>
    </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsRequestsStatuses from "@/components/requests/statuses.vue";
import sbsRequestsStatisticsNewDynamics from "@/components/requests/statistics/new.dynamics.vue";
import sbsRequestsStatisticsCountByResponsibleUsers from "@/components/requests/statistics/count.by.responsible.users.vue";
import sbsRequestsStatisticsClosetimeByResponsibleUsers from "@/components/requests/statistics/closetime.by.responsible.users.vue";
import sbsRequestsStatisticsClosedByCreatedDepartments from "@/components/requests/statistics/closed.by.created.departments.vue";
import sbsRequestsStatisticsClosetimeByDates from "@/components/requests/statistics/closetime.by.dates.vue";
import sbsRequestsStatisticsWorktimeByResponsibleUsers from "@/components/requests/statistics/worktime.by.responsible.users.vue";
import sbsRequestsStatisticsWorktimeByDates from "@/components/requests/statistics/worktime.by.dates.vue";
import sbsRequestsStatisticsCountByEquipment from "@/components/requests/statistics/count.by.equipment.vue";
import sbsRequestsStatisticsRatingByResponsibleUsers from "@/components/requests/statistics/rating.by.responsible.users.vue";
import sbsDepartmentRating from "@/components/departments/rating.vue";
import sbsPopup from "@/components/popup.vue";
import sbsControlRadio from "@/components/controls/radio.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";
import localStorage from "@/plugins/localStorage";

export default {
    name: "sbs-requests-summary",
    components: {
        sbsLoaderInline,
        sbsRequestsStatuses,
        sbsRequestsStatisticsNewDynamics,
        sbsRequestsStatisticsCountByResponsibleUsers,
        sbsRequestsStatisticsClosetimeByResponsibleUsers,
        sbsRequestsStatisticsClosedByCreatedDepartments,
        sbsRequestsStatisticsClosetimeByDates,
        sbsRequestsStatisticsWorktimeByResponsibleUsers,
        sbsRequestsStatisticsWorktimeByDates,
        sbsRequestsStatisticsCountByEquipment,
        sbsRequestsStatisticsRatingByResponsibleUsers,
        sbsDepartmentRating,
        sbsPopup,
        sbsControlRadio,
    },

    data() {
        return {
            loading: true,
            requestsStatuses: [],
            statistics: {
                newByDates: [],
                countByResponsibleUsers: [],
                closeTimeByResponsibleUsers: [],
                closedByCreatedDepartments: [],
                closeTimeByDates: [],
                workTimeByResponsiblesUsers: [],
                workTimeByDates: [],
                countByEquipment: [],
                myDepartmentRating: false,
                responsibleUsersRating: []
            },
            showOptions: false,
            period: "30",
            periods: [
                {
                    id: "10",
                    name: this.$t("requests.summary.period_days", { days: 10 }),
                    short: this.$t("requests.summary.period_days_short", { days: 10 }),
                    from: moment().add(-10, "d").format("DD.MM.YYYY"),
                    filter: {
                        ">=date_create": moment().add(-10, "d").format("DD.MM.YYYY"),
                    },
                    filterClose: {
                        ">=date_create": moment().add(-10, "d").format("DD.MM.YYYY"),
                    },
                },
                {
                    id: "30",
                    name: this.$t("requests.summary.period_days", { days: 30 }),
                    short: this.$t("requests.summary.period_days_short", { days: 30 }),
                    from: moment().add(-30, "d").format("DD.MM.YYYY"),
                    filter: {
                        ">=date_create": moment().add(-30, "d").format("DD.MM.YYYY"),
                    },
                    filterClose: {
                        ">=date_create": moment().add(-30, "d").format("DD.MM.YYYY"),
                    },
                },
                {
                    id: "60",
                    name: this.$t("requests.summary.period_days", { days: 60 }),
                    short: this.$t("requests.summary.period_days_short", { days: 60 }),
                    from: moment().add(-60, "d").format("DD.MM.YYYY"),
                    filter: {
                        ">=date_create": moment().add(-60, "d").format("DD.MM.YYYY"),
                    },
                    filterClose: {
                        ">=date_create": moment().add(-60, "d").format("DD.MM.YYYY"),
                    },
                },
            ],
        };
    },
    props: {
        //отвечает за перезагрузку экрана
        reload: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    computed: {
        selectedPeriod() {
            let p = this.periods.find((p) => p.id == this.period);
            return p;
        },

        filterForView() {
            if (this.selectedPeriod) {
                return {
                    created: [
                        {
                            id: moment().format("x"),
                            from: this.selectedPeriod.from,
                            to: ""
                        }
                    ]
                }
            }

            return false;
        }
    },
    watch: {
        reload: function () {
            this.refresh();
        },
        period: function (newVal) {
            if (!this.loading) {
                this.showOptions = false;
                this.refresh();
                localStorage.set("requests_summary_period", newVal);
            }
        },
    },
    methods: {
        /**
         * Подготовка запросов
         */
        getBatchRequests() {
            return {
                statuses: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: ["status_id", "cnt"],
                        filter: this.selectedPeriod.filter,
                        group: ["status_id"],
                        order: { "status.sort": "asc" },
                    },
                },
                new: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: ["date_create_date", "status_id", "cnt"],
                        filter: this.selectedPeriod.filter,
                        group: ["date_create_date"],
                        order: { date_create_date: "asc", "status.sort": "asc" },
                    },
                },
                //кол-во заявок по исполнителям
                countByResponsibleUsers: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "responsible_user_id",
                            1: "cnt",
                            responsible_user_name: "responsible_user.name",
                            responsible_user_last_name: "responsible_user.last_name",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!responsible_user_id": false,
                        },
                        group: ["responsible_user_id"],
                    },
                },
                //среднее время закрытия заявок по исполнителям
                closeTimeByResponsibleUsers: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "responsible_user_id",
                            1: "close_period_avg",
                            responsible_user_name: "responsible_user.name",
                            responsible_user_last_name: "responsible_user.last_name",
                        },
                        filter: {
                            0: this.selectedPeriod.filterClose,
                            "!date_close": false,
                            "!responsible_user_id": false,
                        },
                        group: ["responsible_user_id"],
                    },
                },
                //закрытые заявки по заказчикам
                closedByCreatedDepartments: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "created_department_id",
                            1: "cnt",
                            created_department_name: "created_department.name",
                        },
                        filter: {
                            0: this.selectedPeriod.filterClose,
                            "!date_close": false,
                        },
                        group: ["created_department_id"],
                    },
                },

                //динамика среднего времени закрытия по датам
                closeTimeByDates: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "cnt",
                            1: "close_period_avg",
                            2: "date_close_date",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!date_close": false,
                        },
                        group: ["date_close_date"],
                        order: {
                            date_close_date: "asc",
                        },
                    },
                },

                //отработанные часы по исполнителям
                workTimeByResponsiblesUsers: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "responsible_user_id",
                            1: "work_time_sum",
                            responsible_user_name: "responsible_user.name",
                            responsible_user_last_name: "responsible_user.last_name",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!responsible_user_id": false,
                            "!work_time.id": false,
                        },
                        group: ["responsible_user_id"],
                    },
                },

                //динамика рабочего времени по датам
                workTimeByDates: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "work_time_sum",
                            2: "work_time_date",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!work_time_date": false,
                        },
                        group: ["work_time_date"],
                        order: {
                            work_time_date: "asc",
                        },
                    },
                },

                //кол-во заявок по исполнителям
                countByEquipment: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "equipment_id",
                            1: "cnt",
                            equipment_name: "equipment.name",
                            equipment_short_name: "equipment.short_name.value",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!equipment_id": false,
                        },
                        limit: 10,
                        group: ["equipment_id", "equipment.name", "equipment.short_name.value"],
                        order: {
                            CNT: "desc",
                        },
                    },
                },

                //средняя оценка своего подразделения
                myDepartmentRating: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "responsible_department_id",
                            1: "rating_avg",
                            responsible_department_name: "responsible_department.name",
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "responsible_department_id": "MY_DEPARTMENT",
                            ">rating": 0
                        },
                        limit: 10,
                        group: ["responsible_department_id"],
                    },
                },

                //средняя оценка по исполнителям
                responsibleUsersRating: {
                    ENTITY: "request",
                    METHOD: "statistics",
                    PARAMS: {
                        select: {
                            0: "responsible_user_id",
                            1: "rating_avg",
                            responsible_user_name: "responsible_user.name",
                            responsible_user_last_name: "responsible_user.last_name",
                            responsible_user_photo: "responsible_user.personal_photo"
                        },
                        filter: {
                            0: this.selectedPeriod.filter,
                            "!responsible_user_id": false,
                            ">rating": 0
                        },
                        limit: 10,
                        group: ["responsible_user_id"],
                    },
                },
            };
        },

        /**
         * Загружает данные
         */
        loadData() {
            //покажем экран загрузки
            this.loading = true;

            //запрашиваем данные
            rest
                .batch(this.getBatchRequests())
                .then((arResults) => {
                    //обработка результатов
                    this.processResponse(arResults);
                })
                .catch(() => {
                    //в тихом режиме не обрабатваем ошибку
                })
                .finally(() => {
                    //скроем экран загрузки
                    this.loading = false;
                });
        },

        /**
         * Обработка результата
         */
        processResponse(results) {
            for (var key in results) {
                let data = results[key];

                if (data.success) {
                    if (key == "statuses") {
                        this.requestsStatuses = data.items;
                    } else if (key == "new") {
                        this.statistics.newByDates = data.items;
                    } else if (key == "countByResponsibleUsers") {
                        this.statistics.countByResponsibleUsers = data.items;
                    } else if (key == "closeTimeByResponsibleUsers") {
                        this.statistics.closeTimeByResponsibleUsers = data.items;
                    } else if (key == "closedByCreatedDepartments") {
                        this.statistics.closedByCreatedDepartments = data.items;
                    } else if (key == "closeTimeByDates") {
                        this.statistics.closeTimeByDates = data.items;
                    } else if (key == "workTimeByResponsiblesUsers") {
                        this.statistics.workTimeByResponsiblesUsers = data.items;
                    } else if (key == "workTimeByDates") {
                        this.statistics.workTimeByDates = data.items;
                    } else if (key == "countByEquipment") {
                        this.statistics.countByEquipment = data.items;
                    } else if (key == "myDepartmentRating") {
                        this.statistics.myDepartmentRating = data.items.length > 0 ? data.items[0] : false
                    } else if (key == "responsibleUsersRating") {
                        this.statistics.responsibleUsersRating = data.items;
                    }

                } else {
                    //показываем сообщение об ошибке
                    bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    return;
                }
            }
        },

        /**
         * Перезагружает список
         */
        refresh() {
            this.requestsStatuses = [];
            this.loadData();
        },

        /**
         * Открывает настройки
         */
        openOptions() {
            this.showOptions = true;
        },
    },

    created() {
        //пытаемся восстановить кол-во дней
        localStorage.get("requests_summary_period").then((value) => {
            let p = this.periods.find((p) => p.id == value);
            if (p) {
                this.period = p.id;
            }
            //загружаем данные
            this.loadData();
        });
    },

    mounted() {
        //подписваемся на события
        bus.on("SBS_REQUEST_FORM_E_SAVE", this.refresh);
        bus.on("SBS_REQUEST_E_UPDATED", this.refresh);
    },

    unmounted() {
        //отписываемся от событий
        bus.off("SBS_REQUEST_FORM_E_SAVE", this.refresh);
        bus.off("SBS_REQUEST_E_UPDATED", this.refresh);
    },
};
</script>

<style lang="scss">
.sbs-requests-summary {
    .title-wrap {
        position: relative;
        padding-right: 40px;

        .options-btn {
            position: absolute;
            top: 50%;
            right: -15px;
            transform: translateY(-50%);
            padding: 10px;
            color: var(--color-icon-active);
            font-size: 20px;
        }
    }

    .title {
        margin-bottom: 20px;
    }

    .options {
        .option {
            padding: 5px 0px;
        }
    }
}
</style>