import bus from '@/plugins/bus'
import { VoiceRecorder } from '@independo/capacitor-voice-recorder';
import errorListiner from '@/plugins/error.listiner'
import moment from "moment";

export default {

    start: 0,

    /**
     * Инициализация
     */
    init() {
        //подписываемся на событие вызова начала записи
        bus.on('SBS_VOICE_CAPTURE_E_START', (params) => {
            this.prepare(params);
        });

        //подписываемся на событие вызова завершения записи
        bus.on('SBS_VOICE_CAPTURE_E_END', (params) => {
            this.end(params);
        });


    },

    /**
     * Подготовка
     */
    async prepare(params) {
        //проверяем вообще доступна ли запись аудио
        let result = await VoiceRecorder.canDeviceVoiceRecord();
        if (!result.value) {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: "Запись аудио на данном устройстве недоступна." });
            params.onError();
            return;
        }

        //проверяем есть ли разрешение записывать аудио
        let has = false;
        try {
            result = await VoiceRecorder.hasAudioRecordingPermission();
            has = result.value;
        }
        catch (error) {
            has = false;
        }

        //если прав нет
        if (!has) {
            //запрашиваем права
            result = await VoiceRecorder.requestAudioRecordingPermission();
            if (!result.value) {
                //показываем сообщение об ошибке
                bus.emit("SBS_MESSAGE_E_SHOW", { message: "У приложения нет прав на запись аудио." });
                params.onError();
                return;
            }
        }

        //начинаем запись
        this.start =  parseInt(moment().format("X"));
        VoiceRecorder.startRecording()
            .then(() => {
                params.onStart();
            })
            .catch((error) => {
                //показываем сообщение об ошибке
                bus.emit("SBS_MESSAGE_E_SHOW", { message: "Ошибка записи аудио сообщения." });
                params.onError();
                errorListiner.onError(error);
            });


    },

    /**
     * Завершение записи
     */
    end(params) {
        let duration = parseInt(moment().format("X")) - this.start;
        //начинаем запись
        VoiceRecorder.stopRecording()
            .then((result) => {
                params.onEnd(result.value);
            })
            .catch((error) => {
                //показываем сообщение об ошибке
                if(duration <=2) {
                    bus.emit("SBS_MESSAGE_E_SHOW", { message: "Удерживайте для записи звука." });
                } else {
                    bus.emit("SBS_MESSAGE_E_SHOW", { message: "Ошибка записи аудио сообщения." });
                    errorListiner.onError(error);
                }
                
                params.onError();
                
            });
    }

}