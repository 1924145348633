<template>
    <sbs-control-input v-model="value" type="text" :label="label" :name="name" :required="required"
        class="sbs-control-time-duration" :mask="{ mask: '9', tokens: { 9: { pattern: /[0-9]/, repeated: true } } }">

        <template v-slot:bottom>
            <div class="types">
                <span class="type" v-for="item in types" :key="item.code" :class="{ selected: this.type == item.code }"
                    @click="onTypeClick(item.code)"> {{
                        item.name }}
                </span>
                <span class="after">после создания</span>
            </div>
        </template>
    </sbs-control-input>
</template>

<script>
import sbsControlInput from "@/components/controls/input.vue";

export default {
    name: "sbs-control-time-duration",
    components: {
        sbsControlInput
    },
    data() {
        return {
            types: [
                {
                    code: "days",
                    name: "дней"
                },
                {
                    code: "hours",
                    name: "часов"
                },
                {
                    code: "minutes",
                    name: "минут"
                },
            ],
            type: this.getType(),
            value: this.getValue(),
        };
    },
    emits: ["update:modelValue"],
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        name: {
            type: String,
            default() {
                return "";
            },
        },
        modelValue: {
            type: [String, Number],
            default() {
                return "";
            },
        },
        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        factor() {
            if (this.type == "days") {
                return 24 * 60 * 60;
            } else if (this.type == "hours") {
                return 60 * 60;
            }

            return 60;
        }
    },
    watch: {
        modelValue: function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.type = this.getType();
                this.value = this.getValue();
            }
        },
        value: function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (newVal == "") {
                    this.$emit("update:modelValue", "");
                } else {
                    this.$emit("update:modelValue", this.value * this.factor);
                }

            }
        },

    },
    methods: {

        onTypeClick(code) {
            if (this.type != code) {
                this.type = code;
                if (this.value != "") {
                    this.$emit("update:modelValue", this.value * this.factor);
                }
            }
        },

        /**
         * Устанавилвает тип длительности по значению
         */
        getType() {
            if (this.modelValue >= 24 * 60 * 60) {
                return "days";
            } else if (this.modelValue >= 60 * 60) {
                return "hours";
            }

            return "minutes"
        },

        /**
         * Устанавилвает внутреннее значение
         */
        getValue() {
            if (this.modelValue == "") {
                return "";
            }

            let v = parseInt(this.modelValue);

            if (v >= 24 * 60 * 60) {
                return v / (24 * 60 * 60)
            } else if (v >= 60 * 60) {
                return v / (60 * 60);
            }

            return v / 60;

        }

    },

};
</script>


<style lang="scss">
.sbs-control-time-duration {
    margin-bottom: 60px !important;

    .types {
        position: absolute;
        left: 20px;
        top: 100%;
        margin-top: 5px;

        color: var(--color-control-description);
    }

    .type {
        display: inline-block;
        margin-right: 7px;
        border-bottom: 1px dashed currentColor;

        &.selected {
            border-bottom-color: transparent;
            color: var(--color-text-primary);
        }
    }
}
</style>
