import bus from '@/plugins/bus'
import utils from '@/plugins/utils'
import moment from "moment";
import semver from 'semver'
import errorListiner from '@/plugins/error.listiner'
import qrScannerNative5 from '@/plugins/capacitor.qr.scanner5'
import qrScannerNative6 from '@/plugins/capacitor.qr.scanner6'
import nfcScannerNative from '@/plugins/capacitor.nfc.scaner'

import googlePush from '@/plugins/capacitor.push'
import hmsPush from '@/plugins/capacitor.hms.push'
import hmsLocalNotifications from '@/plugins/capacitor.hms.localnotifications'

import download from '@/plugins/capacitor.download'
import speech from '@/plugins/capacitor.speech'
import voice from '@/plugins/capacitor.voice'
import store from '@/store'

import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { PowerOptimization } from '@awesome-cordova-plugins/power-optimization';
import { LocalNotifications } from '@capacitor/local-notifications'
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/';
import { Filesystem,Directory } from '@capacitor/filesystem'
import { Keyboard } from '@capacitor/keyboard';
import { Badge } from '@capawesome/capacitor-badge';


export default {

    /**
     * Инициализирует плагины
     */
    init() {
        if (Capacitor.isNativePlatform()) {
            //инициализируем работу со статус баром
            this.processStatusBar();
            if (Capacitor.getPlatform() === 'android') {
                //инициализируем работу с навигационным баром
                this.processNavigationBar();
                //инициализируем работу с плагином выключения оптимизации приложения
                this.processPowerOptimization();
            } else if (Capacitor.getPlatform() == 'ios') {
                //значок кол-ва сообщений у иконки приложения
                this.processBadge();
            }
            //инициализируем плагин захвата медиа
            this.processMediaCapture();
            //чтение файлов
            this.processFileRead();
            //события клавиатуры
            this.processKeybord();
            
            //инициализируем плагин считываний QR
            this.processBarcodeScanner();

            //инициализирцуем плагин NFC
            nfcScannerNative.init();
            //инициализируем распознование голоса
            speech.init();

            //хак если есть объект push HMS (есть Honor, у которых доступен Google Play, соответственно не установлен HMS)
            if(window.HmsPushEvent) {
                //локальные уведомления уведомлений
                hmsLocalNotifications.init();
                //push
                hmsPush.init();
            } else {
                //локальные уведомления уведомлений
                this.processLocalNotifications();
                //push
                googlePush.init();
            }



        }

        //камера
        this.processCamera();
        //запись аудио
        voice.init();
        //скачивание файлов
        download.init();
    },

    /**
    * Статус бар - изменение цвет в зависимости от экрана
    */
    processStatusBar() {

        var onThemeChange = () => {
            let root = document.documentElement;
            var styles = window.getComputedStyle(root);
            var color = styles.getPropertyValue('--color-background');
            if (color) {
                color = color.trim();
            }

            
            if (root.dataset.theme == 'light') {
                StatusBar.setStyle({ style: Style.Light });
            } else {
                StatusBar.setStyle({ style: Style.Dark });
            }
            
            //только для Android
            if (Capacitor.getPlatform() === 'android') {
                StatusBar.setBackgroundColor({ color: utils.convertHexColor(color) });
            }

            //только для Android
            if (Capacitor.getPlatform() === 'android') {
                //если плагин безопасной зоны экрана доступен
                if(Capacitor.isPluginAvailable('SafeArea')) {
                    StatusBar.setOverlaysWebView({ overlay: true });
                } else {
                    StatusBar.setOverlaysWebView({ overlay: false });
                }
            }
            
        };

        //подписываемся на событие завершения смены темы
        bus.on('SBS_APP_E_THEME_CHANGED', onThemeChange);

        //запускаем при инициалиазции
        onThemeChange();

    },

    /**
     * Навигационный бар - изменение цвет в зависимости от экрана
     */
    processNavigationBar() {

        var onThemeChange = () => {
            let root = document.documentElement;
            var styles = window.getComputedStyle(root);
            var color = styles.getPropertyValue('--color-background');
            if (color) {
                color = color.trim();
            }

            var isLight = root.dataset.theme == 'light';

            NavigationBar.setColor({ color: utils.convertHexColor(color), darkButtons: isLight });
        }
        //подписываемся на событие завершения смены темы
        bus.on('SBS_APP_E_THEME_CHANGED', onThemeChange);

        //запускаем при инициалиазции
        onThemeChange();
    },

    /**
     * Инициализация плагина камеры
     */
    processCamera() {

        //подписываемся на событие получения фото с камеры
        bus.on('SBS_CAMERA_E_GET_PICTURE', (params) => {

            Camera.getPhoto({
                quality: 70,
                resultType: CameraResultType.Base64,
                correctOrientation: true,
                source: params.photoLibrary ? CameraSource.Photos : CameraSource.Camera
            }).then((image) => {
                if (params.resolve) {
                    params.resolve(image.base64String);
                }
            }).catch((e) => {
                if(e.message != 'User cancelled photos app') {
                    errorListiner.onError(e);
                    console.log(e);
                }
            });

        });

    },

    /**
     * Инициализация плагина настроек оптимизатора памяти
     */
    processPowerOptimization() {

        bus.on('SBS_POWER_OPTIMIZATIO_E_CHECK', () => {

            //проверяем выклюяена ли оптимизация
            PowerOptimization.IsIgnoringBatteryOptimizations().then((result) => {

                //если да - ничего не надо делать
                if (result) {
                    return;
                }

                //спрашиваем завершить ли заявку
                bus.emit("SBS_CONFIRM_E_SHOW", {
                    message: "Для стабильной работы приложения необходимо выключить оптимизацию работы приложения.",
                    resolveText: "Выключить",
                    rejectText: "Отменить",
                    resolve: function (value) {
                        if (value) {
                            //запрашиваем отключение оптимизации приложения
                            PowerOptimization.RequestOptimizations().then(() => {

                            }, (err) => {
                                alert(err);
                            });
                        }
                    },
                    reverse: false,
                });



            }, (err) => {
                alert(err);
            });

        });

    },

    /**
     * Инициализация плагина локальных уведомлений
     */
    processLocalNotifications() {

        //подписываемся на событие необходимости показа локального уведомления
        bus.on('SBS_NOTIFICATION_E_SHOW', async (data) => {

            let status = await LocalNotifications.checkPermissions();
            if(status.display == 'prompt' || status.display == 'prompt-with-rationale') {
                status = await LocalNotifications.requestPermissions();
            }
            if (status.display !== 'granted') {
                return;
            }

            let arSchedule = {
                id: parseInt(moment().format("X")),
                title: data.title,
                body: data.body,
                extra: data
            };
            if (data.image) {
                arSchedule["Attachments"] = [
                    {
                        id: data.image,
                        url: data.image
                    }
                ];
            }

            let ignore = false;
            
            //если пуш о новом сообщении в чате
            if(data.type == "request_message") {
                //если сообщение уже просмотрено
                let stask = store.getters.getReadMessagesStack;
                if(stask.includes(data.messageId)) {
                    ignore = true;
                }
            }

            if(!ignore) {
                LocalNotifications.schedule({ notifications: [arSchedule] });
            }

            //обновление счётчика у иконки приложения (а то локальная нотификация сбрасывает кол-во у иконки приложения)
            bus.emit("SBS_BADGE_E_SET", store.getters.getBadgetCount);

        });

        //обработка действия по уведомлению
        LocalNotifications.addListener('localNotificationActionPerformed', (event) => {
            googlePush.onPushClick(event.notification.extra);
        });

    },

    /**
     * Захват медио (аудио, видео, фото)
     */
    async processMediaCapture() {

        //подписываемся на событие вызова
        bus.on('SBS_VIDEO_CAPTURE_E_OPEN', (params) => {

            if (!params.resolve) {
                return;
            }

            MediaCapture.captureVideo(params.options).then((data) => {

                //errorListiner.onError({
                //    message: "1. Получено видео, кол-во = " + data.length
                //});

                if (data.length == 0) {
                    return;
                }

                let file = data[0];

                let fileName = file.fullPath.indexOf('file://') === -1 ? 'file://'+file.fullPath : file.fullPath;

                //errorListiner.onError({
                //    message: "2. Название файла видео " + fileName
                //});

                //копируем файл в кеш приложения (ios может только оттуда считывать файлы)
                Filesystem.copy({
                    from: fileName,
                    to: file.name,
                    toDirectory: Directory.Cache
                }).then(async () => {

                    //errorListiner.onError({
                    //    message: "3. Видео скопировано в папку Cache"
                    //});

                    //считываем файл в формате base64
                    Filesystem.readFile({
                        directory: Directory.Cache,
                        path: file.name
                    }).then(
                        async (file2) => {

                            //errorListiner.onError({
                            //    message: "4. Видео считано, тип = " +file.type
                            //});

                            //конвертируем строку base64 в blob
                            let blob = utils.b64toBlob(file2.data, file.type);

                            //errorListiner.onError({
                            //    message: "4. Видео сконвертировано в blob"
                            //});

                            params.resolve({
                                //base64: file2.data,
                                type: file.type,
                                blob: blob
                            });

                    }).catch((e) => {
                        errorListiner.onError({
                            message: "4. Ошибка считывания видео"
                        });
                        errorListiner.onError(e);
                        console.log(e);
                    });

                        
                }).catch((e) => {
                    errorListiner.onError({
                        message: "3. Ошибка копирования видео"
                    });
                    errorListiner.onError(e);
                    console.log(e);
                });

            }).catch((e) => {
                errorListiner.onError({
                    message: "1. Ошибка получения видео"
                });
                errorListiner.onError(e);
                console.log(e);
            });
        });
    },

    /**
     * Чтение файла
     */
    processFileRead() {

        bus.on('SBS_FILE_E_READ', (params) => {
            Filesystem.readFile(params.options).then(
                () => {
                    console.log("readed");
                });
        });
    },


    /**
     * События клавиатуры
     */
    processKeybord() {

        if(!Capacitor.isPluginAvailable('Keyboard')) {
            return;
        }

        if(Capacitor.getPlatform() == "ios") {
            //отклочаем автоматическу прокрутку к полю ввода
            Keyboard.setScroll({isDisabled: true});
        }

        Keyboard.addListener('keyboardWillShow', info => {
            bus.emit('SBS_KEYBOARD_E_WILL_SHOW',info);
        });

        Keyboard.addListener('keyboardDidShow', info => {
            let inputFocused = document.querySelector("input[type=text]:focus,input[type=password]:focus,textarea:focus");
            if (inputFocused) {
                inputFocused.scrollIntoView(false);
            }
            bus.emit('SBS_KEYBOARD_E_DID_SHOW',info);
        });

        Keyboard.addListener('keyboardWillHide', () => {
            bus.emit('SBS_KEYBOARD_E_WILL_HIDE');
        });

        Keyboard.addListener('keyboardDidHide', () => {
            bus.emit('SBS_KEYBOARD_E_DID_HIDE');
        });

    },

    /**
     * Плагин управления кол-ва сообщений у иконки приложения
     */
    async processBadge() {

        if(!Capacitor.isPluginAvailable('Badge')) {
            return;
        }

        let supported = await Badge.isSupported();
        if(!supported.isSupported) {
            //Badge not supported
            return;
        }

        let result = await Badge.checkPermissions();
        if(result.display != 'granted') {
            result =  await Badge.requestPermissions();
        }   

        if(result.display != 'granted') {
            //Badge not granted
            return;
        }

        bus.on('SBS_BADGE_E_SET', async (count) => {
            Badge.set({ count: count });
        });

        //первоначальная установка
        bus.emit("SBS_BADGE_E_SET", store.getters.getBadgetCount);
    },

    /**
     * Сканер QR
     */
    processBarcodeScanner() {

        //узнаём версию нативного приложения
        App.getInfo().then((info) => {

            //если версия приложения старая
            if (semver.lt(info.version, "2.0.0")) {
                console.log("инициализирцем старый плагин QR");
                //инициализирцем старый плагин QR
                qrScannerNative5.init();
            } else {
                console.log("инициализирцем новый плагин QR");
                //инициализирцем новый плагин QR
                qrScannerNative6.init();
            }

        });

        

    }
}