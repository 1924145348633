<template>
    <sbs-view :class="'sbs-v-equipment-form'" :isMenuHidden="hideMenu" :refreshDisabled="true">

        <template v-slot:header>
            <sbs-header>
                <template v-slot:right> </template>
            </sbs-header>
        </template>

        <template v-slot:content>

            <div class="h1">
                <template v-if="mode == 'ADD'">{{ $t("equipment.form.add") }}</template>
                <template v-else>{{ $t("equipment.form.edit") }}</template>
            </div>

            <template v-if="loading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>

            <template v-else>

                <div class="form" ref="form">

                    <sbs-control-checkbox v-model="fields.active" value="Y" defaultValue="N"
                        :label="$t('equipment.form.active')" />

                    <sbs-control-input v-model="fields.name" type="text" :label="$t('equipment.form.name')"
                        :required="true" :noLabelAnimation="setFieldsFilled" :maxlength="255">
                        <template v-if="isNativePlatform" v-slot:icon>
                            <div class="icon" @click="speechField('name')">
                                <span class="fi fi-mic"></span>
                            </div>
                        </template>
                    </sbs-control-input>

                    <sbs-control-input v-model="props.short_name" type="text" :label="$t('equipment.form.short')"
                        :noLabelAnimation="setFieldsFilled" :maxlength="255">
                        <template v-if="isNativePlatform" v-slot:icon>
                            <div class="icon" @click="speechField('short_name')">
                                <span class="fi fi-mic"></span>
                            </div>
                        </template>
                    </sbs-control-input>

                    <sbs-control-input v-model="fields.external_code" type="text"
                        :label="$t('equipment.form.external_code')" :noLabelAnimation="setFieldsFilled"
                        :maxlength="255" />

                    <sbs-control-select v-model="props.department_id" :modelName="info.departmentName" :required="true"
                        :label="$t('equipment.form.department')" :noLabelAnimation="setFieldsFilled"
                        method="department.list" :params="{ filter: departmentsFilter }" />

                    <sbs-control-select v-model="props.type_id" :modelName="info.typeName" :required="true"
                        :label="$t('equipment.form.type')" :noLabelAnimation="setFieldsFilled"
                        method="dictionary.equipment.type.list" />

                    <sbs-control-input v-model="props.qr_code" type="text" :label="$t('equipment.form.qr')"
                        :noLabelAnimation="setFieldsFilled" :maxlength="255">
                        <template v-if="isNativePlatform" v-slot:icon>
                            <div class="icon" @click="scanQR">
                                <span class="fi fi-qr-scanner"></span>
                            </div>
                        </template>
                    </sbs-control-input>

                    <sbs-control-input v-model="props.nfc_id" type="text" :label="$t('equipment.form.nfc')"
                        :noLabelAnimation="setFieldsFilled">
                        <template v-if="isNativePlatform" v-slot:icon>
                            <div class="icon" @click="scanNFC">
                                <span class="fi fi-nfc"></span>
                            </div>
                        </template>
                    </sbs-control-input>

                    <sbs-control-textarea v-model="fields.description" :label="$t('equipment.form.description')"
                        :noLabelAnimation="setFieldsFilled">
                        <template v-if="isNativePlatform" v-slot:icon>
                            <div class="icon" @click="speechField('description')">
                                <span class="fi fi-mic"></span>
                            </div>
                        </template>
                    </sbs-control-textarea>


                    <sbs-control-textarea v-model="fields.detail_text" :label="$t('equipment.form.detail_text')"
                        :noLabelAnimation="setFieldsFilled" :rows="10">

                        <template v-if="isNativePlatform" v-slot:icon>
                            <div class="icon" @click="speechField('detail_text')">
                                <span class="fi fi-mic"></span>
                            </div>
                        </template>
                    </sbs-control-textarea>

                    <sbs-control-photos v-model="props.photo" :label="$t('equipment.form.photo')" />

                </div>

                <div ref="entity">
                    <sbs-fields-edit v-model="entityFields" entity="EQUIPMENT" :settingsKey="props.type_id" :id="id" />
                </div>

                <div class="btn btn1 btn-block btn-save" :class="saving ? 'wait' : ''" v-ripple.300 @click="save">

                    <template v-if="mode == 'ADD'">{{ $t("common.add") }}</template>
                    <template v-else>{{ $t("common.save") }}</template>
                    <div v-if="saving" class="btn-loader"></div>
                </div>

            </template>

        </template>
    </sbs-view>

</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlSelect from "@/components/controls/select.vue";
import sbsControlTextarea from "@/components/controls/textarea.vue";
import sbsControlPhotos from "@/components/controls/photos.vue";
import sbsFieldsEdit from "@/components/fields.edit.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";
import validator from "@/plugins/validator";

import { mapGetters } from "vuex";

export default {
    name: "sbs-v-equipment-form",
    components: {
        sbsView,
        sbsHeader,
        sbsLoaderInline,
        sbsControlCheckbox,
        sbsControlInput,
        sbsControlSelect,
        sbsControlTextarea,
        sbsControlPhotos,
        sbsFieldsEdit
    },
    data() {
        return {
            showConfirm: false,
            checkLeave: true,
            hideMenu: false,
            skipReload: false,

            loading: false,
            fields: {},
            props: {},
            entityFields: {},
            setFieldsFilled: false,
            saving: false,
            info: {},
            equipment: false,
            copy: false,
        };
    },
    props: {
        id: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    computed: {
        ...mapGetters({
            isNativePlatform: "isNativePlatform",
            access: "getAccess"
        }),
        mode() {
            return this.id > 0 ? "EDIT" : "ADD";
        },

        /**
         * Данные, передаваемые между экранами
         */
        tempData() {
            return this.$store.getters.getTempData("equipment-form-data")
        },

        permissions() {
            if (!this.access || !this.access.permissions.equipment) {
                return [];
            }

            return this.access.permissions.equipment;
        },

        ownDepartments() {
            if (!this.access || !this.access.own_departments) {
                return [];
            }
            return this.access.own_departments;
        },

        accessDepartments() {
            if (!this.access || !this.access.equipment_access_departments) {
                return [];
            }
            return this.access.equipment_access_departments;
        },

        /**
         * Фильтр для подразделений
         */
        departmentsFilter() {

            let filter = {};
            //если доступ на управление всем оборудованием
            if (this.permissions.includes('MANAGE_ALL')) {
                return filter;
            }

            filter = {
                "LOGIC": "OR"
            };
            //если доступ на управление оборудованием своего подразделения 
            if (this.permissions.includes('MANAGE_OWN_DEPARTMENT')) {
                filter["0"] = { ID: this.ownDepartments };
            }
            //если доступ на управление оборудованием выбранных пдразделений
            if (this.permissions.includes('MANAGE_SELECTED_DEPARTMENTS')) {
                filter["1"] = { ID: this.accessDepartments };
            }

            return filter

        }
    },

    watch: {
        id: function () {
            if (this.id > 0) {
                this.loadData();
            } else {
                this.clear();
            }
        },
    },

    methods: {

        /**
         * Очищает форму
         */
        clear() {
            this.fields = {
                name: "",
                active: "Y",
                external_code: "",
                description: "",
                detail_text: "",
            };

            this.props = {
                department_id: "",
                type_id: "",
                short_name: "",
                nfc_id: "",
                qr_code: "",
                photo: []
            };

            this.info = {
                departmentName: "",
                typeName: ""
            };

            this.equipment = false;

        },

        /**
         * Загружает данные
         */
        loadData() {

            this.loading = true;
            this.clear();

            rest.call("equipment.get", {
                method: "post",
                data: {
                    id: this.id,
                    select: ["qr_code", "nfc_id"]
                },
            })
                .then((data) => {
                    if (data.success) {
                        this.setFields(data.equipment);
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }

                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Устанавливает поля формы по данным оборудования
         * @param Object type данные типа
         */
        setFields(equipment) {
            //установим у полей флаг как буд-то бы поля заполнены
            //чтобы метки поднялись без анимации
            this.setFieldsFilled = true;

            for (let code in this.fields) {
                this.fields[code] = equipment[code];
            }

            for (let code in this.props) {
                let prop = equipment.props.find(p => p.code.toLowerCase() == code);
                if (!prop) {
                    if (equipment[code]) {
                        this.props[code] = equipment[code];
                    }
                    continue;
                }
                this.props[code] = prop.link_id ? prop.link_id : prop.value
                if (prop.code == "DEPARTMENT_ID") {
                    this.info.departmentName = prop.value
                } else if (prop.code == "TYPE_ID") {
                    this.info.typeName = prop.value
                }

            }

            //снимаем флаг
            let vm = this;
            setTimeout(() => {
                vm.setFieldsFilled = false;
            }, 100);
        },

        /**
         * Распознование речи для поля ввода
         */
        speechField(field) {
            bus.emit("SBS_SPEECH_RECOGNITION_E_GET", {
                resolve: (text) => {
                    this.fields[field] = text;
                },
            });
        },

        /**
         * Сохранение
         */
        save() {
            //проверка введённых данных
            let formValid = validator.check({ container: this.$refs.form });
            let entityValid = validator.check({ container: this.$refs.entity });
            if (!formValid || !entityValid) {
                return;
            }

            if (this.saving) {
                return;
            }
            this.saving = true;

            //выполняем сохранение
            rest
                .call("equipment." + (this.mode == "ADD" ? "add" : "update"), {
                    method: "post",
                    data: {
                        id: this.id,
                        fields: this.fields,
                        props: this.props,
                        entityFields: this.entityFields
                    },
                })
                .then((data) => {
                    if (data.success) {
                        this.onSave(data.id);
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .finally(() => {
                    this.saving = false;
                });

        },

        /**
        * Обработка успешного сохранения
        */
        onSave(id) {
            //показываем сообщение об Успехе
            bus.emit("SBS_MESSAGE_E_SHOW", {
                message: this.$t("common.save_success"),
                type: "success",
            });

            //запрещает экрану запрашивать у пользователя подтверждение выхода с экрана
            this.checkLeave = false;

            //генерим событие успешного сохранения
            bus.emit("SBS_EQUIPMENT_E_SAVE", { id: id, fields: this.fields });

            //переход обратно
            this.$router.go(-1);

            //если режим создания
            if (this.mode == "ADD") {
                //очищаем форму с задержкой (пока переход анимируется ждем)
                let vm = this;
                setTimeout(function () {
                    vm.clear();
                }, 500);
            }

        },

        /**
         * Сканирует QR-код и вставляет в форму
         */
        scanQR() {

            let vm = this;
            this.skipReload = true;
            bus.emit("SBS_QR_SCANNER_E_OPEN", {
                mode: "SIMPLE",
                resolve: function (code) {
                    vm.props.qr_code = code;
                },
            });
        },

        /**
         * Привязать NFC-метку
         */
        scanNFC() {

            let vm = this;
            this.skipReload = true;
            bus.emit("SBS_NFC_SCANNER_E_OPEN", {
                mode: "SIMPLE",
                resolve: function (code) {
                    vm.props.nfc_id = code;
                },
            });
        },

        /**
         * Установка полей из временных данных
         */
        setFieldsFromTempData() {

            if (this.tempData) {
                if (this.tempData.qr_code) {
                    this.props.qr_code = this.tempData.qr_code;
                }
                if (this.tempData.nfc_id) {
                    this.props.nfc_id = this.tempData.nfc_id;
                }

                this.$store.commit("setTempData", { name: "equipment-form-data", value: false });
            }
        }
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.clear();
        this.setFieldsFromTempData();

        if (this.id > 0) {
            this.loadData();
        }
    },

    /**
     * Вызывается перед переходом в этот экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            //выставляем необходимость подтверждения выхода из экрана
            vm.checkLeave = true;
            if (vm.id > 0 && !vm.skipReload) {
                vm.loadData();
            }

            vm.setFieldsFromTempData();
            vm.skipReload = false;
        });
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    async beforeRouteLeave(to) {
        if (to.meta.skipConfirm) {
            return true;
        }

        if (this.checkLeave) {
            let result = await new Promise((resolve) => {
                bus.emit("SBS_CONFIRM_E_SHOW", {
                    message:
                        this.mode == "ADD"
                            ? "Отменить создание оброрудования?"
                            : "Отменить изменение оброрудования?",
                    resolveText: "Продолжить заполнение",
                    rejectText: "Отменить",
                    resolve: function (value) {
                        resolve(value);
                    },
                    reverse: true,
                });
            });

            return result;
        }
        return true;
    },
}

</script>

<style lang="scss">
.sbs-v-equipment-form {
    .btn-save {
        margin-bottom: 40px;
    }
}
</style>