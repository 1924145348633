<template>
    <div class="sbs-control-select-multiple form-group form-group-label form-control-icon" :class="[
        values.length > 0 ? 'filled' : '',
        noLabelAnimation && values.length > 0 ? 'no-label-animation' : '',
        open ? 'open' : '']" ref="el">

        <template v-if="label != ''">
            <div class="floating-label" v-on:click="onLabelClick">
                {{ label }}
                <span v-if="required && needShowRequired">*</span>
            </div>
        </template>

        <div class="form-control" @click="onClick">

            <TransitionGroup v-if="values.length > 0" class="values" name="values" tag="div">
                <div v-for="value in displayValues" :key="value.id" class="value btn btn1"
                    @click.stop="removeValue(value.id)">
                    {{ value.name }}
                    <span class="icon fi fi-close"></span>
                </div>
            </TransitionGroup>

            <div class="icon">
                <span class="fi fi-more"></span>
            </div>

        </div>

        <input type="hidden" :class="required ? 'required' : ''" :value="values.join(',')" />

        <div v-if="description" v-html="description" class="description"></div>

        <teleport to="#app">
            <sbs-dialog-select v-model:visible="open" :title="label" :multiple="true"
                v-model:modelValues="selectedValues" :list="list" />
        </teleport>

    </div>
</template>

<script>
import sbsDialogSelect from "@/components/dialogs/select.vue";

export default {
    name: "sbs-control-select-multiple",
    components: {
        sbsDialogSelect
    },
    emits: ["update:modelValue"],
    data() {
        return {
            values: this.modelValue,
            selectedValues: [],
            open: false,
            loaded: false,
            items: this.list,
            search: "",
        };
    },
    props: {
        label: {
            type: String,
            default() {
                return "";
            },
        },
        modelValue: {
            type: Array,
            default() {
                return [];
            },
        },
        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showRequired: {
            type: Boolean,
            default() {
                return true;
            },
        },
        description: {
            type: String,
            default() {
                return "";
            },
        },
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        noLabelAnimation: {
            type: Boolean,
            default() {
                return false;
            },
        },

    },
    computed: {

        needShowRequired() {
            return this.required && this.showRequired;
        },

        displayValues() {
            let items = [];

            this.values.forEach(v => {
                let item = this.items.find(i => i.id == v)
                if (item) {
                    items.push({
                        id: v,
                        name: item.name
                    })
                }
            });

            return items;
        },

    },
    watch: {
        modelValue: function () {
            this.values = this.modelValue;
            this.setSelectedValues();
        },
        open: function () {
            if (this.open) {
                this.items = this.list;
                this.loaded = true;
            }
        },

        selectedValues: function () {
            this.values = this.selectedValues.map(v => v.id);
            this.$emit("update:modelValue", this.values);
        }
    },
    methods: {

        /**
         * Клик по контролу
         */
        onClick() {
            this.open = !this.open;
        },

        onLabelClick() {
            if (this.values.length == 0) {
                this.open = !this.open;
            }
        },

        /**
         * Удаление значения
         */
        removeValue(id) {
            var index = this.values.indexOf(id);
            if (index !== -1) {
                this.values.splice(index, 1);
            }
        },

        /**
         * Устанавилвает выбранные варианты из values
         */
        setSelectedValues() {
            this.items.forEach(i => {
                if (this.values.includes(i.id)) {
                    this.selectedValues.push({ id: i.id, name: i.name })
                }
            })
        }

    },

    created() {

        //установим выбранные варианты
        this.setSelectedValues();
    }

}
</script>

<style lang="scss">
.sbs-control-select-multiple {

    .form-control::before {
        content: "\00a0";
    }

    &.filled {
        .form-control::before {
            display: none;
        }

        .form-control {
            padding-bottom: 5px;
        }
    }

    .form-control::after {
        content: "";
        display: block;
        clear: both;
    }

    .value {
        display: inline-block;
        text-transform: none;
        margin-bottom: 6px;
        padding: 5px 40px 5px 10px;
        font-size: 14px;
        margin-right: 6px;
        text-align: left;

        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
            font-size: 12px;
            color: currentColor !important;
        }
    }


    /**
        Аниция
    */

    .values-enter-active,
    .values-leave-active {
        transition: all 0.5s ease;
    }

    .values-enter-from,
    .values-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }
}
</style>