<template>
  <div class="sbs-user-item" @click="onItemClick(item)">
    <div class="photo">
      <div v-if="item.photo" class="picture" :style="{
    backgroundImage: 'url(' + item.photo.preview + ')',
  }"></div>
      <span v-else class="icon fi fi-user"></span>
    </div>

    <div>
      <div v-if="item.last_name || item.name" class="fio">
        {{ item.last_name }} {{ item.name }}
      </div>

      <div v-if="item.work_position" class="position">
        {{ item.work_position }}
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "sbs-user-item",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {

    ...mapGetters({
      service: "getService"
    }),
  },

  methods: {
    /**
     * Переход к пользователю
     */
    onItemClick(item) {
      this.$router.push({
        name: "user-view",
        params: { id: item.id, service: this.service.toLowerCase() },
      });
    },
  },
};
</script>

<style lang="scss">
.sbs-user-item {
  position: relative;
  padding: 10px;
  border-radius: 8px;
  background-color: var(--color-block-background);
  margin-bottom: 20px;

  width: 100%;
  display: inline-grid;
  align-items: center;
  grid-template-columns: 90px 1fr;
  grid-gap: 0px 10px;

  .photo {
    position: relative;
    width: 90px;
    height: 90px;
    border: 1px solid var(--color-block-label);

    border-radius: 50%;

    .picture {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-size: cover;
      border-radius: 50%;
    }

    .icon {
      font-size: 29px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--color-icon-common);
    }
  }

  .fio {
    font-size: 16px;
    color: var(--color-text-label);
    font-weight: 600;
  }

  .position {
    margin-top: 10px;
    color: var(--color-block-label);
    line-height: 1.1;
  }
}
</style>