<template>
    <div class="sbs-rt-fr">

        <sbs-control-checkbox v-model="fields.repeat" value="Y" label="Включить" />

        <div v-show="fields.repeat == 'Y'">

            <div class="form-group form-group-label filled">
                <div class="floating-label">
                    Периодичность
                </div>
                <div class="list">
                    <div class="item" v-for="item in periods" :key="item.code"
                        :class="{ selected: this.fields.repeat_period == item.code }" @click="onPeriodClick(item.code)">{{
                            item.name }}</div>
                </div>
            </div>

            <!-- Дневное рассписание -->
            <div v-show="this.fields.repeat_period == 'daily'" class="shedule">

                <div class="grid-wrap">
                    <div>каждый</div>
                    <div>
                        <sbs-control-input v-model="fields.repeat_params.daily.day" type="text"
                            :mask="{ mask: '9', tokens: { 9: { pattern: /[0-9]/, repeated: true } } }">
                        </sbs-control-input>
                    </div>
                    <div>день</div>
                </div>

            </div>

            <!-- Недельное рассписание -->
            <div v-show="this.fields.repeat_period == 'weekly'" class="shedule">

                <div class="grid-wrap">
                    <div>каждую</div>
                    <div>
                        <sbs-control-input v-model="fields.repeat_params.weekly.week" type="text"
                            :mask="{ mask: '9', tokens: { 9: { pattern: /[0-9]/, repeated: true } } }">
                        </sbs-control-input>
                    </div>
                    <div>неделю</div>
                </div>

                <div class="list">
                    <div class="item" v-for="item in daysShort" :key="item.id">
                        <div>{{ item.name }}</div>
                        <sbs-control-checkbox :value="item.id" :values="fields.repeat_params.weekly.days" :form="false"
                            :multiple="true" />
                    </div>
                </div>

            </div>

            <!-- Месячное рассписание -->
            <div v-show="this.fields.repeat_period == 'monthly'" class="shedule">
                <div class="grid-wrap">
                    <div>
                        <sbs-control-radio value="1" label="каждое" v-model="fields.repeat_params.monthly.type" />
                    </div>
                    <div>
                        <sbs-control-input v-model="fields.repeat_params.monthly.month_day" type="text"
                            :mask="{ mask: '9', tokens: { 9: { pattern: /[0-9]/, repeated: true } } }">
                        </sbs-control-input>
                    </div>
                    <div>
                        число
                    </div>
                </div>
                <div class="grid-wrap">
                    <div>
                        <sbs-control-radio value="2" label="каждый" v-model="fields.repeat_params.monthly.type" />
                    </div>
                    <div>
                        <sbs-control-input v-model="fields.repeat_params.monthly.week_num" type="text"
                            :mask="{ mask: '9', tokens: { 9: { pattern: /[0-9]/, repeated: true } } }">
                        </sbs-control-input>
                    </div>
                    <div>
                        <sbs-control-select v-model="fields.repeat_params.monthly.week_day" :list="daysFull" />

                    </div>
                </div>
            </div>

            <!-- Когда создавать -->
            <sbs-control-date v-model="fields.repeat_params.start_date" label="Начать повторение с" />

            <!-- Список времени -->
            <div class="times">
                <div class="item" :class="{ one: fields.repeat_params.times.length == 1 }"
                    v-for="(time, index) in fields.repeat_params.times" :key="index">
                    <sbs-control-time v-model="fields.repeat_params.times[index]" type="text"
                        :label="'Время создания заявки ' + (fields.repeat_params.times.length > 1 ? '#' + (index + 1) : '')"
                        :hideClear="true" />
                    <div class="delete" v-if="fields.repeat_params.times.length > 1" @click="deleteTime(index)">
                        <span class="fi fi-close"></span>
                    </div>
                </div>
                <div class="add" @click="addTime">+ добавить время</div>
            </div>

        </div>

    </div>
</template>

<script>
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlRadio from "@/components/controls/radio.vue";
import sbsControlSelect from "@/components/controls/select.vue";
import sbsControlTime from "@/components/controls/time.vue";
import sbsControlDate from "@/components/controls/date.vue";

import moment from "moment";

export default {
    name: "sbs-request-template-form-repeat",
    components: {
        sbsControlCheckbox,
        sbsControlInput,
        sbsControlRadio,
        sbsControlSelect,
        sbsControlTime,
        sbsControlDate
    },
    emits: ["update:modelValue"],
    data() {
        return {
            fields: this.modelValue,
            setFieldsFilled: false,
            periods: [
                {
                    code: "daily",
                    name: "день"
                },
                {
                    code: "weekly",
                    name: "неделя"
                },
                {
                    code: "monthly",
                    name: "месяц"
                }
            ],
        };
    },
    props: {
        modelValue: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        daysShort() {
            let items = [];
            let today = moment();
            let date = today.startOf('week').add(-1, 'd');
            for (let i = 0; i < 7; i++) {
                date = date.add(1, 'd');
                items.push({
                    id: i + 1,
                    name: date.format('dd')
                });
            }

            return items;
        },
        daysFull() {
            let items = [];
            let today = moment();
            let date = today.startOf('week').add(-1, 'd');
            for (let i = 0; i < 7; i++) {
                date = date.add(1, 'd');
                items.push({
                    id: i + 1,
                    name: date.format('dddd')
                });
            }

            return items;
        }
    },

    watch: {
        modelValue: function () {
            this.fields = this.modelValue;
        },
        fields: {
            handler() {
                this.$emit("update:modelValue", this.fields);
            },
            deep: true,
        },
    },

    methods: {

        /**
         * Клик по варианту периодичности
         */
        onPeriodClick(code) {
            this.fields.repeat_period = code;
        },

        /**
         * Добавляет в список ещё одно время создания
         */
        addTime() {
            this.fields.repeat_params.times.push("09:00");
        },

        /**
         * Удаляет из списка время
         */
        deleteTime(index) {
            this.fields.repeat_params.times = this.fields.repeat_params.times.filter((time, i) => i != index);
        }
    },

    created() {
        if (!this.fields.repeat_period) {
            this.fields.repeat_period = "daily";
        }
    }
}
</script>

<style lang="scss">
.sbs-rt-fr {

    .list {
        &::after {
            content: "";
            display: block;
            clear: both;
        }

        .item {
            float: left;
            padding: 7px 10px;
            cursor: pointer;

            border: 1px solid var(--color-control-border);
            border-right-width: 0px;
            background-color: var(--color-control-background);

            &:last-of-type {
                border-right-width: 1px;
            }

            &.selected {
                background-color: var(--color-btn1-background);
                color: var(--color-btn1-text);
                border-color: var(--color-btn1-border);
            }
        }
    }

    .shedule {
        margin-bottom: 45px;
    }

    .grid-wrap {
        display: grid;
        grid-template-columns: auto 60px 1fr;
        align-items: center;
        column-gap: 20px;
        margin-bottom: 20px;

        .form-group {
            margin-bottom: 0px;
        }
    }

    .times {
        .item {
            position: relative;
            padding-right: 40px;

            &.one {
                padding-right: 0px;
            }

            .delete {
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                padding: 10px;
                line-height: 1;
            }
        }

        .add {
            position: relative;
            top: -25px;
        }
    }
}
</style>
