<template>
  <sbs-view :class="'sbs-v-equipment-search'" :isMenuHidden="hideMenu" :refreshDisabled="true" ref="view">
    <template v-slot:header>
      <sbs-header />
    </template>
    <template v-slot:content>
      <div class="h1">Поиск оборудования</div>

      <sbs-equipment-search />
    </template>
  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsEquipmentSearch from "@/components/equipment/search.vue";

export default {
  name: "sbs-v-equipment-search",
  components: {
    sbsView,
    sbsHeader,
    sbsEquipmentSearch,
  },
  data() {
    return {
      hideMenu: false
    };
  },

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },
};
</script>
