<template>
  <div class="sbs-radio" :class="{
    checked: checked,
    nolabel: label == '',
    'form-group': form,
    'value-not-empty': !modelValue,
  }">
    <label>
      <div class="icon fi fi-radio-on icon-on"></div>
      <div class="icon fi fi-radio-off icon-off"></div>

      <input type="radio" :class="required ? 'required' : ''" :name="name" :value="value" v-model="checked"
        @change="onChange" />

      <template v-if="label != ''">
        <div class="label">
          {{ label }}
        </div>
      </template>
      <template v-else> &nbsp; </template>
    </label>
  </div>
</template>

<script>
export default {
  name: "sbs-control-radio",
  data() {
    return {
      checked: this.modelValue == this.value ? this.value : false,
    };
  },
  emits: ["update:modelValue"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
    value: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    modelValue: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    form: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    modelValue: function (newVal) {
      if (newVal == this.value) {
        this.checked = newVal;
      } else {
        this.checked = false;
      }
    },
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.value);
    },
  },
};
</script>

<style lang="scss">
.sbs-radio {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin: 5px 0px;

  &.nolabel {
    width: 20px;
    height: 20px;
    padding-left: 20px;
  }

  input {
    display: none;
  }

  label {
    margin-bottom: 0px;
    cursor: pointer;
    font-weight: normal;
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;

    font-size: 20px;
    padding: 0px !important;

    -webkit-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .icon-on {
    color: var(--color-radio-on);
  }

  .icon-off {
    color: var(--color-radio-off);
  }

  .icon-on {
    opacity: 0;
  }

  &.checked .icon-on {
    opacity: 1;
  }

  &.checked .icon-off {
    opacity: 0;
  }

  &.form-group {
    margin-bottom: 15px;
    display: block;
  }

  &.form-group-error {
    color: var(--color-control-label-error);
  }
}
</style>