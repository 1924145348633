<template>
    <sbs-view :class="'sbs-v-terms-of-use'" :isMenuHidden="hideMenu" @refresh="refresh" ref="view">
        <template v-slot:content>

            <!-- Загрузка -->
            <template v-if="loading">
                <div class="text-center">
                    <sbs-loader-inline />
                </div>
            </template>

            <template v-if="!loading && !error">
                <div class="h1">Пользовательское соглашение</div>
                <div class="text" v-html="text"></div>
            </template>

        </template>
        <template v-slot:footer v-if="!loading && !error">
            <div class="btn-wrap">
                <div class="btn btn1" @click="accept">Принять и продолжить</div>
            </div>
        </template>

    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import localStorage from '@/plugins/localStorage'

export default {
    name: "sbs-v-terms-of-use",
    components: {
        sbsView,
        sbsLoaderInline
    },
    data() {
        return {
            loading: true,
            error: false,
            hideMenu: false,
            text: ""
        };
    },

    methods: {

        /**
         * Загрузка данных
         */
        loadData(resolve = false) {
            this.loading = resolve ? false : true;
            this.error = false;

            //запрашиваем данные
            rest
                .call("system.termsofuse", {
                    method: "get"
                })
                .then((data) => {
                    if (data.success) {

                        this.text = data.html;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                        this.error = true;
                    }
                })
                .finally(() => {
                    //скроем экран загрузки
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Принять 
         */
        accept() {

            localStorage.set("terms_of_use_accepted", "Y");
            this.$store.commit("setTermsOfUseAccepted", true);

            //переход на главный экран
            this.$router.replace({ name: "home" });
        }
    },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
        this.loadData();
    },
}

</script>

<style lang="scss">
.sbs-v-terms-of-use {
    .btn-wrap {
        padding: 15px 20px;
        text-align: center;

    }
}
</style>