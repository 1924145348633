<template>
  <div class="sbs-dialog-select" :class="[visibleInternal ? 'show' : '']"
    :style="{ paddingTop: paddingTop + 'px', paddingBottom: paddingBottom + 'px' }">

    <div class="dialog-outer">
      <div class="dialog-header">
        <sbs-header></sbs-header>
        <div class="container">
          <div class="page-padding">
            <div class="h2" v-html="title"></div>

            <sbs-control-input v-model="search" placeholder="Поиск" class="search">
              <template v-slot:icon>
                <div v-if="search != ''" class="icon" @click="clearSearch()">
                  <span class="fi fi-close"></span>
                </div>
              </template>
            </sbs-control-input>
          </div>
        </div>
      </div>
      <div class="dialog-content">
        <div class="container">
          <div class="page-padding">

            <div class="options">

              <template v-if="!loaded">
                <div class="text-center">
                  <sbs-loader-inline />
                </div>
              </template>

              <template v-for="item in searchItems" :key="item.id">
                <div v-if="isOptionVisible(item)" class="option" :class="getOptionClasses(item)"
                  @click="onOptionClick(item.id, item.name)">
                  <div class="collapser" v-if="isOptionParent(item) && !this.search"
                    @click.stop="onCollapserClick(item)">
                    <span class="icon fi fi-arrow-down"></span>
                  </div>

                  <div v-html="formatName(item)"></div>
                  <div class="description" v-html="getDesciption(item)"></div>


                  <div class="control">
                    <template v-if="!multiple">
                      <sbs-control-radio :name="name" :value="item.id" v-model="value.id" />
                    </template>
                    <template v-else>

                      <div class="icon fi " :class="selected.includes(item.id) ? 'fi-checkbox-on' : 'fi-checkbox-off'">
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="dialog-footer">
        <div class="container">
          <div class="page-padding">

            <div v-if="isApplyEnabled" class="buttons">
              <div class="row">
                <div class="col-6">
                  <div class="btn btn-block btn2" @click="reset">Сбросить</div>
                </div>
                <div class="col-6">
                  <div class="btn btn-block btn1" @click="apply">Выбрать</div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>


<script>
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import sbsHeader from "@/components/header.vue";
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlInput from "@/components/controls/input.vue";
import sbsControlRadio from "@/components/controls/radio.vue";

import { mapGetters } from "vuex";

export default {
  name: "sbs-dialog-select",
  components: {
    sbsHeader,
    sbsLoaderInline,
    sbsControlInput,
    sbsControlRadio
  },
  emits: ["update:visible", "update:modelValue", "update:modelValues"],
  data() {
    return {
      value: this.modelValue,
      values: this.modelValues,
      selected: this.modelValues.map((v) => v.id),
      visibleInternal: this.visible,
      watchVisible: false,

      search: "",
      loaded: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      safeArea: "getSafeArea",
      platform: "getPlatform"
    }),
    paddingTop() {
      if (this.safeArea && this.safeArea.top > 0) {
        return this.safeArea.top;
      }

      return 0;
    },
    paddingBottom() {
      if (this.safeArea && this.safeArea.bottom > 0 && this.platform == "ios") {
        return this.safeArea.bottom;
      }
      return 0;
    },
    searchItems() {
      return this.searchByName(this.items);
    },
    hierarchy() {
      let temp = this.items.filter((item) => item.parent_id > 0);
      return temp.length > 0;
    },
    isApplyEnabled() {
      if (!this.multiple) {
        //если значение никакое не выбрано
        if (!this.value || !this.value.id) {
          return false;
        }

        //ищем значение среди результатов поиска
        let item = this.searchItems.find((item) => item.id == this.value.id);

        //если выбранного значения нет в результатах поиска
        if (!item) {
          return false;
        }
      }
      return true;
    },

  },
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    method: {
      type: String,
      default() {
        return "";
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
    modelValue: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
        };
      },
    },
    modelValues: {
      type: Array,
      default() {
        return [];
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },

  },
  watch: {
    selected: function (newVal) {
      let temp = this.items.filter((item) => newVal.includes(item.id));
      this.values = temp;
    },

    visible: function (newVal) {
      this.visibleInternal = newVal;
      if (newVal) {
        this.onOpen();
      }
    },
    visibleInternal: function (newVal) {
      if (!newVal) {

        if (this.watchVisible) {
          //эмитируем переход назад
          history.back();
        }
      }

      this.$emit("update:visible", newVal);
    },

    modelValues: function () {
      this.selected = this.modelValues.map((v) => v.id);
    },

    params: {
      handler() {
        this.loaded = false;
      },
      deep: true,
    },
  },
  methods: {

    /**
     * Открытие диалога
     */
    onOpen() {

      this.watchVisible = true;

      //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
      window.history.pushState({}, "", window.location.href + "?dialogOpen");

      //добавляем закрытие в стэк обработчиков возврата назад
      this.$store.commit("addToBackStack", this.closeFromBaсkBtn);


      //если метод получения задан
      if (this.method) {
        //загружаем REST методом
        this.load();
      } else {
        this.items = this.list;
        this.loaded = true;
      }
    },

    /**
     * Закрыть при нажатие Назад в браузере
     */
    closeFromBaсkBtn() {
      this.watchVisible = false;
      this.visibleInternal = false;
      //удаляем закрытие из стэка обработчиков возврата назад
      this.$store.commit("removeFromBackStack", this.closeFromBaсkBtn);
    },

    /**
     * Загрузка вариантов
     */
    load() {
      if (this.loaded) {
        return;
      }

      //запрашиваем данные
      rest
        .call(
          this.method,
          {
            data: this.params,
            method: "POST",
          },
          false
        ) //тихий режим если настройки уже были получены ранее
        .then((data) => {
          if (data.success) {
            this.items = data.items;
            this.loaded = true;
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },

    /**
     * Клик по опции
     * @param Strings id Ид опции
     */
    onOptionClick(id) {
      let valRef = this.items.find((i) => i.id == id);
      //получаем элемент оригинальным значением без реактивности
      let val = Object.assign({}, valRef);

      //обычный режим выбора "одно значение"
      if (!this.multiple) {
        this.value = val;
        this.apply();
      } else {

        //если значение уже есть в списке
        if (this.selected.includes(val.id)) {
          // то удалим
          let index = this.selected.indexOf(val.id);
          if (index !== -1) {
            this.selected.splice(index, 1);
            this.removeChildValues(val.id);
          }

        } else {
          //иначе добавим
          this.selected.push(val.id);
          //добавим нижестоящие по иерархии элементы
          this.addChildValues(val.id);
        }

        //создаём новый массив, чтобы вочер смог отследить
        this.selected = this.selected.filter(() => true);

      }

    },

    /**
     * Удаляет все нижестоящие значения (по depth_level)
     */
    removeChildValues(id) {

      //в режим поиска не используем групповые операции
      if (this.search) {
        return;
      }

      let item = this.searchItems.find(i => i.id == id);
      let index = this.searchItems.indexOf(item);

      //если последний элемент в списке
      if (index == this.searchItems.length - 1) {
        return;
      }

      //прохдоим следюущие за ним элементы
      for (let i = index + 1; i < this.searchItems.length; i++) {
        if (this.searchItems[i].depth_level && this.searchItems[i].depth_level > item.depth_level) {
          let i2 = this.selected.indexOf(this.searchItems[i].id);
          if (i2 !== -1) {
            this.selected.splice(i2, 1);
          }
        } else {
          break;
        }
      }

    },

    /**
     * Добавляет все нижестоящие значения (по depth_level)
     */
    addChildValues(id) {

      //в режим поиска не используем групповые операции
      if (this.search) {
        return;
      }

      let item = this.searchItems.find(i => i.id == id);
      let index = this.searchItems.indexOf(item);

      //если последний элемент в списке
      if (index == this.searchItems.length - 1) {
        return;
      }

      //прохдоим следюущие за ним элементы
      for (let i = index + 1; i < this.searchItems.length; i++) {
        //если по уровню иерархии он меньше
        if (this.searchItems[i].depth_level && this.searchItems[i].depth_level > item.depth_level) {
          //если ещё не в списке 
          if (!this.selected.includes(this.searchItems[i].id)) {
            this.selected.push(this.searchItems[i].id);
          }
        } else {
          break;
        }
      }

    },

    /**
     * Применение
     */
    apply() {
      //если кнопка применения не доступна
      if (!this.isApplyEnabled) {
        return;
      }

      if (!this.multiple) {
        this.value.r = Math.random();
        this.$emit("update:modelValue", this.value);
      } else {
        this.$emit("update:modelValues", this.values);
      }

      this.visibleInternal = false;
    },

    /**
     * Сбросить
     */
    reset() {
      if (!this.multiple) {
        this.value = {
          id: "",
          name: "",
        };
        this.$emit("update:modelValue", this.value);
      } else {
        this.values = [];
        this.$emit("update:modelValues", this.values);
      }
      this.visibleInternal = false;
    },

    /**
     * Производит поиск
     */
    searchByName(items) {
      if (this.search == "") {
        return items;
      }

      return items.filter(
        (item) => {
          let str = item.name + (this.method == "users.list" ? (" " + item.last_name) : "")
          return str.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        }

      );
    },

    /**
     * Очищает поиск
     */
    clearSearch() {
      this.search = "";
    },

    /**
     * Определяет является ли вариант родителем
     */
    isOptionParent(item) {
      if (item.id === null || item.id === false) {
        return false;
      }
      let childs = this.items.filter((child) => child.parent_id == item.id);
      return childs.length > 0;
    },

    /**
     * Определяет видин ли вариант
     */
    isOptionVisible(item) {
      //если не режим иерархии
      if (!this.hierarchy) {
        return true;
      }

      //если включен поиск
      if (this.search) {
        return true;
      }

      //получаем родителя
      var parent = this.getItemById(item.parent_id);
      if (!parent) {
        return true;
      }

      //если родитель не раскрыт
      if (!parent.expanded) {
        return false;
      }

      return true;
    },

    getOptionClasses(item) {
      let classes = {
        parent:
          (this.isOptionParent(item) ||
            (item.depth_level == 1 && this.hierarchy)) &&
          !this.search,
        expanded: item.expanded,
      };

      if (item.depth_level && !this.search) {
        classes["level-" + item.depth_level] = true;
      }

      return classes;
    },

    /**
     * Получает вариант по его ИД
     */
    getItemById(id) {
      return this.items.find((item) => item.id == id);
    },

    /**
     * Клик по коллапсеру родительского варианта
     */
    onCollapserClick(item) {
      item.expanded = !item.expanded;
    },

    formatName(item) {
      let name = item.name;
      if (this.method == "users.list") {
        name = item.last_name + " " + item.name;
      }
      if (item.count !== undefined) {
        name += " (" + item.count + ")";
      }
      return name;
    },

    /**
     * Возвращает описание к пункту
     */
    getDesciption(item) {
      if (this.method == "equipment.list") {
        return item.department_name;
      }

      return "";
    }
  },
};
</script>

<style lang="scss">
.sbs-dialog-select {
  display: none;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  z-index: 2000;
  background: var(--color-background);

  &.show {
    display: block;
  }

  .btn-close {
    display: inline-block;
    color: var(--color-text-label);
    font-size: 16px;
    padding-left: 22px;
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      font-size: 14px;
      color: var(--color-icon-active);
    }
  }

  .dialog-outer {
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr auto;
  }

  .dialog-content {
    overflow: hidden;
    overflow-y: scroll;
  }

  .dialog-footer {
    padding-top: 20px;
  }

  .option {
    position: relative;
    padding: 13px 45px 13px 20px;
    background: var(--color-block-background);
    margin-bottom: 2px;

    &:first-of-type {
      border-radius: 8px 8px 0px 0px;
    }

    .description {
      font-size: 11px;
      color: var(--color-text-label);
    }

    .control {
      position: absolute;
      top: 50%;
      right: 10px;

      transform: translateY(-50%);

      .icon {
        color: var(--color-radio-on);
      }
    }

    &.parent {
      padding-left: 40px;
    }

    &.level-2 {
      padding-left: 60px;
    }

    .collapser {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      width: 35px;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        color: var(--color-text-primary);

        transform: translate(-50%, -50%) rotate(-90deg);
        transition: transform 0.3s cubic-bezier(0.25, 1.07, 0.54, 0.92);
      }
    }

    &.expanded {
      .collapser {
        .icon {
          transform: translate(-50%, -50%) rotate(0deg);
        }
      }
    }
  }

  .buttons {
    padding-bottom: 20px;
  }
}
</style>
