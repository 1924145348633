<template>
  <div class="sbs-department-item" @click="onItemClick(item)">
    <div class="name">{{ item.name }}</div>
    <span class="icon fi fi-arrow-right"></span>
  </div>
</template>

<script>
export default {
  name: "sbs-department-item",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods: {
    /**
     * Переход к подразделению
     */
    onItemClick(item) {
      this.$router.push({
        name: "department-view",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss">
.sbs-department-item {
  position: relative;
  padding: 10px;
  padding-right: 45px;
  border-radius: 8px;
  background-color: var(--color-block-background);
  margin-bottom: 20px;

  .name {
    font-size: 14px;
    line-height: 1.2;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: var(--color-text-label);
  }
}
</style>