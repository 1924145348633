import localStorage from '@/plugins/localStorage'
const connection = {
    state: () => {
        return {
           
            //список подключений
            connections: {},
            //ключ текущего подключения
            curentConnection: "",
            //флаг, отраэающий что в данный момент идёт переключение аккаунтов
            connectionChanging: false,
        }
    },

    getters: {
        getConnections(state) {
            return state.connections;
        },
        getCurrentConnectionKey(state) {
            return state.curentConnection;
        },
        getConnection(state) {
            return state.connections[state.curentConnection] ? state.connections[state.curentConnection] : false;
        },
        isConnectionChanging(state) {
            return state.connectionChanging;
        },
        getAccessToken(state) {
            return state.connections[state.curentConnection] ? state.connections[state.curentConnection].access_token : null;
        },
        getRefreshToken(state) {
            return state.connections[state.curentConnection] ? state.connections[state.curentConnection].refresh_token : null;
        },
        getApiUrl(state) {
            return state.connections[state.curentConnection] ? "https://" + state.connections[state.curentConnection].server + "/app/sbs/rest/" : "";
        },
        getServer(state) {
            return state.connections[state.curentConnection] ? state.connections[state.curentConnection].server : "";
        },
        isAuthorized(state) {
            return state.connections[state.curentConnection] ? true : false;
        },
    },

    mutations: {
        setConnections: (state, value) => {
            Object.keys(value).forEach( key => {
                //переводим на новый домен
                if(value[key].server == "sbs.okraina.ru") {
                value[key].server = "cbc1.ru";
                }
            })
            state.connections = value;
        },
        setCurrentConnection: (state, value) => {
            state.curentConnection = value;
            localStorage.set("current_connection", value);
        },
        updateConnection: (state, value) => {
            state.connections[state.curentConnection] = value;
            localStorage.set("connections", state.connections);
        },
        addConnection: (state, value) => {
            state.connections[value.server + "/" + value.login] = value;
            localStorage.set("connections", state.connections);
        },
        setConnectionChanging: (state, value) => {
            state.connectionChanging = value;
        },
    },
    actions: {

        /**
         * Удаляет подключение
         */
        removeConnection(context, key) {
            if (context.getters.getConnections[key]) {
                let connections = context.getters.getConnections;
                delete connections[key];
                context.commit('setConnections', connections);
                localStorage.set("connections", connections);
            }
        },

        /**
         * Сброс подключений
         */
        clear(context) {

            //очищаем подключения
            context.commit("setConnections", {});
            localStorage.set("connections", {});
            context.commit("setCurrentConnection", 0);
        },

    }
}

export default connection;