<template>
    <sbs-view :class="'sbs-v-home'" @refresh="refresh" :isMenuHidden="hideMenu" ref="view">
        <template v-slot:content>
            <sbs-requests-summary :reload="reload" />
        </template>
    </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsRequestsSummary from "@/components/requests/summary.vue";

export default {
    name: "sbs-v-requests-statistics",
    components: {
        sbsView,
        sbsRequestsSummary
    },
    data() {
        return {
            reload: 0,
            hideMenu: false
        };
    },
    methods: {
        refresh(resolve) {
            this.reload++;
            setTimeout(() => {
                resolve();
            }, 300);
        },
    },
    mounted() { },

    created() {
        this.hideMenu = !!this.$route.meta.hideMenu;
    },
};
</script>


<style lang="scss"></style>
