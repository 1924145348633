<template>
    <div class="sbs-department-rating">

        <div class="department" @click="goToDepartment">
            {{ user ? user.department_name : "&nbsp;" }}
        </div>
        <div class="rating" v-if="rating > 0" @click="goToRatingRequests">
            <div>
                <span class="icon fi fi-star-filled"></span>
            </div>
            <div class="value">{{ displayRating }}</div>
        </div>
        <div class="norating" v-else>
            нет оценок
        </div>


    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: "sbs-department-rating",
    props: {
        rating: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        filter: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    computed: {

        ...mapGetters({
            user: "getUserProfile",
            service: "getService"
        }),

        displayRating() {
            return Math.round(this.rating * 10) / 10;
        }
    },

    methods: {

        goToRatingRequests() {

            let filter = Object.assign(this.filter, {
                rating: [
                    {
                        id: 1,
                        name: "1"
                    },
                    {
                        id: 2,
                        name: "2"
                    },
                    {
                        id: 3,
                        name: "3"
                    },
                    {
                        id: 4,
                        name: "4"
                    },
                    {
                        id: 5,
                        name: "5"
                    }
                ],
                responsibleDepartment: [
                    {
                        id: this.user.department_id,
                        name: this.user.department_name
                    }
                ]
            });

            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", {
                name: "REQUEST-common",
                value: filter
            });

            //меняем вкладку на экране заявок
            this.$store.commit("setRequestsTab", "all");

            this.$router.push({ name: "requests" });
        },

        /**
         * Переход к просмотру подразделения
         */
        goToDepartment() {

            this.$router.push({
                name: "department-view",
                params: { id: this.user.department_id, service: this.service.toLowerCase() },
            });
        }
    }
};
</script>

<style lang="scss">
.sbs-department-rating {

    position: relative;

    border-radius: 8px;
    background-color: var(--color-block-background);
    margin-bottom: 20px;

    display: grid;
    grid-template-columns: 1fr 85px;
    align-items: center;

    >div {
        padding: 20px 20px;
    }

    .rating {
        display: flex;
        gap: 5px;
        font-size: 18px;

        .icon {
            color: var(--color-color1);
        }
    }

    .norating {
        text-align: center;
    }

}
</style>