<template>
    <div class="sbs-requests-types" v-if="items.length > 0 || showEmpty">
        <div v-if="showTitle" :class="[titleClass]">{{ title }}</div>

        <template v-if="items.length > 0">
            <div class="row">
                <div class="col-4" v-for="item in items" :key="item.priority_id">
                    <div class="item" @click="onItemClick(item)">
                        <div class="name">{{ item.type_name }}</div>
                        <div class="cnti">

                            <div class="cnt" :class="{
        'cnt-3': item.cnt >= 100 && item.cnt < 1000,
        'cnt-4': item.cnt >= 1000 && item.cnt < 10000,
        'cnt-5': item.cnt >= 10000,
    }">
                                {{ item.cnt >= 10000 ? "+9999" : item.cnt }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="empty-message" v-html="emptyMessage"></div>
        </template>
    </div>
</template>

<script>
export default {
    name: "sbs-requests-types",
    components: {},
    data() {
        return {};
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        prefilter: {
            type: Object,
            default() {
                return {};
            },
        },
        showEmpty: {
            type: Boolean,
            default() {
                return false;
            },
        },
        emptyMessage: {
            type: String,
            default() {
                return "";
            },
        },
        title: {
            type: String,
            default() {
                return "Заявки";
            },
        },
        titleClass: {
            type: String,
            default() {
                return "h1";
            },
        },
        showTitle: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    methods: {
        onItemClick(item) {
            let filter = Object.assign({}, this.prefilter, {
                type: [
                    {
                        id: item.type_id,
                        name: item.type_name,
                    },
                ],
            });

            //сохраняем Фильтр в текущем состоянии
            this.$store.commit("setFilter", {
                name: "REQUEST-common",
                value: filter
            });

            //меняем вкладку на экране заявок
            this.$store.commit("setRequestsTab", "all");

            this.$router.push({ name: "requests" });
        },
    },
};
</script>


<style lang="scss">
.sbs-requests-types {
    .h1 {
        margin-bottom: 20px;
    }

    .row {
        margin: 0px -10px;

        >div {
            padding: 0px 10px;
        }
    }

    .item {
        border-radius: 8px;
        padding: 2px 10px 7px 10px;
        background-color: var(--color-block-background);
        margin-bottom: 20px;
        cursor: pointer;

        .name {
            font-size: 10px;
            text-align: right;
        }

        .cnti {
            position: relative;
        }

        .cnt {
            text-align: right;
            font-size: 36px;
            line-height: 42px;
            font-weight: 700;
        }

        @media (max-width: 359px) {

            .cnt {
                font-size: 26px;
            }

            .cnt-3 {
                font-size: 20px;
            }

            .cnt-4 {
                font-size: 16px;
            }

            .cnt-5 {
                font-size: 12px;
            }
        }

        @media (min-width: 360px) and (max-width: 400px) {
            .cnt-3 {
                font-size: 28px;
            }

            .cnt-4 {
                font-size: 20px;
            }

            .cnt-5 {
                font-size: 16px;
            }
        }

        @media (min-width: 401px) and (max-width: 450px) {
            .cnt-3 {
                font-size: 34px;
            }

            .cnt-4 {
                font-size: 26px;
            }

            .cnt-5 {
                font-size: 22px;
            }
        }

        @media (min-width: 451px) and (max-width: 500px) {
            .cnt-4 {
                font-size: 30px;
            }

            .cnt-5 {
                font-size: 26px;
            }
        }

        @media (min-width: 501px) and (max-width: 550px) {
            .cnt-5 {
                font-size: 32px;
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            font-size: 23px;
            color: var(--color-icon-active);
        }
    }
}
</style>
