<template>
  <div class="sbs-user-settings">

    <!-- Загрузка -->
    <template v-if="loading">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>

    <template v-else>

      <h2>Тема</h2>
      <div class="list">
        <div class="item" v-for="item in themes" :key="item.id">
          <div class="label">{{ item.name }}</div>
          <div class="control">
            <sbs-control-radio name="theme" :value="item.id" v-model="themeInternal" />
          </div>
        </div>
      </div>

      <br />

      <h2>Push</h2>
      <div class="list">
        <div class="item" v-for="item in notifications" :key="item.name">
          <div class="label">{{ item.title }}</div>
          <div class="control">
            <sbs-control-checkbox v-model="settings.notifications[item.name].push" value="Y" label="" defaultValue="N"
              :form="false" @change="onNotificationChange()" />
          </div>
        </div>

      </div>


    </template>

  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsControlRadio from "@/components/controls/radio.vue";
import sbsControlCheckbox from "@/components/controls/checkbox.vue";
import { mapGetters } from "vuex";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
  name: "sbs-user-settings",
  components: {
    sbsLoaderInline,
    sbsControlRadio,
    sbsControlCheckbox
  },
  data() {
    return {
      loading: true,
      themeInternal: this.theme,
      themes: [
        {
          id: "dark",
          name: "Тёмная",
        },
        {
          id: "light",
          name: "Светлая",
        },
      ],

      notifications: {},

      settings: {
        notifications: {}
      }

    };
  },
  computed: {
    ...mapGetters({ theme: "getTheme" }),
  },

  watch: {
    themeInternal: function (value) {
      if (value != this.theme) {
        this.$store.commit("setTheme", value);
      }
    },
    theme: function (value) {
      if (value != this.themeInternal) {
        this.themeInternal = value;
      }
    },
  },

  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;

      //запрашиваем данные
      rest
        .call("user.settings.get", {}, false)
        .then((data) => {
          if (data.success) {
            this.notifications = data.notifications;
            this.setFields();
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Устанавилвает поля настроек
     */
    setFields() {
      this.notifications.forEach(n => {
        this.settings.notifications[n.name] = {};
        this.settings.notifications[n.name].push = n.push;
      });
    },

    onNotificationChange() {
      this.save();
    },

    /**
     * Сохранение
     */
    save() {

      //запрашиваем данные
      rest
        .call("user.settings.update", {
          method: "post",
          data: this.settings,
        })
        .then((data) => {
          if (!data.success) {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },
  },

  /**
   * Создание эксземпляра компонента
   */
  created() {
    this.themeInternal = this.theme;

    //загружаем данные
    this.loadData();
  },
};
</script>

<style lang="scss">
.sbs-user-settings {
  .list {
    .item {
      position: relative;
      margin-bottom: 15px;

      padding-right: 40px;

      .control {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      }
    }
  }
}
</style>