<template>
  <div class="sbs-control-filter" @click="onClick">
    <div class="label">
      {{ this.label }}
      <div class="icon">
        <span class="fi fi-arrow-right"></span>
      </div>
    </div>
    <TransitionGroup v-if="values.length > 0" class="values" name="values" tag="div">
      <div v-for="value in values" :key="value.id" class="value btn btn1" @click.stop="removeValue(value.id)">
        {{ formatName(value) }}
        <span class="icon fi fi-close"></span>
      </div>
    </TransitionGroup>

    <teleport to="#app">
      <sbs-dialog-select v-model:visible="showDialog" :title="label" :name="name" :method="method" :multiple="true"
        v-model:modelValues="values" :params="params" :list="items" />
    </teleport>
  </div>
</template>


<script>
import sbsDialogSelect from "@/components/dialogs/select.vue";

export default {
  name: "sbs-control-filter",
  components: {
    sbsDialogSelect,
  },
  data() {
    return {
      values: this.modelValue,
      showDialog: false,
    };
  },
  emits: ["update:modelValue"],
  props: {
    label: {
      type: String,
      default() {
        return "Метка";
      },
    },

    name: {
      type: String,
      default() {
        return "";
      },
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },

    method: {
      type: String,
      default() {
        return "";
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  watch: {
    modelValue: function (newVal) {
      this.values = newVal;
    },

    values: function () {
      this.$emit("update:modelValue", this.values);
    },
  },
  methods: {
    /**
     * Клик по фильтру
     */
    onClick() {
      this.showDialog = true;
    },

    removeValue(id) {
      this.values = this.values.filter((item) => item.id != id);
    },

    formatName(item) {
      if (this.method == "users.list") {
        return item.last_name + " " + item.name;
      }
      return item.name;
    },
  },
};
</script>

<style lang="scss">
.sbs-control-filter {
  margin: 0px -20px;
  padding: 15px 20px;
  background-color: var(--color-block-background);
  margin-bottom: 2px;

  .label {
    position: relative;
    color: var(--color-block-value);
    font-weight: 700px;
    padding-right: 50px;

    .icon {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      color: var(--color-icon-active);
    }
  }

  .values-enter-active,
  .values-leave-active {
    transition: all 0.5s ease;
  }

  .values-enter-from,
  .values-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .values {
    margin-top: 10px;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .value {
      display: inline-block;
      text-transform: none;
      margin-bottom: 6px;
      padding: 7px 40px 7px 10px;
      font-size: 14px;
      margin-right: 6px;
      text-align: left;

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 13px;
        font-weight: 17px;
      }
    }
  }
}
</style>
