import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
import { Capacitor } from '@capacitor/core';
import store from '@/store'
import rest from "@/plugins/rest"
import bus from '@/plugins/bus'
import router from '@/router'

import errorListiner from '@/plugins/error.listiner'

export default {

    init() {

        //обработка события регистрации
        PushNotifications.addListener('registration', token => {

            //хак для ios - token возвращается APNs, а не FCM
            if(Capacitor.getPlatform() == "ios") {
                //используем другой плагин - FCM
                if(Capacitor.isPluginAvailable("FCM")) {
                    //получаем токен FCM
                    FCM.getToken().then((r) => {
                        this.onTokenRefresh(r.token);
                    });
                }

            } else {
                this.onTokenRefresh(token.value);
            }
            
        });

        //обработка события ошибки регистрации
        PushNotifications.addListener('registrationError', err => {
            bus.emit('SBS_MESSAGE_E_SHOW', { message: err.error });
        });

        //подписываемся на получение сообщения
        PushNotifications.addListener('pushNotificationReceived', notification => {
            //покажем сообщение в области уведомлений
            bus.emit('SBS_NOTIFICATION_E_SHOW', notification.data);
            //отправим событие всем подписанным компонентам
            bus.emit('SBS_PUSH_E_RECEIVE', notification.data);
        });

        //подписываемся на клик по сообщению в области уведомлений
        PushNotifications.addListener('pushNotificationActionPerformed', event => {
            let vm = this;
            //создаём задержку, чтобы App успел смонтироваться и инициализироваться
            setTimeout(() => {
                vm.onPushClick(event.notification.data);
            }, 300);
        });

        this.register();

        //подписываемся на запрос удаления токена с сервера
        bus.on("SBS_PUSH_E_DELETE_TOKEN_FROM_SERVER", this.deleteTokenFromServer);
        //подписываемся на запрос сохранения токена на сервере
        bus.on("SBS_PUSH_E_SAVE_TOKEN_TO_SERVER", this.saveTokenToServer);
    },

    /**
     * Производит регистрацию и проверку доступа
     */
    async register() {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }

        await PushNotifications.register();
    },

    /**
    * Обработка изменения токена
    */
    onTokenRefresh(token) {

        let oldToken = store.getters.getPushToken;

        //сохраним токен в текущем состоянии приложения
        store.commit('setPushToken', token);

        //сохраним токен на сервере
        this.saveTokenToServer(oldToken);

    },

    /**
    * Сохраняет токен на сервере
    */
    saveTokenToServer(oldToken = null) {

        //сохраняем только в случае если пользователь авторизован и имеется FCM токен
        if (store.getters.getPushToken && store.getters.isAuthorized) {

            rest.call("push.token.add", {
                method: 'post',
                data: {
                    token: store.getters.getPushToken,
                    oldToken: oldToken,
                    platform: Capacitor.getPlatform()
                }
            }, true) //тихий режим
                .then(data => {

                    if (!data.success) {
                        errorListiner.onError({
                            message: "Ошибка сохранения push токена: " +data.errorText
                        });
                    }

                })
                .catch(() => {
                    //в тихом режиме не обрабатываем ошибку
                });
        }

    },

    /**
    * Удаление
    */
    deleteTokenFromServer() {

        //только если токен имеется и пользователь авторизован
        if (store.getters.getPushToken && store.getters.isAuthorized) {

            rest.call("push.token.delete", {
                method: 'post',
                data: {
                    token: store.getters.getPushToken
                }
            }, true) //тихий режим
                .then(data => {

                    if (!data.success) {
                        errorListiner.onError({
                            message: "Ошибка сохранения push токена: " +data.errorText
                        });
                    }

                })
        }

    },

    /**
     * Действие при клике на уведомление
     */
    onPushClick(data) {

        //переход к экрану
        if (data.type == "request_message") {
            router.push({ name: "request-view", params: { id: data.requestId, tab: "discussion" } });
        } else if (data.type == "request_view") {
            router.push({ name: "request-view", params: { id: data.requestId } });
        } else if (data.type == "education_training_assign") {
            router.push({ name: "education-training-view", params: { id: data.trainingId } });
        }
    },
}