<template>
    <div class="sbs-edu-training-statuses">


        <div class="row">
            <div class="col-4" v-for="item in list" :key="item.result_status">
                <div class="item" @click="onItemClick(item)">
                    <div class="name">{{ item.name }}</div>
                    <div class="cnti">
                        <div class="icon fi" :class="item.icon"></div>
                        <div class="cnt" :class="{
                'cnt-3': item.cnt >= 100 && item.cnt < 1000,
                'cnt-4': item.cnt >= 1000 && item.cnt < 10000,
                'cnt-5': item.cnt >= 10000,
            }">
                            {{ item.cnt >= 10000 ? "+9999" : item.cnt }}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: "sbs-edu-training-statuses",
    components: {},
    data() {
        return {};
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        prefilter: {
            type: Object,
            default() {
                return {};
            },
        },

    },
    computed: {
        list() {
            let list = [];

            ["N", "P", "S"].forEach(s => {
                let item = this.items.find(i => i.result_status == s);
                if (!item) {
                    item = {
                        result_status: s,
                        cnt: 0
                    };
                }

                let icon = "fi-new";
                if (item.result_status == 'P') {
                    icon = "fi-process";
                } else if (item.result_status == 'S') {
                    icon = "fi-done";
                }



                list.push({
                    result_status: item.result_status,
                    cnt: item.cnt,
                    icon: icon,
                    name: this.$t("education.trainings.statuses." + item.result_status)
                });
            })

            return list;
        }
    },
    methods: {
        onItemClick(item) {

            this.$router.push({ name: "education-training-my", params: { status: item.result_status } });
        },
    },
};
</script>


<style lang="scss">
.sbs-edu-training-statuses {

    .row {
        margin: 0px -10px;

        >div {
            padding: 0px 10px;
        }
    }

    .item {
        border-radius: 8px;
        padding: 2px 10px 7px 10px;
        background-color: var(--color-block-background);
        margin-bottom: 20px;
        cursor: pointer;

        .name {
            font-size: 10px;
            text-align: right;
        }

        .cnti {
            position: relative;
        }

        .cnt {
            text-align: right;
            font-size: 36px;
            line-height: 42px;
            font-weight: 700;
        }

        @media (max-width: 359px) {

            .cnt {
                font-size: 26px;
            }

            .cnt-3 {
                font-size: 20px;
            }

            .cnt-4 {
                font-size: 16px;
            }

            .cnt-5 {
                font-size: 12px;
            }
        }

        @media (min-width: 360px) and (max-width: 400px) {
            .cnt-3 {
                font-size: 28px;
            }

            .cnt-4 {
                font-size: 20px;
            }

            .cnt-5 {
                font-size: 16px;
            }
        }

        @media (min-width: 401px) and (max-width: 450px) {
            .cnt-3 {
                font-size: 34px;
            }

            .cnt-4 {
                font-size: 26px;
            }

            .cnt-5 {
                font-size: 22px;
            }
        }

        @media (min-width: 451px) and (max-width: 500px) {
            .cnt-4 {
                font-size: 30px;
            }

            .cnt-5 {
                font-size: 26px;
            }
        }

        @media (min-width: 501px) and (max-width: 550px) {
            .cnt-5 {
                font-size: 32px;
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            font-size: 23px;
            color: var(--color-icon-active);
        }
    }
}
</style>
