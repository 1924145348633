<template>
    <div class="sbs-equipment-view-actions">
        <sbs-popup v-model:show="display" :loading="loading">
            <div class="actions">

                <!-- Редактировать -->
                <div v-if="permissions.includes('manage')" class="action" @click="editAction">
                    <span class="icon fi fi-edit"></span>
                    <div class="text">Редактировать</div>
                </div>

                <template v-if="isNativePlatform">
                    <!-- привязать Qr-код -->
                    <div v-if="permissions.includes('manage')" class="action" @click="attachQrCodeAction">
                        <span class="icon fi fi-qr-scanner"></span>
                        <div class="text">Привязать QR-код</div>
                    </div>

                    <!-- привязать NFC-метку -->
                    <div v-if="permissions.includes('manage')" class="action" @click="attachNfcAction">
                        <span class="icon fi fi-nfc"></span>
                        <div class="text">Привязать NFC-метку</div>
                    </div>
                </template>

            </div>
        </sbs-popup>
    </div>
</template>

<script>
import sbsPopup from "@/components/popup.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "sbs-equipment-view-actions",
    components: {
        sbsPopup,
    },
    emits: ["update:show"],
    data() {
        return {
            loading: false,
            display: this.show,
        };
    },
    props: {
        id: {
            type: [Number, String],
            default() {
                return 0;
            },
        },
        show: {
            type: Boolean,
            default() {
                return false;
            },
        },
        permissions: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        ...mapGetters({
            isNativePlatform: "isNativePlatform",
        }),
    },
    watch: {
        show: function (newVal) {
            this.display = newVal;
        },
        display: function (newVal) {
            this.$emit("update:show", newVal);
        },
    },

    methods: {
        close() {
            this.$emit("update:show", false);
        },

        /**
         * Редактировать
         */
        editAction() {
            this.close();
            this.$router.push({ name: "equipment-form", params: { id: this.id } });
        },

        /**
         * Привязать QR-код
         */
        attachQrCodeAction() {

            let vm = this;
            bus.emit("SBS_QR_SCANNER_E_OPEN", {
                mode: "SIMPLE",
                resolve: function (code) {
                    vm.attachQrCode(code);
                },
            });
        },

        /**
         * Выполняет непосредственную привязку qr-кода
         */
        attachQrCode(code) {

            //выполняем запрос
            rest
                .call("equipment.update", {
                    method: "post",
                    data: {
                        id: this.id,
                        props: {
                            qr_code: code
                        }
                    },
                })
                .then((data) => {
                    if (data.success) {
                        //показываем сообщение
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: "QR-код успешно обновлён.", type: "success" });
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .catch(() => {
                    //в тихом режиме не обрабатваем ошибку
                })
                .finally(() => {
                    this.loading = false;
                    this.close();
                });

        },


        /**
         * Привязать NFC-метку
         */
        attachNfcAction() {

            let vm = this;
            bus.emit("SBS_NFC_SCANNER_E_OPEN", {
                mode: "SIMPLE",
                resolve: function (code) {
                    vm.attachNfcCode(code);

                },
            });
        },

        /**
         * Выполняет непосредственную привязку nfc-метки
         */
        attachNfcCode(code) {

            rest
                .call("equipment.update", {
                    method: "post",
                    data: {
                        id: this.id,
                        props: {
                            nfc_id: code
                        }
                    },
                })
                .then((data) => {
                    if (data.success) {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: "NFC-метка успешно привязана.", type: "success" });
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
                    }
                })
                .catch(() => {
                    //в тихом режиме не обрабатваем ошибку
                })
                .finally(() => {
                    this.loading = false;
                    this.close();
                });

        }
    }
}
</script>

<style lang="scss"></style>