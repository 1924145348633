<template>
    <div class="sbs-view" :class="[this.class]"
        :style="{ paddingTop: this.paddingTop + 'px', paddingBottom: this.paddingBottom + 'px' }">
        <div class="view-outer">
            <div class="view-top">
                <slot name="top"></slot>
            </div>
            <div class="view-inner" :class="[!this.isMenuHidden ? 'bottom-padding' : '']">
                <div class="view-header" v-if="this.$slots.header" ref="header">
                    <div class="header-bg"></div>
                    <div class="header-inner">
                        <slot name="header"></slot>
                    </div>
                </div>
                <div class="view-content-scroll" ref="contentScroll" v-pull-to-refresh:disabled="refreshDisabled"
                    @refresh="refresh">
                    <div class="view-content" :style="{
            paddingTop: this.contentPaddigTop + 'px',
        }">
                        <div class="container">
                            <slot name="content"></slot>
                        </div>
                    </div>

                </div>

                <div class="view-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
            <slot name="outer"></slot>
        </div>
    </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
    name: "sbs-view",
    components: {},
    emits: ["refresh"],
    data() {
        return {
            contentPaddigTop: 15,
            savedScrollTop: 0,
        };
    },
    props: {
        class: {
            type: String,
            default() {
                return "refresh";
            },
        },
        refreshDisabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
        isMenuHidden: {
            type: Boolean,
            default() {
                return false;
            },
        }
    },
    computed: {
        ...mapGetters({
            safeArea: "getSafeArea",
            platform: "getPlatform"
        }),
        paddingTop() {
            if (this.safeArea && this.safeArea.top > 0) {
                return this.safeArea.top;
            }

            return 0;
        },
        paddingBottom() {
            if (this.safeArea && this.safeArea.bottom > 0 && this.platform == "ios") {
                return this.safeArea.bottom;
            }

            return 0;
        }
    },
    methods: {
        /**
         * Перезагрузка экрана
         */
        refresh(e) {
            this.$emit("refresh", e.detail.resolve);
        },

        /**
         * Пересчитывает отступ контента сверху для учата шапки
         */
        refreshHeaderPadding() {
            if (this.$slots.header) {
                this.contentPaddigTop = this.$refs.header.clientHeight + 10;
            }

            if (this.contentPaddigTop < 20) {
                this.contentPaddigTop = 20;
            }
        },

        /**
         * Вызывается перед переходом из этого экрана в другой
         */
        onBeforeRouteLeave() {
            this.savedScrollTop = this.$refs.contentScroll.scrollTop;
        },

        /**
         * Обраотка переход входом на экран
         */
        onBeforeRouteEnter() {
            this.$refs.contentScroll.scrollTop = this.savedScrollTop;
            this.refreshHeaderPadding();
        },

        /**
         * Сбарсывает сохранённый скрол
         */
        resetSavedScroll() {
            this.savedScrollTop = 0;
        }


    },
    mounted() {
        this.refreshHeaderPadding();
    },

}

</script>

<style lang="scss">
.sbs-view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    background-color: var(--color-background);
    transition: background-color ease-in-out 0.15s;

    box-shadow: rgba(1, 0, 61, 0.15) 0px 0px 15px;

    .view-outer {
        margin-right: auto;
        margin-left: auto;
        height: 100%;
        overflow: hidden;
        position: relative;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    }

    .view-inner {
        position: relative;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        overflow: hidden;

        &.bottom-padding {
            padding-bottom: 65px;
        }
    }

    .view-header {
        position: absolute;
        max-width: 960px;
        top: 0px;
        left: 0px;
        right: 0px;
        margin-right: auto;
        margin-left: auto;
        z-index: 200;

        .header-inner {
            position: relative;
            z-index: 2;
        }
    }

    .view-content-scroll {
        position: relative;
        overflow: auto;
    }

    .view-content {
        position: relative;
        padding: 10px 5px;
        min-height: 100%;
    }

    /*
        Анимация перехода: слайд слева
    */

    &.slide-left-enter-active {
        transition: transform 0.5s cubic-bezier(0.25, 1.07, 0.54, 0.92);
        z-index: 999;
    }

    &.slide-left-enter-from {
        transform: translateX(100%);
    }

    &.slide-left-enter-from,
    &.slide-left-enter-to {
        z-index: 999;
    }

    &.slide-left-leave-active {
        transition: transform 0.5s cubic-bezier(0.25, 1.07, 0.54, 0.92);
    }

    &.slide-left-leave-active:after {
        content: "";
        position: absolute;
        background-color: #000;
        opacity: 0;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 501;

        transition: opacity 0.5s cubic-bezier(.25, 1.07, .54, .92);
    }

    &.slide-left-leave-to {
        transform: translateX(-30%);
    }

    &.slide-left-leave-to:after {
        opacity: 0.5;
    }


    /*
        Анимация перехода: слайд с права
    */

    &.slide-right-enter-active {
        transition: transform 0.5s cubic-bezier(0.25, 1.07, 0.54, 0.92);
    }

    &.slide-right-enter-active:after {
        content: "";
        position: absolute;
        background-color: #000;

        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 500;

        transition: opacity 0.5s cubic-bezier(.25, 1.07, .54, .92);
    }

    &.slide-right-enter-from {
        transform: translateX(-30%);
    }

    &.slide-right-enter-from:after {
        opacity: 0.5;
    }

    &.slide-right-enter-to:after {
        opacity: 0;
    }

    &.slide-right-leave-from {
        z-index: 999;
    }

    &.slide-right-leave-active {
        transition: transform 0.5s cubic-bezier(0.25, 1.07, 0.54, 0.92);
        z-index: 999;
    }

    &.slide-right-leave-to {
        transform: translateX(100%);
        z-index: 999;
    }
}
</style>