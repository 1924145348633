<template>
    <div class="sbs-edu-training-item" @click="onItemClick(item)">

        <div v-if="item.result && item.result.deadline && item.result.status != 'S'" class="deadline">
            {{ $t('education.trainings.before') }} {{ formatDate(item.result.deadline) }}
        </div>

        <div class="picture">

            <div v-if="item.picture" class="image preloaded" v-lazy:background-image="item.picture.preview">

                <div class="preload" v-if="item.picture.preload"
                    :style="{ backgroundImage: 'url(\'' + item.picture.preload + '\')' }">
                </div>

                <div class="loader">
                    <sbs-loader-inline />
                </div>
            </div>

            <div v-else class="image no-photo" :style="{ backgroundImage: 'url(' + imageNoPhoto + ')' }"></div>

        </div>

        <div class="inner">
            <div class="name" v-html="item.name"></div>

        </div>

        <div class="posts">
            <div>
                <span class="fi fi-copy icon"></span>
            </div>
            <div>{{ item.data && item.data.posts ? item.data.posts.length : 0 }}</div>
        </div>

        <div v-if="item.result" class="result" :class="{ success: item.result.status == 'S' }">
            <span v-if="item.result.status != 'S'">{{ item.result.percent }}%</span>
            <span v-else class="fi fi-save-bold icon"></span>
        </div>

    </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";

import moment from "moment";

import imageNoPhotoPath from "@/assets/images/no-photo.svg";

export default {
    name: "sbs-education-training-item",
    components: {
        sbsLoaderInline,
    },
    data() {
        return {
            imageNoPhoto: imageNoPhotoPath
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    methods: {

        /**
         * Переход к тренировке
         */
        onItemClick(item) {
            this.$router.push({
                name: "education-training-view",
                params: { id: item.id },
            });
        },

        /**
         * Форматирует дату
         */
        formatDate(date) {
            return moment(date, "DD.MM.YYYY hh:mm:ss").format("DD MMMM YYYY");
        },
    },
};
</script>
<style lang="scss">
.sbs-edu-training-item {
    position: relative;

    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 8px;

    background-color: var(--color-block-background);

    .deadline {
        position: absolute;
        top: -5px;
        left: -5px;
        z-index: 10;

        padding: 3px 5px;
        border-radius: 4px;
        background-color: var(--color-btn1-background);
        color: var(--color-btn1-text);
        font-size: 11px;
    }


    .picture {
        height: 0px;
        padding-top: 60%;
        border-radius: 8px 8px 0px 0px;
        overflow: hidden;
        position: relative;

        .image {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            &.no-photo {
                background-size: contain;
            }
        }
    }

    .inner {
        position: relative;
        padding: 16px 10px 40px;
    }

    .name {
        font-size: 12px;
        line-height: 14px;
    }

    .posts {
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 5px;
        font-size: 10px;
        color: var(--color-text-label);

        .icon {
            font-size: 12px;
        }
    }

    .result {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 40px;
        border-radius: 8px;
        background-color: var(--color-btn1-background);
        color: var(--color-btn1-text);
        text-align: center;
        line-height: 1;
        font-size: 11px;
        padding: 3px 0px;

        &.success {
            background-color: var(--color-success);
        }
    }
}
</style>