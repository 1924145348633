<template>
  <sbs-view :class="'sbs-v-requests-search'" :isMenuHidden="hideMenu" :refreshDisabled="true">
    <template v-slot:header>
      <sbs-header />
    </template>
    <template v-slot:content>
      <div class="h1">Поиск заявок</div>

      <sbs-requests-search :reload="reload" />
    </template>

  </sbs-view>
</template>

<script>
import sbsView from "@/components/view.vue";
import sbsHeader from "@/components/header.vue";
import sbsRequestsSearch from "@/components/requests/search.vue";

export default {
  name: "sbs-v-requests-search",
  components: {
    sbsView,
    sbsHeader,
    sbsRequestsSearch,
  },
  data() {
    return {
      reload: 0,
      hideMenu: false
    };
  },

  methods: {},

  created() {
    this.hideMenu = !!this.$route.meta.hideMenu;
  },
};
</script>

<style lang="scss"></style>